<template>
  <div>
    <div class="wt-org-auth"  v-if="sysInfo && sysInfo.status != 2">
      <div class="alert to" v-if="sysInfo.status == 0" @click="toAuth">立即认证，获得系统完整功能 ></div>
      <div class="alert blue"  v-if="sysInfo.status == 1">
        系统认证审核中
        <div class="btn" @click="visible = true">查看资料</div>
      </div>
      <div class="alert red"  v-if="sysInfo.status == 3" >
        <div class="reason">资料审核未通过，原因：{{sysInfo.reason||''}}</div>
        <div class="link" @click="toAuth">重新提交 ></div>
      </div>

      <el-dialog title="认证资料" :visible.sync="visible" append-to-body size="small" width="600">
        <div class="detail">
          <div class="row">
            <div class="item">
              <div class="label">企业名称:</div>
              <div class="value">{{sysInfo.name}}</div>
            </div>
            <div class="item">
              <div class="label">注册地址:</div>
              <div class="value">{{sysInfo.registeredAddress}}</div>
            </div>
            <div class="item">
              <div class="label">经营地址:</div>
              <div class="value">{{sysInfo.businessAddress}}</div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="label">法人名称:</div>
              <div class="value">{{sysInfo.legalPerson}}</div>
            </div>
            <div class="item">
              <div class="label">法人电话:</div>
              <div class="value">{{sysInfo.legalPersonPhone}}</div>
            </div>
            <div class="item">
              <div class="label">企业联系人:</div>
              <div class="value">{{sysInfo.enterpriseContact}}</div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="label">企业联系电话:</div>
              <div class="value">{{sysInfo.enterpriseContactPhone}}</div>
            </div>
            <div class="item">
              <div class="label">注册资本:</div>
              <div class="value">{{sysInfo.registeredCapital}}</div>
            </div>
            <div class="item">
              <div class="label">医疗机构许可证编号:</div>
              <div class="value">{{sysInfo.licenseNo}}</div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="label">注册科室:</div>
              <div class="value">{{sysInfo.registeredDepartment}}</div>
            </div>
            <div class="item">
              <div class="label">邮箱地址:</div>
              <div class="value">{{sysInfo.email}}</div>
            </div>
            <div class="item">
              <div class="label">机构商户编号:</div>
              <div class="value">{{sysInfo.bankAccount || '-'}}</div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="label">主营项目简介:</div>
              <div class="value">{{sysInfo.introduction || '-'}}</div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="label">附件:
              </div>
              <div class="value">
                <wt-text-button v-for="(item, index) in fileList" :key="index"
                                style="margin-left: 0px;margin-right:10px"
                                @click="previewImg(item.fileUrl)">{{item.fileName}}</wt-text-button>
              </div>
            </div>
            <div class="detail-section-item"></div>
            <div class="detail-section-item"></div>
          </div>
        </div>
      </el-dialog>
      <el-image-viewer zIndex="999999"
        v-if="previewImageList && previewImageList.length > 0"
        :on-close="() => {previewImageList = []}"
        :url-list="previewImageList"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'WtOrgAuth',
  components: { ElImageViewer },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  },
  data() {
    return {
      institution: false,
      sysInfo: null,
      visible: false,
      fileList: [],
      previewImageList: []
    }
  },
  mounted() {
    this.institution = !!this.userInfo.organizationId
    if (this.institution) {
      this.loadSysInfo()
    }
  },
  methods: {
    loadSysInfo() {
      const that = this
      that.$wtRequest({
        url: 'modules/organization/systemInfo',
        method: 'post',
        data: {}
      }).then(async(resp) => {
        that.sysInfo = resp.data || null
        that.fileList = resp.data.organizationFileResult || {}
      }).catch(() => {
        that.sysInfo = null
      })
    },
    previewImg(imgUrl) {
      const that = this
      that.$wtRequest({
        url: 'modules/resourceFile/getSignUrl',
        method: 'post',
        data: { fileName: imgUrl }
      }).then((resps) => {
        if (resps.data) {
          this.previewImageList = [resps.data]
        } else {
          this.previewImageList = []
        }
      }).catch(() => {
        console.log('图片回显失败')
      })
    },
    toAuth() {
      this.$router.push({ path: '/settledApply?reapply=1' })
    }
  }
}
</script>

<style scoped lang="scss">
.wt-org-auth{
  position: fixed;
  top: 0px;
  left: calc(50% - 300px);
  width: 600px;
  z-index: 999;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: none;
  .alert{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e6a23c;
    border-radius: 100px;
    padding: 10px 15px;
    color: #ffffff;
  }
  .to{
    cursor: pointer;
  }
  .blue{
    background-color: #5e7ce0;
  }
  .red{
    background-color: #f66f6a;
  }
  .reason{
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }
  .btn{
    margin-left: 50px;
    background-color: #ffffff;
    border-radius: 100px;
    color: #333333;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }
  .link{
    margin-left: 50px;
    cursor: pointer;
  }
}
.detail{
  padding: 20px;
  .row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .item{
      flex: 1;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;
      .label{
        margin-right: 6px;
      }
      .value{
        color: #666;
      }
    }
  }
}
</style>
