<template>
  <div v-if="!data.hidden" class="wt-module-item">
    <div class="title">{{data.meta ? data.meta.title : '-'}}</div>
    <div class="content" v-if="data.children && data.children.length > 0">
      <module-tree-item v-for="(item, index) in data.children" :data="item" :key="index"></module-tree-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'moduleTreeItem',
  props: ['data'],
  data() {
    return {
      checked: false
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
  }
}
</script>
<style scoped>
  .wt-module-item{display: flex;border-left: 1px solid #F0F0F0;border-top: 1px solid #F0F0F0;padding-left: 16px;font-size: 14px;color: #333;}
  .wt-module-item > .title{min-width: 150px;min-height: 30px;display: flex;align-items: center;}
  .wt-module-item > .content{flex: 1;min-height: 30px;}
  .wt-module-item > .permissions{flex: 1;min-height: 30px;display: flex;flex-wrap: wrap;align-items: center;}
  .wt-module-item > .permissions > .permission{margin: 8px 12px;text-decoration: underline; color: #666;}
  .wt-module-item > .content > .wt-module-item:first-child{border-top: 0;}
</style>
