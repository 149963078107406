<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="优惠券详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="15%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="优惠券类型" prop="name">
                <span>{{ detailForm.type | FILTER_CouponType }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发放状态" prop="summary">
                <span>{{ detailForm.isPublish === 0 ? '未发放' : '已发放' }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="优惠券内容" prop="themeName">
                <span>{{ couponContent }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="优惠券用途" prop="organizationName">
                <span>{{ detailForm.useClass | FILTER_UseClass }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发放范围" prop="pageNumber">
                <span>{{ rangeContent }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="过期时间" prop="freeNumber">
                <span>{{ detailForm.endDate }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
      <wt-detail-section title="发放信息" v-if="showList">
        <div style="padding: 0 16px;width: 100%;display: flex;justify-content: space-between;">
          <div style="display: flex;">
            <wt-crud-form-item title="手机号" v-model="crud.formData.userPhone" type="input" style="width: 250px;" />
            <wt-crud-form-item title="会员类型" v-model="crud.formData.memberLevel" type="select" :dataList="$wtConst.MEMBER_TYPE_OPTIONS"  style="width: 260px;" />
            <wt-crud-form-item title="使用状态" v-model="crud.formData.isUse" type="select" :dataList="$wtConst.ISUSE__OPTIONS"  style="width: 260px;" />
          </div>
          <div>
            <wt-button type="primary" @click="crud.requestDataList()">查询</wt-button>
            <wt-button @click="resetListRequest">重置</wt-button>
          </div>
        </div>
        <wt-crud :crud="crud" mode="slot" style="width: 100%; padding:0 16px 0 16px;">
<!--          <template slot="search">-->
<!--            <wt-crud-form-item title="手机号" v-model="crud.formData.userPhone" type="input" />-->
<!--            <wt-crud-form-item title="会员类型" v-model="crud.formData.memberLevel" type="select" :dataList="$wtConst.MEMBER_TYPE_OPTIONS" />-->
<!--            <wt-crud-form-item title="使用状态" v-model="crud.formData.isUse" type="select" :dataList="$wtConst.ISUSE__OPTIONS" />-->
<!--          </template>-->
          <template #table-column>
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="userName" label="昵称"></el-table-column>
            <el-table-column prop="userPhone" label="手机号" width="160"></el-table-column>
            <el-table-column prop="wechatOpenid" label="微信ID"></el-table-column>
            <el-table-column prop="memberLevel" label="会员类型" width="120">
              <template v-slot="scope">
                {{ scope.row.memberLevel | FILTER_MemberType }}
              </template>
            </el-table-column>
            <el-table-column prop="isUse" label="使用状态" width="120">
              <template v-slot="scope">
                {{ scope.row.isUse === 1 ? '已使用' : '未使用' }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间"></el-table-column>
          </template>
        </wt-crud>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'couponView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {},
      crud: {},
      showList: false
    }
  },
  async mounted() {
    const useCrudObj = useCrud({
      requestListUrl: '/modules/userCoupon/list',
      formData: {
        couponId: this.detailData.id,
        isUse: '',
        memberLevel: '',
        userPhone: ''
      }
    })
    this.crud = useCrudObj.crud
    setTimeout(() => {
      this.showList = true
    }, 500)
    if (this.viewType !== 'add') await this.requestDetail()
  },
  created() {
  },
  methods: {
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/coupon/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    resetListRequest() {
      this.crud.formData = {
        couponId: this.detailData.id,
        isUse: '',
        memberLevel: '',
        userPhone: ''
      }
      this.crud.requestDataList()
    }
  },
  computed: {
    couponContent() {
      if (!this.detailForm.type) return ''
      if (this.detailForm.type === 1) {
        return `满${this.detailForm.fullSubtractPriceDollar}减${this.detailForm.favourablePriceDollar}元`
      } else {
        return `${this.detailForm.discount}折`
      }
    },
    rangeContent() {
      if (!this.detailForm.givingRange) {
        return ''
      } else {
        return this.detailForm.givingRange.replace(/1/g, '初级会员').replace(/2/g, '高级会员').replace(/3/g, '优享会员').replace(/,/g, '、')
      }
    }
  }
}
</script>
<style scoped lang="scss">
.wt-detail-section-content {
  padding: 16px;
}
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 40%;
}
.user-avatar {
  height: 80px;
}
</style>
