<template>
  <div class="wt-form-inputs-container">

    <div class="wt-form-inputs-title">
      <div class="title">筛选查询</div>
      <div class="buttons">
        <template v-if="!$slots.buttons">
          <el-button size="small" type="primary" @click="toSearch">查询</el-button>
          <el-button size="small" @click="toReset">重置</el-button>
          <el-button size="small" @click="toMore" v-show="isHasMoreButtons()">
            {{isMoreHidden?'更多搜索':'隐藏更多搜索'}}
          </el-button>
        </template>
        <slot name="buttons"></slot>
      </div>
    </div>
    <div class="wt-form-inputs-content" v-if="form.formInputsData && form.formInputsData.length > 0">
      <el-form size="small" :inline="true" class="wt-form-inputs">
        <template v-if="!$slots.default">
          <template v-for="item in form.formInputsData">
            <!--全部自定义-->
            <slot v-if="item.type === 'customAll'" :name="item.key" v-show="isItemShow(item)"></slot>
            <el-form-item v-else :label="item.name + '：'" v-show="isItemShow(item)" :key="item.name">
              <!--日期选择-->
              <el-date-picker v-if="item.type === 'datetime'" v-model="item.value"
                              type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至"
                              start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
              <!--下拉选择-->
              <el-select v-else-if="item.type === 'select'" v-model="item.value" :placeholder="'请选择' + item.name" clearable>
                <el-option v-for="(dataItem, dataIndex) in item.data" :key="dataIndex" :label="dataItem.label"
                           :value="dataItem.value"></el-option>
              </el-select>
              <!--自定义-->
              <slot v-else-if="item.type === 'custom'" :name="item.key"></slot>
              <!--文本输入-->
              <el-input v-else v-model="item.value" :placeholder="'请输入' + item.name" clearable></el-input>
            </el-form-item>
          </template>
        </template>
        <slot name="default"></slot>

      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'wt-form-inputs',
  props: ['form'],
  data() {
    return {
      isMoreHidden: true
    }
  },
  methods: {
    toSearch() {
      //  点击查询
      this.form.pageData.currentPage = 0
      this.$emit('request-form-list')
    },
    toReset() {
      //  点击重置
      this.form.formInputsData.forEach(item => {
        if (item.type === 'datetime') {
          item.value = []
        } else {
          item.value = ''
        }
      })
      this.$emit('update:form.formInputsData', this.form.formInputsData)
      this.$emit('to-reset')
      this.$emit('request-form-list')
    },
    toMore() {
      this.isMoreHidden = !this.isMoreHidden
    },
    isItemShow(item) {
      if (item.display !== 'more') {
        return true
      }
      return !this.isMoreHidden
    },
    isHasMoreButtons() {
      if (this.form.formInputsData && this.form.formInputsData.length > 0) {
        let result = false
        this.form.formInputsData.forEach((item) => {
          if (item.display === 'more') {
            result = true
            return false
          }
        })
        return result
      }
      return false
    }
  }
}
</script>

<style scoped>
</style>
