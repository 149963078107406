<template>
  <div class="node-picker">
    <el-button class="node-picker-btn" @click="dialogFormVisible = true">请选择</el-button>
    <el-dialog :visible.sync="dialogFormVisible" :destroy-on-close="true" :append-to-body="true">
      <div class="node-picker-content">
        <div class="node-picker-list">
          <div class="node-picker-inputs">
            <wt-search-form-item title="名称" v-model="form.formData.title" class="node-picker-input-item" type="input" />
          </div>
          <div class="node-picker-buttons">
            <wt-button type="primary" :loading="form.isFromLoading" @click="onClickSearch">查询</wt-button>
          </div>
          <div class="node-picker-table">
            <wt-form-table ref="teacherTable" :form.sync="form" height="100%" size="mini">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column prop="title" label="名称"></el-table-column>
              <el-table-column prop="mobile" label="手机号码"></el-table-column>
              <el-table-column prop="email" label="邮箱"></el-table-column>
              <el-table-column prop="webhook" label="webhook" min-width="120"></el-table-column>
              <el-table-column label="操作" fixed="right" width="80">
                <template slot-scope="scope">
                  <wt-text-button v-if="selectDataIdList.indexOf(scope.row.id) != -1" v-has-any-premission="['MANAGER_LIST:VIEW']" @click="onClickRowUnSelect(scope.row)">取消选择</wt-text-button>
                  <wt-text-button v-else v-has-any-premission="['MANAGER_LIST:VIEW']" @click="onClickRowSelect(scope.row)">选择</wt-text-button>
                </template>
              </el-table-column>
            </wt-form-table>
          </div>
          <wt-form-pagination :form="form" @request-form-list="requestDataList"/>
        </div>
      </div>
      <div slot="footer">
        <div style="display: flex;margin-right: 16px;display: inline-block;">
          <el-tag v-for="selectData in selectDataList" :key="selectData.id" effect="dark" type="success" size="medium" @close="onClickTagClose(selectData)" closable>{{selectData.title}}</el-tag>
        </div>
        <el-button type="primary" @click="onClickOk">确定</el-button>
        <el-button @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'receiverPicker',
  isAutoComponent: true,
  props: {
    multiple: {
      default: false
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      nodeData: {},
      dataValue: '',
      selectDataList: [],
      selectDataIdList: [],
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        formData: {
          xxjgid: '',
          qxdm: '',
          xm: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.requestDataList()
  },
  methods: {
    onClickSearch() {
      this.requestDataList()
    },
    onClickRowSelect(rowData) {
      const id = rowData.id
      if (this.multiple && this.selectDataIdList.indexOf(id) === -1) {
        this.selectDataIdList.push(id)
        this.selectDataList.push(rowData)
      } else if (!this.multiple) {
        this.selectDataIdList = [id]
        this.selectDataList = [rowData]
      }
    },
    onClickRowUnSelect(rowData) {
      const id = rowData.id
      if (this.multiple && this.selectDataIdList.indexOf(id) !== -1) {
        this.selectDataIdList.splice(this.selectDataIdList.indexOf(id), 1)
        this.selectDataList.splice(this.selectDataIdList.indexOf(id), 1)
      } else if (!this.multiple) {
        this.selectDataIdList = []
        this.selectDataList = []
      }
    },
    onClickTagClose(tagData) {
      const id = tagData.id
      if (this.selectDataIdList.indexOf(id) !== -1) {
        this.selectDataIdList.splice(this.selectDataIdList.indexOf(id), 1)
        this.selectDataList.splice(this.selectDataIdList.indexOf(id), 1)
      }
    },
    onClickOk() {
      if (!this.selectDataList || this.selectDataList.length <= 0) {
        this.$message({ type: 'warning', message: '请选择至少一条数据' })
        return
      }
      this.dialogFormVisible = false
      this.$emit('on-receiver-picker-change', this.selectDataList)
    },
    requestDataList() {
      const that = this
      that.form.isFromLoading = true
      that.$wtRequest({
        url: '/modules/alertingReceiver/list',
        method: 'post',
        data: {
          formData: that.form.formData,
          pageData: that.form.pageData
        }
      }).then((resp) => {
        that.form.isFromLoading = false
        that.form.formResult = resp.data
      }).catch(() => {
        that.form.isFromLoading = false
      })
    }
  }
}
</script>

<style scoped>
  .node-picker{display: flex;}
  .node-picker-inputs{display: flex;flex-wrap: wrap;}
  .node-picker-inputs .node-picker-input-item{width: calc(50% - 16px);}
  .node-picker-buttons{display: flex;justify-content: flex-end;}
  .node-picker-content{max-height: 500px;display: flex;}
  .node-picker-content .node-picker-school-tree{width: 300px;overflow: auto;flex-shrink: 0;}
  .node-picker-content .node-picker-list{margin: 0 16px;display: flex;flex-direction: column;width: calc(100% - 32px)}
  .node-picker-content .node-picker-list .node-picker-table{height: calc(100% - 140px);overflow: hidden;}
  .node-picker-input{min-width: 100px;width: 50%;}
  .node-picker-btn{margin-left: 8px;}
</style>
