<template>
  <div class="page-container">
    <wt-data-form ref="dataForm" :form.sync="form" request-list-url="modules/auth/accountList"
                  request-delete-url="modules/auth/accountDelete"
                  request-detail-url="modules/auth/accountDetail">
      <template slot="search">
        <wt-search-form-item title="搜索" v-model="form.formData.username" type="input"/>
        <wt-search-form-item title="角色" v-model="form.formData.roleName" type="input"/>
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['MANAGERLIST:ADD']" @click="$refs.dataForm.toAdd()">+ 新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['MANAGERLIST:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">
          删除
        </wt-button>
      </template>
      <template slot="table">
        <el-table-column label="序号" type="selection" :selectable="onSelectable"></el-table-column>
        <el-table-column prop="id" label="用户ID"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <el-table-column prop="lastLoginTime" label="最后登录时间"></el-table-column>
        <el-table-column prop="ip" label="最后登录IP"></el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><span :class="scope.row.isPublish|FILTER_OpinionReplyStatusClass">{{scope.row.isPublish|FILTER_IsPublishjy}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['MANAGERLIST:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑
            </wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible">
      <managerEdit :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/>
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible">
      <managerEdit :detailData="form.detailData" :view-type="form.dialogViewType"
                   @on-refresh="$refs.dataForm.requestDataList()" @on-close="form.isEditDialogVisible = false"/>
    </wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'managerList',
  data() {
    return {
      organizationList: [],
      areaList: [],
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    onSelectable(row, index) {
      return row.id > 1
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
