<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType === 'add'" v-has-any-premission="['ARTICLELIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['ARTICLELIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item label="轮播图">
            <label slot="label"><span class="red">*</span> 轮播图</label>
            <wt-oss-upload :fileList.sync="fileList" public list-type="picture" :file-size="detailForm.type === 1 ? '351px*200px' : '1920px*667px'" file-path="banner/" :limit="1">
            </wt-oss-upload>
          </el-form-item>
          <el-form-item label="轮播图">
            <label slot="label"><span class="red"></span> 显示端</label>
            <el-radio v-model="detailForm.type" :label="1">移动端</el-radio>
            <el-radio v-model="detailForm.type" :label="2">web端</el-radio>
          </el-form-item>
          <el-form-item label="排序" prop="orderNumber">
            <el-input-number v-model="detailForm.orderNumber" :min="0"></el-input-number>
          </el-form-item>
          <el-form-item label="跳转地址：" prop="skipUrl">
            <el-input v-model="detailForm.skipUrl" placeholder="请输入跳转地址"></el-input>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
export default {
  name: 'bannerEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {
        type: 1
      },
      fileList: [] // 文件列表
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    onClickAdd() {
      const that = this
      if (that.fileList && that.fileList.length < 1) {
        that.$message({ type: 'error', message: '请上传轮播图' })
        return
      }
      that.detailForm.imgId = this.fileList[0].name
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      if (that.fileList && that.fileList.length < 1) {
        that.$message({ type: 'error', message: '请上传轮播图' })
        return
      }
      that.detailForm.imgId = this.fileList[0].name
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/banner/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        this.fileList.push(
          {
            name: that.detailForm.imgId,
            url: that.detailForm.imgUrl,
            status: 'success'
          }
        )
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/banner/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/banner/add',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 40%;
}
.red {
  color: red;
}
</style>
