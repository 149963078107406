<template>
  <div class="wt-detail-section">
    <div class="wt-detail-section-title" v-if="title">
      <span class="title">{{title}}</span>
    </div>
    <div class="wt-detail-section-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtDetailSection',
  props: ['title'],
  data() {
    return {
      isExpanded: true
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
