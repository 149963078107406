<template>
  <div class="app-header">
    <ul class="app-top-nav app-layout-left">
      <li class="app-nav-item app-flexible">
        <a href="javascript:;" title="" @click="toggleSideBar">
          <i v-if="sidebar.opened" class="el-icon-s-fold"></i>
          <i v-else class="el-icon-s-unfold"></i></a>
      </li>
      <template v-if="isAppTopTabEnable && (!isAppTopTabSingleHidden || (isAppTopTabSingleHidden && activeModuleList.length > 1))">
        <li class="app-nav-title-item" :class="[activeTopItem.name === item.name ? 'active': '']" v-for="(item, index) in moduleList" :key="index" @click="onClickTopTab(item, index)">
          <span v-if="!item.hidden">{{item.meta ? item.meta.title : '#'}}</span>
        </li>
      </template>
    </ul>
    <ul class="app-top-nav app-layout-right">
<!--      <li class="app-nav-item">
        <i class="el-icon-headset" style="font-size: 30px;margin-right: 6px;vertical-align: middle"></i>
        <div style="float: right;line-height: 20px;padding-top: 5px;">
          <span>联系客服</span><br/>
          <span>{{servicePhone}}</span>
        </div>
      </li>-->
      <li class="app-nav-item">
<!--        <span style="margin-right: 20px;">坐席状态：{{currentStatus}},</span>-->
        <el-avatar style="width: 30px;height: 30px;margin-right: 6px;vertical-align: middle" :src="userInfo.headPhotoUrl" :preview-src-list="userInfo.headPhotoUrl" />
        <router-link to="">
          <span>{{userInfo.username}}</span>
        </router-link>
        <span class="loginout" @click="loginout">退出</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'WtHeader',
  data() {
    return {
      isAppTopTabEnable: process.env.VUE_APP_TOP_TAB_ENABLE === 'TRUE',
      isAppTopTabSingleHidden: process.env.VUE_APP_TOP_TAB_SINGLE_HIDDEN === 'TRUE',
      activeIndex: 0,
      servicePhone: ''
    }
  },
  created() {
  },
  mounted() {
    if (this.userInfo.headPhotoPath === '' || this.userInfo.headPhotoPath === undefined) {
      this.userInfo.headPhotoPath = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    },
    onClickTopTab(item, index) {
      this.$store.dispatch('updateModuleItem', item)
    },
    // 退出按钮
    loginout() {
      this.$confirm('是否退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.$store.dispatch('requestLogout').then(() => {
          location.reload()
        })
      }).catch(() => {
      })
    }
  },
  computed: {
    activeTopItem() {
      if (this.moduleItem) {
        return this.moduleItem
      }
      let firstItem = {}
      for (let i = 0; i < this.moduleList.length; i++) {
        if (!this.moduleList[i].hidden) {
          firstItem = this.moduleList[i]
          break
        }
      }
      return firstItem
    },
    activeModuleList() {
      return this.moduleList.filter((item) => !item.hidden)
    },
    ...mapState({
      moduleItem: state => state.auth.moduleItem,
      moduleList: state => state.auth.moduleList,
      currentStatus: state => state.ctiCloud.currentStatus,
      userInfo: state => state.auth.userInfo || {}
    }),
    ...mapGetters([
      'sidebar'
    ])
  }
}
</script>

<style scoped>
  .loginout{padding-left: 10px;}
  .loginout:hover {cursor: pointer;}
</style>
