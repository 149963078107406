<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="优惠券类型" v-model="crud.formData.type" type="select" :dataList="$wtConst.COUPON_TYPE" />
        <wt-crud-form-item title="优惠券用途" v-model="crud.formData.useClass" type="select" :dataList="$wtConst.COUPON_USE_CLASS" />
        <wt-crud-form-item title="过期时间" v-model="crud.formData.expirationDateRange" type="daterange" />
      </template>
      <template slot="toolbar-left">
        <wt-button v-has-any-premission="['COUPONMANAGE:ADD']" type="primary" @click="crud.onClickAdd('couponAdd')">新增优惠券</wt-button>
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="type" label="优惠券类型">
          <template v-slot="scope">
            {{ scope.row.type | FILTER_CouponType }}
          </template>
        </el-table-column>
        <el-table-column prop="useClass" label="优惠券用途">
          <template v-slot="scope">
            {{ scope.row.useClass | FILTER_UseClass }}
          </template>
        </el-table-column>
        <el-table-column label="优惠券内容">
          <template v-slot="scope">
            {{ couponContent(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="isPublish" label="发放状态" width="120">
          <template v-slot="scope">
            {{ scope.row.isPublish === 1 ? '已发放' : '未发放' }}
          </template>
        </el-table-column>
        <el-table-column prop="endDate" label="有效期">
          <template v-slot="scope">
            {{ `${scope.row.startDate} — ${scope.row.endDate}` }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['COUPONMANAGE:VIEW']" @click="crud.onClickView('couponView', scope.row)">查看</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" v-has-any-premission="['COUPONMANAGE:EDIT']" @click="onClickPublish('couponEdit', scope.row)">发放</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" v-has-any-premission="['COUPONMANAGE:EDIT']" @click="crud.onClickEdit('couponEdit', scope.row)">编辑</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <coupon-edit
          v-if="crud.view === 'couponAdd'"
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></coupon-edit>
        <coupon-edit
          v-if="crud.view === 'couponEdit'"
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></coupon-edit>
        <coupon-view
          v-if="crud.view === 'couponView'"
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></coupon-view>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'index',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/coupon/list',
      publishSuccessText: '操作成功',
      formData: {
        type: '',
        useClass: '',
        expirationDateRange: []
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    dateChange() {
      console.log('变化了', this)
    },
    onClickPublish(val, row) {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '此操作不可撤销，是否确认发放?').then(() => {
        that.requestPublish(val, row)
      }).catch(() => {})
    },
    requestPublish(val, row) {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/coupon/updatePublish',
        method: 'post',
        data: { id: row.id }
      }).then(() => {
        that.isFromLoading = false
        that.$message({ type: 'success', message: '操作成功' })
        that.crud.requestDataList()
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    }),
    couponContent() {
      return (row) => {
        if (row.type === 1) {
          return `满${row.fullSubtractPriceDollar}减${row.favourablePriceDollar}元`
        } else {
          return `${row.discount}折`
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-cascader {
  line-height: 1;
}
::v-deep .el-cascader .el-input__inner {
  height: 28px;
  line-height: 28px;
}
::v-deep .el-cascader .el-input__icon {
  line-height: 28px;
}
</style>
