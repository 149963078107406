<template>
  <div class="app-layout-admin" :class="{'app-side-shrink':!sidebar.opened}">
    <WtHeader/>
    <WtSide/>
    <WtOrgAuth/>
    <div class="app-body">
      <wt-breadcrumb/>
      <div id="app-page-container">
        <router-view :key="$route.fullPath"></router-view>
<!--        <router-view></router-view>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'layout',
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ])
  }
}
</script>
