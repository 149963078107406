<template>
  <div v-if="isItemShow">
    <el-menu-item v-if="!item.children || item.children.length <= 0" :index="itemIndex" @click="onClickItem(item)">
      <span>{{item.meta ? item.meta.title : ''}}</span>
      <span class="numShow numShowItem" v-if="item.meta.title ==='诊疗过程咨询' && totalTreat !==0">{{totalTreat}}</span>
      <span class="numShow numShowItem" v-if="item.meta.title ==='团队客服咨询' && totalTeam !==0">{{totalTeam}}</span>
    </el-menu-item>
    <el-submenu v-else :index="itemIndex">
      <template slot="title">
        <i :class="[item.meta && item.meta.icon ? item.meta.icon : 'el-icon-discover']"></i>
        <span slot="title">{{item.meta ? item.meta.title : ''}}</span>
        <span class="numShow" v-if="item.meta.title ==='咨询处理' && Number(totalTreat + totalTeam) !==0">{{Number(totalTreat + totalTeam)}}</span>
      </template>
      <wt-side-menu-item v-for="(subItem, subIndex) in item.children" :item="subItem" :key="subIndex" :itemIndex="subItem.name"></wt-side-menu-item>
    </el-submenu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getStorgeItem } from '@/utils/auth'
export default {
  name: 'WtSideMenuItem',
  props: ['item', 'itemIndex'],
  data() {
    return {
      totalTreatPro: getStorgeItem('totalTreat') ? getStorgeItem('totalTreat') : 0,
      totalTeamPro: getStorgeItem('totalTeam') ? getStorgeItem('totalTeam') : 0
    }
  },
  methods: {
    onClickItem(item) {
      sessionStorage.setItem('MENU_ITEM_INDEX', this.$route.path.replace('/', ''))
      this.$router.push({ name: item.name, query: { _t: new Date().getTime() }})
    }
  },
  computed: {
    moduleName() {
      return this.item.name && this.item.name.toUpperCase()
    },
    isItemShow() {
      if (this.moduleCodeList.indexOf(this.moduleName) === -1) {
        return false
      }
      return !this.item.hidden && (true || this.moduleNames.indexOf((this.item.name ? this.item.name : '').toUpperCase()) !== -1)
    },
    ...mapState({
      moduleCodeList: state => state.auth.moduleCodeList,
      moduleNames: state => state.auth.moduleNames,
      totalTreat: state => state.auth.totalTreat, // 诊疗过程未读
      totalTeam: state => state.auth.totalTeam // 团队客服未读
    })
  },
  mounted() {
    // console.log(this.itemIndex)
  },
  watch: {
  }
}
</script>
<style>
.numShow{
  width: 16px;
  height: 16px;
  line-height: 16px;
  color:#fff;
  font-size: 12px;
  background: red;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  right:35px;
  top:15px;
}
.numShowItem {
  right: 10px;
}
</style>
