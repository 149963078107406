<template>
  <div>
    <el-card>
      <div class="page-title">{{ title }}</div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: ['title']
}
</script>

<style scoped>
/deep/ .el-card__body {
  padding: 0 !important;
}

.page-title {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 20px;
  color: black;
}
</style>
