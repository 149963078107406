<template>
  <div class="app-container">
    <wt-form-table :form.sync="form" @on-button-action="onButtonAction" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: { //  表单数据
        isFormLoading: false,
        formCode: 'MODULES_ACCOUNT',
        formComponent: {
          view: { type: 'component', componentName: 'Duty-view', value: 'queryDutyDetailData' },
          add: { type: 'component', componentName: 'Duty-edit', value: 'resetDutyDetailData' },
          edit: { type: 'component', componentName: 'Duty-edit', value: 'queryDutyDetailData' },
          list: { type: 'default', value: '/modules/auth/accountList' },
          delete: { type: 'default', value: '/modules/auth/accountDelete' }
        },
        formInputsData: [
          { name: '名称', key: 'dutyName', value: '', type: 'text' },
          { name: '时间', key: 'updateTimeRange', value: [], type: 'datetime' }],
        formButtonsData: [{ name: '+ 新增', key: 'ADD', action: 'ADD', className: 'primary', hidden: false },
          { name: '批量删除', key: 'DELETE', action: 'DELETE_LIST', className: 'danger', hidden: false }],
        formTableData: [
          { name: '主键ID', key: 'id', type: 'text' },
          { name: '名称', key: 'dutyName', type: 'text' },
          { name: '部门', key: 'organizationId', type: 'text' },
          { name: '时间字段', key: 'updateTime', sortable: 'custom', type: 'datetime' },
          { name: '状态值字段', key: 'isPublish', type: 'enum', filter: 'FILTER_IsPublish' }],
        formTableButtonsData: [{ name: '删除', key: 'DELETE', action: 'DELETE', className: 'danger', hidden: false }],
        formResult: {},
        pageData: { currentPage: 0, pageSize: 20, sortName: '', sortOrder: '' },
        formTableProp: {
          buttonHidden: true
        }
      }
    }
  },
  methods: {
    /*  ------------------------- 按钮相关  -------------------------  */
    onButtonAction(data) {
      console.log('data', data)
    }
  }
}
</script>
