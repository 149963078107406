// import IconTest from '@/assets/images/icon_test.png'
const wtConst = {
  ISPUBLISH_ENUM_CONTENT: {
    0: { text: '禁用', color: '#333333', icon: 'el-icon-warning', iconColor: '#DE504E' },
    1: { text: '启用', color: '#333333', icon: 'el-icon-success', iconColor: '#50D4AB' }
  },
  ISPUBLISH_ENUM_OPTIONS: [
    { value: 1, label: '启用' },
    { value: 0, label: '禁用' }
  ],
  ISPUBLISH__OPTIONS: [
    { value: 1, label: '已发布' },
    { value: 0, label: '未发布' }
  ],
  ISUSE__OPTIONS: [
    { value: 1, label: '已使用' },
    { value: 0, label: '未使用' }
  ],
  ISDYNAMIC__OPTIONS: [
    { value: 1, label: '是' },
    { value: 0, label: '否' }
  ],
  MENU_ISHIDDEN_ENUM_CONTENT: {
    0: { text: '显示', color: '#50D4AB', weight: 'bold' },
    1: { text: '隐藏', color: '#999999' }
  },
  MEMBER_TYPE_OPTIONS: [
    { value: 1, label: '初级会员' },
    { value: 2, label: '高级会员' },
    { value: 3, label: '优享会员' }
  ],
  ADVANCED_TYPE_OPTIONS: [
    { value: 1, label: '热门' },
    { value: 2, label: '最新' },
    { value: 3, label: '爆款' },
    { value: 0, label: '无' }
  ],
  DICT_TYPE_OPTIONS: [
    { value: 1, label: '研报标签' },
    { value: 2, label: '课程标签' },
    { value: 3, label: '热搜词' },
    { value: 4, label: '文章标签' },
    { value: 5, label: '动态标签' }
  ],
  COUPON_TYPE: [
    { value: 1, label: '满减券' },
    { value: 2, label: '折扣券' }
  ],
  COUPON_USE_CLASS: [
    { value: 1, label: '课程' },
    { value: 2, label: '研报' },
    { value: 3, label: '会员充值' }
  ],
  COURSE_PAY_TYPE: [
    { value: 1, label: '免费' },
    { value: 2, label: '付费' }
  ],
  COURSE_PLAY_CLASS: [
    { value: 1, label: '直播' },
    { value: 2, label: '录播' }
  ],
  COMMENT_STATUS: [
    { value: 0, label: '待审核' },
    { value: 1, label: '审核通过' },
    { value: 2, label: '审核驳回' }
  ],
  PAY_STATUS: [
    { value: 0, label: '待支付' },
    { value: 1, label: '已支付' },
    { value: 2, label: '已取消' }
  ]
}
export default wtConst
