<template>
  <div class="wt-detail-form-buttons-container">
    <div class="wt-detail-form-buttons-title">
      <div class="left">
        <template v-if="!$slots.left">
          <span><i class="el-icon-document"></i> {{title}}</span>
          <span style="font-size: 12px;color: #999999">{{subTitle}}</span>
        </template>
        <slot v-else name="left"></slot>
      </div>
      <div class="right" v-show="!isRightHidden">
        <div class="wt-section-title-icon" @click="myIsExpanded = !myIsExpanded">
          <span>{{myIsExpanded?'收起':'展开'}}</span>
          <i :class="{'el-icon-caret-right': !myIsExpanded, 'el-icon-caret-bottom': myIsExpanded}"></i>
        </div>
      </div>
    </div>
    <div v-show="myIsExpanded" class="wt-form-inputs-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-detail-blank-section',
  props: ['title', 'isRightHidden', 'subTitle', 'isExpanded'],
  data() {
    return {
      myIsExpanded: false
    }
  },
  mounted() {
    this.myIsExpanded = this.isExpanded
  }
}
</script>

<style scoped>
  .wt-detail-form-buttons-container {
    margin: 20px 20px 16px;
  }

  .wt-detail-form-buttons-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #ffffff;
    border-bottom: 1px solid #f4f4f4;
  }

  .wt-detail-form-buttons-title .left {
  }

  .wt-detail-form-buttons-title .left span {
    font-size: 16px;
    color: #09407e;
    line-height: 20px;
    margin-left: 20px;
    font-weight: bold;
  }

  .wt-detail-form-buttons-title .right {
    margin-right: 20px;
  }
  .wt-section-title-icon {
    cursor: pointer;
    font-size: 14px;
    color: #999999;
  }
</style>
