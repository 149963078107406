<template>
  <div><slot></slot></div>
</template>

<script>
export default {
  name: 'wtFormItem',
  props: ['type']
}
</script>

<style scoped>

</style>
