<template>
  <div>
    <div>
      <wt-button type="primary" @click="onUploadClick">上传视频</wt-button>
<!--      {{fileName}}-->
      <input type="file" name="videoFile" id="videoFile" accept="video/mp4" @change="fileChange" class="input-file"/>
    </div>
    <div class="tip">只能上传单个mp4文件，且不超过2G</div>
    <el-progress v-if="uploadPercentage !== 0" :percentage="uploadPercentage" style="margin-top: 5px; width: 60%;"></el-progress>
  </div>
</template>

<script>
export default {
  name: 'wt-aliyun-video-upload',
  data() {
    return {
      uploader: null,
      fileName: '',
      uploadPercentage: 0
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$wtRequest({
        url: '/modules/vod/config',
        method: 'post',
        data: {}
      }).then((resp) => {
        // console.log('上传凭证', resp)
        const { userId, regionId, courseCateId } = resp.data
        this.initUploader(userId, regionId, courseCateId)
      }).catch((e) => {
      })
    },
    initUploader(userId, region, cateId) {
      const that = this
      // eslint-disable-next-line no-undef
      that.uploader = new AliyunUpload.Vod({
        // userID，必填，您可以使用阿里云账号访问账号中心（https://account.console.aliyun.com/），即可查看账号ID
        userId,
        // 上传到视频点播的地域，默认值为'cn-shanghai'
        region,
        // 分片大小默认1 MB，不能小于100 KB（100*1024）
        partSize: 1048576,
        // 并行上传分片个数，默认5
        parallel: 5,
        // 网络原因失败时，重新上传次数，默认为3
        retryCount: 3,
        // 网络原因失败时，重新上传间隔时间，默认为2秒
        retryDuration: 2,
        // 开始上传
        'onUploadstarted': function(uploadInfo) {
          // console.log('开始上传', uploadInfo)
          let url = ''
          if (uploadInfo.videoId) {
            // 如果uploadInfo.videoId存在，调用刷新音视频上传凭证接口
            url = '/modules/vod/refreshUploadVideo' // 此处URL需要替换为服务端AppServer对应的地址
          } else {
            // 如果uploadInfo.videoId不存在，调用获取音视频上传地址和凭证接口
            url = '/modules/vod/createUploadVideo' // 此处URL需要替换为服务端AppServer对应的地址
          }
          // console.log('获取凭证权限接口地址', url)
          // 获取UploadAuth, UploadAddress, VideoId可能因AppServer实现有差异
          that.$wtRequest({
            url,
            method: 'post',
            data: {
              cateId,
              title: uploadInfo.file.name,
              fileName: uploadInfo.file.name
            }
          }).then((res) => {
            // console.log('上传权限设置', res)
            that.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId)
          }).catch((e) => {
          })
        },
        // 文件上传成功
        'onUploadSucceed': function(uploadInfo) {
          console.log('上传成功', uploadInfo)
          that.resetVideoFile()
          that.$emit('uploadSuccess', uploadInfo)
        },
        // 文件上传失败
        'onUploadFailed': function(uploadInfo, code, message) {
          console.log('上传失败', uploadInfo, code, message)
        },
        // 文件上传进度，单位：字节
        'onUploadProgress': function(uploadInfo, totalSize, loadedPercent) {
          console.log('上传进度', uploadInfo, totalSize, loadedPercent)
          that.uploadPercentage = Number(parseFloat(loadedPercent * 100).toPrecision(2))
        },
        // 上传凭证或STS token超时
        'onUploadTokenExpired': function(uploadInfo) {
          console.log('上传凭证或STS token超时', uploadInfo)
        },
        // 全部文件上传结束
        'onUploadEnd': function(uploadInfo) {
          console.log('全部文件上传结束', uploadInfo)
        }
      })
    },
    onUploadClick() {
      document.getElementById('videoFile').click()
    },
    resetVideoFile() {
      document.getElementById('videoFile').value = ''
    },
    fileChange(e) {
      const file = e.target.files[0]
      console.log(file)
      console.log(file.size / 1024 / 1024 / 1024)
      if (file.size / 1024 / 1024 / 1024 > 2) {
        this.$message.error('文件大小超出2G')
        this.resetVideoFile()
        return
      }
      this.uploadPercentage = 0
      this.fileName = file.name
      this.uploader.addFile(file, null, null, null, null)
      this.uploader.startUpload()
    },
    getVideoDuration(url) {
      function getTime(url) {
        const video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = function() {
          const url = video.src
          window.URL.revokeObjectURL(url)
          const duration = video.duration
          console.log('视频长度为：' + duration)
        }
        video.src = url
      }
      getTime(url)
    }
  }
}
</script>

<style scoped>
.input-file {
  width: 0;
  height: 0;
}
.tip {
  margin-top: 12px;
  font-size: 14px;
}
</style>
