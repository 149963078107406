<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType == 'add'" v-has-any-premission="['ROLELIST:ADD']" type="primary" :loading="isButtonLoading"
                 @click="onClickAdd">保存
      </wt-button>
      <wt-button v-if="viewType == 'edit'" v-has-any-premission="['ROLELIST:EDIT']" type="primary"
                 :loading="isButtonLoading" @click="onClickSave">保存
      </wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息" v-loading="isFromLoading">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item label="名称：" prop="roleName"
                        :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.roleName" maxlength="20" placeholder="请输入"
                      :disabled="viewType === 'view'"></el-input>
          </el-form-item>
          <el-form-item label="描述：" prop="description"
                        :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.description" maxlength="30" placeholder="请输入"
                      :disabled="viewType === 'view'"></el-input>
          </el-form-item>
          <el-form-item label="设置启用：" prop="isPublish">
            <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0"
            :disabled="viewType === 'view'" active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
          </el-form-item>
        </el-form>
      </wt-detail-section>
      <wt-detail-section title="权限设置" v-loading="isFromLoading">
        <div class="module-permission-content">
          <el-tree class="left-content" ref="moduleTree"
                   @check-change="handleCheckChange"
                   :expand-on-click-node="false"
                   :data="moduleTreeData" node-key="id" :default-checked-keys="defaultCheckedKeys"
                   @check="onModuleCurrentChanged" accordion show-checkbox>
          </el-tree>
          <div class="right-content" v-if="isPermissionItemVisibility">
            <permission-item v-if="!item.hidden" :key="'permission_' + index" v-for="(item, index) in staticRoutes"
                             :is-read-mode="isReadMode"
                             :item="item" :level="0" type="picker" :checked-module-codes="checkedModuleCodes"
                             :checked-permission-codes="checkedPermissionCodes"
                             :module-permission-map="modulePermissionMap"
                             @on-click-tag="onClickTag" @on-click-item-action="onClickItemAction"
            ></permission-item>
          </div>
        </div>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'

export default {
  name: 'RoleEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isPermissionItemVisibility: true,
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      checkedPermissionCodes: [],
      modulePermissionMap: {},
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        orderNum: 0,
        isPublish: 1,
        type: 2
      }
    }
  },
  mounted() {
    this.requestPermissionList()
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    handleCheckChange(data, checked, indeterminate) {
    },
    getChildrenKeys(array, res) {
      if (!array || array.length === 0) { // 终止递归
        return []
      }
      res = res.concat(array.map(item => item.id))
      array.forEach(item => {
        if (item.children && item.children.length > 0) {
          res = res.concat(this.getChildrenKeys(item.children, res))
        }
      })
      return res
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    onClickTag(code) {
      const index = this.checkedPermissionCodes.indexOf(code)
      if (index !== -1) {
        this.checkedPermissionCodes.splice(index, 1)
      } else {
        this.checkedPermissionCodes.push(code)
      }
    },
    onClickItemAction({ action, data }) {
      const moduleName = data.name.toUpperCase()
      if (action === 'currentCheckAll') {
        this.checkAllModulePermission(moduleName)
      } else if (action === 'currentUnCheckAll') {
        this.unCheckAllModulePermission(moduleName)
      } else if (action === 'checkAll') {
        const that = this
        this.staticRoutes.forEach(item => {
          console.log(item)
          const moduleName = item.name.toUpperCase()
          if (!item.hidden && that.checkedModuleCodes.indexOf(moduleName) !== -1) {
          }
        })
      } else if (action === 'unCheckAll') {
      }
    },
    checkAllModulePermission(moduleName) {
      const codes = ['VIEW', 'ADD', 'EDIT', 'DELETE']
      if (this.modulePermissionMap[moduleName] && this.modulePermissionMap[moduleName].length > 0) {
        for (let i = 0; i < this.modulePermissionMap[moduleName].length; i++) {
          codes.push(this.modulePermissionMap[moduleName][i].permissionCode.toUpperCase())
        }
      }
      for (let i = 0; i < codes.length; i++) {
        const permissionCode = codes[i]
        if (this.checkedPermissionCodes.indexOf(moduleName + ':' + permissionCode) === -1) {
          this.checkedPermissionCodes.push(moduleName + ':' + permissionCode)
        }
      }
    },
    unCheckAllModulePermission(moduleName) {
      const codes = ['VIEW', 'ADD', 'EDIT', 'DELETE']
      if (this.modulePermissionMap[moduleName] && this.modulePermissionMap[moduleName].length > 0) {
        for (let i = 0; i < this.modulePermissionMap[moduleName].length; i++) {
          codes.push(this.modulePermissionMap[moduleName][i].permissionCode.toUpperCase())
        }
      }
      for (let i = 0; i < codes.length; i++) {
        const permissionCode = codes[i]
        const permissionIndex = this.checkedPermissionCodes.indexOf(moduleName + ':' + permissionCode)
        if (permissionIndex !== -1) {
          this.checkedPermissionCodes.splice(permissionIndex, 1)
        }
      }
    },
    onModuleCurrentChanged(nodeData, node) {
      const res = this.getChildrenKeys(nodeData.children, [nodeData.id])
      console.log(res)
      res.forEach(item => {
        this.checkAllModulePermission(item)
      })
      this.checkedModuleCodes = node.checkedKeys.concat(node.halfCheckedKeys)
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/auth/roleDetail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = { ...resp.data, type: 3 } || {}
        that.defaultCheckedKeys = that.detailForm.moduleList.concat([])
        that.isPermissionItemVisibility = false
        that.$nextTick(() => {
          that.checkedModuleCodes = that.detailForm.moduleList
          that.checkedPermissionCodes = that.detailForm.permissionList || []
          // console.log(that.checkedPermissionCodes)
          that.$nextTick(() => {
            that.isPermissionItemVisibility = true
          })
        })
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestPermissionList() {
      const that = this
      that.$wtRequest({
        url: 'modules/auth/permissionList',
        method: 'post',
        data: {}
      }).then((resp) => {
        const tempMap = {}
        resp.data.forEach(item => {
          if (!tempMap[item.moduleName]) {
            tempMap[item.moduleName] = []
          }
          tempMap[item.moduleName].push(item)
        })
        that.modulePermissionMap = tempMap
      }).catch(() => {
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      const permissionList = that.checkedPermissionCodes.filter(permissionCode => {
        for (let i = 0; i < that.checkedModuleCodes.length; i++) {
          if (permissionCode.indexOf(that.checkedModuleCodes[i]) === 0) {
            return true
          }
        }
        return false
      })
      that.$wtRequest({
        url: 'modules/auth/roleUpdate',
        method: 'post',
        data: Object.assign(that.detailForm, {
          moduleList: this.checkedModuleCodes,
          permissionList: permissionList
        })
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      const permissionList = that.checkedPermissionCodes.filter(permissionCode => {
        for (let i = 0; i < that.checkedModuleCodes.length; i++) {
          if (permissionCode.indexOf(that.checkedModuleCodes[i]) === 0) {
            return true
          }
        }
        return false
      })
      that.$wtRequest({
        url: 'modules/auth/roleAdd',
        method: 'post',
        data: Object.assign(that.detailForm, {
          moduleList: this.checkedModuleCodes,
          permissionList: permissionList
        })
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
    moduleTreeData() {
      const that = this
      const treeData = []
      const makeChildrenFunction = (staticRoute) => {
        const children = []
        // eslint-disable-next-line eqeqeq
        if (that.detailForm.type === 1) {
          if (!staticRoute.hidden && staticRoute.props && staticRoute.props.type !== 2) {
            if (staticRoute.children && staticRoute.children.length > 0) {
              const parentRouteId = staticRoute.name.toUpperCase()
              if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
                that.defaultCheckedKeys.splice(that.defaultCheckedKeys.indexOf(parentRouteId), 1)
              }
              staticRoute.children.forEach((child) => {
                const routeId = child.name.toUpperCase()
                if (child.props && child.props.type !== 2) {
                  children.push({
                    id: routeId,
                    label: child.meta ? child.meta.title : '',
                    disabled: that.isReadMode,
                    children: makeChildrenFunction(child)
                  })
                }
              })
            }
          }
        } else {
          if (!staticRoute.hidden && staticRoute.props && staticRoute.props.type !== 1) {
            if (staticRoute.children && staticRoute.children.length > 0) {
              const parentRouteId = staticRoute.name.toUpperCase()
              if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
                that.defaultCheckedKeys.splice(that.defaultCheckedKeys.indexOf(parentRouteId), 1)
              }
              staticRoute.children.forEach((child) => {
                const routeId = child.name.toUpperCase()
                if (child.props && child.props.type !== 1) {
                  children.push({
                    id: routeId,
                    label: child.meta ? child.meta.title : '',
                    disabled: that.isReadMode,
                    children: makeChildrenFunction(child)
                  })
                }
              })
            }
          }
        }

        return children
      }
      this.staticRoutes.forEach(staticRoute => {
        // eslint-disable-next-line eqeqeq
        if (that.detailForm.type === 1) {
          if (!staticRoute.hidden && staticRoute.props && staticRoute.props.type !== 2) {
            const routeId = staticRoute.name.toUpperCase()
            treeData.push({
              id: routeId,
              label: staticRoute.meta ? staticRoute.meta.title : '',
              disabled: that.isReadMode,
              children: makeChildrenFunction(staticRoute)
            })
          }
        } else {
          if (!staticRoute.hidden && staticRoute.props && staticRoute.props.type !== 1) {
            const routeId = staticRoute.name.toUpperCase()
            treeData.push({
              id: routeId,
              label: staticRoute.meta ? staticRoute.meta.title : '',
              disabled: that.isReadMode,
              children: makeChildrenFunction(staticRoute)
            })
          }
        }
      })
      return treeData
    },
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped>
.module-permission-content {
  display: flex;
  width: 100%;
}

.module-permission-content .left-content {
  width: 250px;
  padding: 0 24px;
}

.module-permission-content .right-content {
  flex: 1;
}
</style>
