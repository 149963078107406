<template>
  <div class="common-detail">

    <!--  头部  -->
    <wt-common-detail-header>
      <slot name="header"></slot>
    </wt-common-detail-header>

    <!--    主体-->
    <div class="wt-m-t-20 main-content">
      <el-row :gutter="20" class="wt-flex wt-col-top" style="margin: 0;">
        <el-col :xs="hideAside?'':18" :sm="hideAside?'':18" :md="hideAside?'':18" :lg="hideAside?'':17" :xl="hideAside?'':18">
          <wt-common-detail-main style="flex: 1">
            <slot name="main"></slot>
          </wt-common-detail-main>
        </el-col>

        <el-col v-if="!hideAside" :xs="6" :sm="6" :md="6" :lg="7" :xl="6">
          <wt-common-detail-aside v-bind="$attrs">
            <slot name="aside"></slot>
          </wt-common-detail-aside>
        </el-col>
      </el-row>

    </div>
    <!--    侧边-->
    <wt-common-detail-footer v-if="!hideFooter">
      <slot name="footer"></slot>
    </wt-common-detail-footer>
  </div>
</template>

<script>
import wtCommonDetailAside from './wt-common-detail-aside'
import wtCommonDetailHeader from './wt-common-detail-header'
import wtCommonDetailFooter from './wt-common-detail-footer'
import wtCommonDetailMain from './wt-common-detail-main'

export default {
  name: 'wtCommonDetail',
  components: {
    wtCommonDetailAside,
    wtCommonDetailHeader,
    wtCommonDetailFooter,
    wtCommonDetailMain
  },
  props: {
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideAside: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">

.main-content {
  margin: 0 20px;
}
</style>
