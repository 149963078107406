<template>
  <div>
    <el-upload v-if="!isReadMode" class="avatar-uploader" drag
               :accept="accept" ref="upload" action="string" :on-change="getFile"
               :http-request="handleUpload" :show-file-list="false" :before-upload="beforeUpload">
      <div v-if="!isReadMode" class="el-upload__tip">{{tip}}</div>
      <i class="el-icon-upload"></i>
      <p>点击或将文件拖拽到这里上传</p>
    </el-upload>
    <div class="buttons">
      <el-button size="small" v-if="!isReadMode" @click="$refs.upload.$refs['upload-inner'].$refs.input.click()">上传</el-button>
      <el-button size="small" v-if="!isReadMode" @click="reset">重置</el-button>
    </div>

    <div class="upload-image-list">
      <div v-for="(item, index) in fileList" :key="index" class="upload-image-item">
        <el-image class="upload-image-item-img" fit="cover" :src="item.url" :preview-src-list="[item.url]"></el-image>
        <div class="upload-image-item-ext">
          <span style="white-space: normal;word-break: break-word;">{{item.name}}</span>
        </div>
        <div v-if="!isReadMode" class="upload-image-item-delete" @click="onDeleteItem(index)"><i class="el-icon-close"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter'
import SparkMD5 from 'spark-md5'
import * as imageConversion from 'image-conversion'
export default {
  name: 'WtImageUpload',
  mixins: [Emitter],
  props: {
    ocr: {
      type: Number,
      default: null
    },
    value: {},
    public: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'string'// 支持string（单独的url）、list（url的列表）、json（json的列表）、jsonstring(json的string)
    },
    readonly: {
      type: Boolean,
      default: false
    },
    headPhotoZip: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: 20
    },
    tip: {
      type: String,
      default: '只能上传jpg/png文件，且不超过4M'
    },
    accept: {
      type: String,
      default: '.jpg,jpeg,.png'
    }
  },
  data() {
    return {
      isInited: false,
      uploadUrl: '',
      fileList: [],
      imageUrl: '',
      dataForm: {},
      signUrl: '',
      signData: {},
      isLimit: false,
      isSize: false,
      isType: false
    }
  },
  watch: {
    value: {
      handler() {
        if (typeof (this.value) === 'string' && !this.isInited && this.fileList.length <= 0) {
          this.isInited = true
          if (this.value.indexOf(',') !== -1) {
            const list = this.value.split(',')
            for (let i = 0; i < list.length; i++) {
              this.dataForm.key = list[i]
              this.backImg(this.dataForm.key)
            }
            this.updateValue()
          } else if (this.value !== '') {
            this.dataForm.key = this.value
            this.backImg(this.dataForm.key)
            this.updateValue()
          }
        }
        this.isInited = true
        this.dispatch('ElFormItem', 'el.form.change', this.value)
      },
      deep: true
    }
  },
  mounted() {
    if (this.public) {
      this.signUrl = 'modules/resourceFile/getPublicPreSignUrl'
      this.signData = { suffix: '' }
    } else {
      this.signUrl = 'modules/resourceFile/getPreSignUrl'
      this.signData = { }
    }
  },
  methods: {
    // 点击重置
    reset() {
      this.fileList = []
      this.updateValue()
    },
    getFile(file, fileList) {
      // eslint-disable-next-line eqeqeq
      if (this.ocr === 1) {
        var isLimit, isSize, isType
        isLimit = this.fileList && this.fileList.length < this.limit
        isSize = file.size / 1024 / 1024 < this.max
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        isType = testmsg === 'jpg' || testmsg === 'png' || testmsg === 'jpeg'
        if (isLimit && isSize && isType) {
          this.$emit('on-ocr', file)
        }
      }
    },
    async handleUpload(item) {
      const that = this
      const theSize = item.file.size / 1024 / 1024
      if (this.isLimit && this.isSize && this.isType) {
        const form = new FormData() // FormData 对象
        Object.keys(that.dataForm).forEach(key => {
          form.append(key, that.dataForm[key])
        })
        if (this.headPhotoZip && theSize > 2) {
          // return new Promise((resolve, reject) => {
          await imageConversion.compressAccurately(item.file, 2000).then(res => {
            res.name = item.file.name
            res.uid = item.file.uid
            console.log(res)
            item.file = res
          })
          // })
        }
        form.append('file', item.file)
        console.log(item.file)
        that.$wtUploadRequest({
          url: '',
          method: 'post',
          data: form
        }).then((resp) => {
          that.backImg(that.dataForm.key)
        }).catch(() => {
          this.$message.error('上传失败，请稍后重试')
        })
      }
    },
    backImg(fileName) {
      const that = this
      that.$wtRequest({
        url: 'modules/resourceFile/getSignUrl',
        method: 'post',
        data: { fileName: fileName }
      }).then((resps) => {
        that.fileList.push({ name: fileName, url: resps.data, fileUrl: resps.data })
        that.updateValue()
      }).catch(() => {
        console.log('图片回显失败')
      })
    },
    async beforeUpload(file) {
      const that = this
      this.isLimit = this.fileList && this.fileList.length < this.limit
      this.isSize = file.size / 1024 / 1024 < this.max
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      this.isType = testmsg === 'jpg' || testmsg === 'png' || testmsg === 'jpeg'
      if (!this.isLimit) {
        this.$message({ type: 'warning', message: `最多可以上传${this.limit}个文件` })
      }
      if (!this.isSize) {
        this.$message({ type: 'warning', message: `上传大小不能超过${this.max}MB` })
      }
      if (!this.isType) {
        this.$message({
          message: '上传文件只能是jpg/png/jpeg格式!',
          type: 'warning'
        })
      }
      if (this.isLimit && this.isSize && this.isType) {
        await that.$wtRequest({
          url: that.signUrl,
          method: 'post',
          data: {
            suffix: testmsg
          }
        }).then((resp) => {
          that.dataForm = resp.data
        }).catch(() => {
          console.log('获取签名失败')
        })
        return that.checkFileMd5(file).then((md5File) => {
          return that.requestCheckFile([md5File])
        })
      }
      return this.isLimit && this.isSize && this.isType
      // 获取上传文件签名
    },
    onDeleteItem(index) {
      this.fileList.splice(index, 1)
      this.updateValue()
    },
    updateValue() {
      if (this.type === 'string') {
        if (this.fileList && this.fileList.length > 0) {
          this.$emit('input', this.fileList[0].name)
        } else {
          this.$emit('input', '')
        }
      } else if (this.type === 'jsonstring') {
        if (this.fileList && this.fileList.length > 0) {
          const fileLists = []
          Object.keys(this.fileList).forEach(key => {
            fileLists.push(this.fileList[key].name)
          })
          const fileListStr = fileLists.join(',')
          this.$emit('input', fileListStr)
        } else {
          this.$emit('input', '')
        }
      } else {
        const fileLists = []
        Object.keys(this.fileList).forEach(key => {
          fileLists.push(this.fileList[key].name)
        })
        this.$emit('input', fileLists)
        console.log(fileLists)
      }
    },
    checkFileMd5(file) {
      const that = this
      return new Promise((resolve, reject) => {
        try {
          that.getFileMd5(file, (md5File, data) => {
            if (data.resultCode === 'SUCCESS') {
              file.md5 = data.fileMd5
              resolve(file)
            } else {
              reject(new Error('文件检查失败'))
            }
          })
        } catch (e) {
          reject(new Error(e))
        }
      })
    },
    requestCheckFile(checkFileList) {
      const that = this
      const singleMd5 = checkFileList[0].md5
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: 'modules/resourceFile/checkFile',
          method: 'post',
          data: { fileMd5: [singleMd5] }
        }).then((resp) => {
          if (resp.code === '0' && resp.data && resp.data[singleMd5]) {
            const fileResult = resp.data[singleMd5]
            that.fileList.push({ name: fileResult.name, url: that.$wtUtil.buildImageUrl(fileResult.url), fileUrl: fileResult.url })
            that.updateValue()
            return reject(new Error(''))
          }
          return resolve()
        }).catch((e) => {
          return reject(new Error(e))
        })
      })
    },
    requestFileUpload(arg) {
      // console.log('requestFileUpload', arg)
    },
    getFileMd5(file, callback) {
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunkSize = 1024 * 1024 * 2
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          const fileMd5 = spark.end()
          callback(file, { resultCode: 'SUCCESS', fileMd5: fileMd5 })
        }
      }

      fileReader.onerror = function(err) {
        callback(file, { resultCode: 'FAIL', msg: err })
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    }
  },
  computed: {
    isReadMode() {
      return (this.readonly === '') || this.readonly
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-image-list{display: flex;flex-wrap: wrap;}
  .upload-image-item{
    position: relative;
    width: 100%; height: 100px;max-width: 250px;background-color: #FFFFFF;border-radius: 4px;border: 1px solid #c0ccda;margin: 8px 16px;display: flex;align-items: center;justify-content: space-between;
  }
  .upload-image-item-delete{
    position: absolute;right: 10px;top: 0;cursor: pointer;
    :hover,:active{opacity: 0.75;}
  }
  .upload-image-item-img{width: 80px; height: 80px;margin: 10px;}
  .upload-image-item-ext{
    width: calc(100% - 110px);display: flex;flex-direction: column;justify-content: center;margin-right: 10px;
    > span{line-height: 1;}
  }
  .buttons {
    width: 200px;
  }
</style>
