<template>
  <div>
    <div v-if="title" class="wt-section wt-detail-form">
      <div class="wt-section-title">
        {{title}}
        <div class="wt-section-title-icon" @click="isExpanded = !isExpanded"><i :class="{'el-icon-caret-right': !isExpanded, 'el-icon-caret-bottom': isExpanded}"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-section',
  props: ['title', 'is-expanded']
}
</script>

<style scoped>

</style>
