<template>
  <div class="page-container">
    <PageHeader title="基本信息"/>
    <el-card>
      <div class="content">
        <el-form :model="addForm" :rules="rules" ref="addFormRef" label-width="120px">
          <el-form-item label="详情:" prop='content'>
            <WtTinymce v-model="addForm.content"></WtTinymce>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="card">
      <div class='save'>
        <el-button type="info" @click="closeBtn">关闭</el-button>
        <el-button v-has-any-premission="['AGREEMENTA:EDIT']" type="primary" @click="addSave">保存提交</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import PageHeader from '../../wisdom/components/PageHeader'
import service from '../../../utils/request'

export default {
  name: 'about',
  components: {
    PageHeader
  },
  data() {
    return {
      addForm: {
        content: ''
      },
      rules: {
        content: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }]
      }
    }
  },
  created() {
  },
  methods: {
    closeBtn() {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        this.$confirm('是否保存本次编辑?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const options = {
            type: 1,
            content: this.addForm.content,
            id: 1
          }
          service.post('modules/systemContent/updatePrivacyPolic', options).then(res => {
            if (!res) return false
            this.$message.success('编辑内容已保存！')
          })
        })
      })
    },
    addSave() {
      this.$confirm('是否确定内容无误并提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          const options = {
            type: 1,
            content: this.addForm.content,
            id: 1
          }
          service.post('modules/systemContent/updatePrivacyPolic', options).then(res => {
            if (!res) return false
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
          })
        })
      })
    },
    // 获取数据
    getContentDetail() {
      const options = {
        type: 1
      }
      service.post('modules/systemContent/privacyPolicDetail', options).then(res => {
        if (!res) return false
        this.addForm.content = res.data.content
      })
    }
  },
  computed: {
    ...mapState({})
  },
  mounted() {
    this.getContentDetail()
  }
}
</script>
<style scoped>
  .el-form-item {
    margin-left: -60px;
  }

  .card {
    margin-bottom: 60px;
  }

  .save {
    display: flex;
    justify-content: flex-end;
  }
</style>
