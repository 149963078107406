import { render, staticRenderFns } from "./wt-common-detail-header.vue?vue&type=template&id=58793990&"
import script from "./wt-common-detail-header.vue?vue&type=script&lang=js&"
export * from "./wt-common-detail-header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports