<template>
  <div class="page-container">
    <PageHeader title="机构分类管理"/>
    <el-card>
      <div class="content">
        <div class="col-left">
          <el-tree
              class="filter-tree"
              :data="data"
              :props="{ label: 'name', defaultProps }"
              :expand-on-click-node="false"
              :render-content="renderContent"
              :default-expand-all='true'
              @node-click="handleNodeClick"
              ref="tree"></el-tree>
        </div>
        <div class="col-right">
          <el-form ref="detailForm" :model="detailForm" label-width="150px">
            <div v-if="!nodeClickFlag">选择左侧分类后显示</div>
            <el-form-item label="位置" prop="" v-if="nodeClickFlag">
              <span>{{ parentNodeName }}</span>
            </el-form-item>
            <el-form-item v-if="nodeClickFlag && handleClickFlag" label="分类名称" prop="name" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.name" placeholder="请输入分类名称"></el-input>
            </el-form-item>
            <el-form-item label="分类名称" prop="name" v-if="nodeClickFlag && !handleClickFlag">
              <span>{{ nodeName }}</span>
            </el-form-item>
            <el-form-item label="" prop="" align="right" v-if="nodeClickFlag && handleClickFlag">
              <el-button type="primary" size="mini" @click="sureAdd">确认</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import PageHeader from '../wisdom/components/PageHeader'

export default {
  name: 'orgClassify',
  components: {
    PageHeader
  },
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      requestListUrl: '/modules/organization/tree',
      requestAddUrl: '/modules/organization/add',
      requestUpdateUrl: '/modules/organization/update',
      requestDeleteUrl: '/modules/organization/delete',
      detailForm: {
        name: ''
      },
      dataForm: {},
      handleClickFlag: false,
      nodeClickFlag: false,
      nodeName: '机构分类',
      breadList: [],
      parentNodeName: '机构分类',
      editType: ''
    }
  },
  created() {
  },
  methods: {
    // 确认添加
    sureAdd() {
      if (this.editType === 'edit' && this.detailForm.name !== '') {
        this.orgTypeUpdate(this.detailForm)
      } else if (this.editType === 'add' && this.detailForm.name !== '') {
        this.dataForm.name = this.detailForm.name
        this.orgTypeAdd(this.dataForm)
      }
    },
    handleNodeClick(data, node, tree) {
      this.nodeClickFlag = true
      this.handleClickFlag = false
      this.nodeName = data.name
      // 位置数据处理
      this.siteSet(node)
    },
    // 递归获取父级节点
    platform(node) {
      if (!node.parent) { // 若无父节点，则直接返回
        return
      }
      this.breadList.unshift(node.data.name)// 将value保存起来
      // 调用递归
      this.platform(node.parent)
    },
    // 获取分类树
    getTypeList() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: that.requestListUrl,
        method: 'post',
        data: that.detailForm
      }).then(async resp => {
        const data = [
          {
            id: 0,
            level: 0,
            name: '机构分类',
            parentId: 0,
            children: []
          }
        ]
        data[0].children = resp.data
        that.data = data
        console.log(that.data)
        that.isButtonLoading = false
        that.isFromLoading = false
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    // 点击添加
    append(node, data) {
      event.stopPropagation()
      if (!this.nodeClickFlag) {
        this.nodeClickFlag = true
      }
      this.handleClickFlag = true
      this.editType = 'add'
      this.detailForm.name = ''
      this.dataForm = {
        parentId: data.isFirst ? 0 : data.id,
        name: '',
        level: data.level + 1
      }
      // 位置数据处理
      this.siteSet(node)
    },
    // 点击删除
    remove(node, data) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.orgTypeDelete({ list: [data.id] })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    renderContent(h, { node, data, store }) {
      // eslint-disable-next-line no-unused-vars
      let add = null
      let addEditDelete = null
      let addEditDeleteWithType = null
      let editDelete = null
      add = (
        <span class='custom-tree-node'>
          <span style='display: inline-block; min-width: 200px;'>
            {node.label}
          </span>
          <span>
            <i v-has-any-premission="['ORGCLASSIFY:ADD']" class='el-icon-circle-plus-outline' on-click={() => this.append(node, data)}></i>
          </span>
        </span>
      )
      addEditDelete = (
        <span class='custom-tree-node'>
          <span style='display: inline-block; min-width: 182px;'>
            {node.label}
          </span>
          <span>
            <i class='el-icon-edit' v-has-any-premission="['ORGCLASSIFY:EDIT']" on-click={() => this.edit(node, data)} style='margin-right:8px;'></i>
            <i v-has-any-premission="['ORGCLASSIFY:DELETE']" class='el-icon-remove-outline' on-click={() => this.remove(node, data)} style='margin-right:8px;'></i>
            <i v-has-any-premission="['ORGCLASSIFY:ADD']" class='el-icon-circle-plus-outline' on-click={() => this.append(node, data)}></i>
          </span>
        </span>
      )
      addEditDeleteWithType = (
        <span class='custom-tree-node'>
          <span style='display: inline-block; min-width: 182px;'>
            {node.label}
          </span>
          <span>
            <i v-has-any-premission="['ORGCLASSIFY:EDIT']" class='el-icon-edit' on-click={() => this.edit(node, data)} style='margin-right:8px;'></i>
            <i v-has-any-premission="['ORGCLASSIFY:DELETE']" class='el-icon-remove-outline' on-click={() => this.remove(node, data)} style='margin-right:8px;'></i>
          </span>
        </span>
      )
      editDelete = (
        <span class='custom-tree-node'>
          <span style='display: inline-block; min-width: 164px;'>
            {node.label}
          </span>
          <span>
            <i v-has-any-premission="['ORGCLASSIFY:EDIT']" class='el-icon-edit' on-click={() => this.edit(node, data)} style='margin-right:8px;'></i>
            <i v-has-any-premission="['ORGCLASSIFY:DELETE']" class='el-icon-remove-outline' on-click={() => this.remove(node, data)}></i>
          </span>
        </span>
      )
      console.log(data.level)
      if (data.level === 0) {
        return add
      }
      if (data.level === 1) {
        return addEditDelete
      }
      if (data.level === 2) {
        if (this.detailForm.type) {
          return addEditDeleteWithType
        } else {
          return addEditDelete
        }
      }
      if (data.level === 3) {
        return editDelete
      }
    },
    // 点击编辑
    edit(node, data) {
      event.stopPropagation()
      if (!this.nodeClickFlag) {
        this.nodeClickFlag = true
      }
      this.handleClickFlag = true
      this.editType = 'edit'
      this.detailForm = {}
      this.detailForm = {
        id: data.id,
        name: data.name,
        level: data.level,
        parentId: data.parentId
      }
      // 位置数据处理
      this.siteSet(node)
    },
    // 位置数据处理
    siteSet(node) {
      this.breadList = []
      this.parentNodeName = ''
      this.platform(node)
      if (this.breadList.length > 1) {
        this.breadList = this.breadList.slice(0, -1)
        for (let i = 0; i < this.breadList.length; i++) {
          if (i < this.breadList.length - 1) {
            this.parentNodeName += this.breadList[i] + ' > '
          } else {
            this.parentNodeName += this.breadList[i]
          }
        }
      } else {
        this.parentNodeName = '机构分类'
      }
    },
    // 新增分类
    orgTypeAdd(form) {
      this.isButtonLoading = true
      this.isFromLoading = true
      this.$wtRequest({
        url: this.requestAddUrl,
        method: 'post',
        data: {
          ...form,
          type: this.detailForm.type && this.detailForm.type
        }
      }).then(resp => {
        this.getTypeList()
        this.nodeClickFlag = false
        this.isButtonLoading = false
        this.isFromLoading = false
      }).catch(() => {
        this.isButtonLoading = false
        this.isFromLoading = false
      })
    },
    // 编辑分类
    orgTypeUpdate(form) {
      this.isButtonLoading = true
      this.isFromLoading = true
      this.$wtRequest({
        url: this.requestUpdateUrl,
        method: 'post',
        data: {
          ...form,
          type: this.detailForm.type && this.detailForm.type
        }
      })
        .then(resp => {
          this.getTypeList()
          this.nodeClickFlag = false
          this.isButtonLoading = false
          this.isFromLoading = false
        })
        .catch(() => {
          this.isButtonLoading = false
          this.isFromLoading = false
        })
    },
    // 删除分类
    orgTypeDelete(form) {
      this.isButtonLoading = true
      this.isFromLoading = true
      this.$wtRequest({
        url: this.requestDeleteUrl,
        method: 'post',
        data: {
          ...form
        }
      })
        .then(resp => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getTypeList()
          this.nodeClickFlag = false
          this.isButtonLoading = false
          this.isFromLoading = false
        })
        .catch(() => {
          this.isButtonLoading = false
          this.isFromLoading = false
        })
    }
  },
  computed: {
    ...mapState({})
  },
  mounted() {
    this.getTypeList()
  }
}
</script>
<style scoped lang="scss">
.content {
  min-height: 400px;
  padding: 20px;
  display: flex;
  .col-left {
    width: 60%;
    height: 100vh;
  }
  .col-right {
    width: 40%;
    border-left: 1px solid #ddd;
    padding: 10px 30px 10px 10px;
  }
}
.el-form-item {
  margin-left: -60px;
}

.card {
  margin-bottom: 60px;
}
</style>
