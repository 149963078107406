<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="订单编号" v-model="crud.formData.outTradeNo" type="input" />
        <wt-crud-form-item title="下单人" v-model="crud.formData.userName" type="input" />
        <wt-crud-form-item title="手机号" v-model="crud.formData.userPhone" type="input" />
        <wt-crud-form-item title="课程名称" v-model="crud.formData.courseName" type="input" />
        <wt-crud-form-item title="订单状态" v-model="crud.formData.payStatus" type="select" :dataList="$wtConst.PAY_STATUS" />
        <wt-crud-form-item title="课程分类" v-model="crud.formData.coursePlayClass" type="select" :dataList="$wtConst.COURSE_PLAY_CLASS" />
        <wt-crud-form-item title="课程标签" v-model="crud.formData.courseLableId" type="select" :dataList="labelList" />
        <wt-crud-form-item title="支付时间" v-model="crud.formData.payTimeRange" type="daterange" />
        <wt-crud-form-item title="下单时间" v-model="crud.formData.createTimeRange" type="daterange" />
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="outTradeNo" label="订单编号"></el-table-column>
        <el-table-column prop="userName" label="下单人"></el-table-column>
        <el-table-column prop="userPhone" label="手机号"></el-table-column>
        <el-table-column prop="payStatus" label="订单状态">
          <template v-slot="scope">
            <span>{{ scope.row.payStatus | FILTER_PayStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间"></el-table-column>
        <el-table-column prop="payTime" label="支付时间"></el-table-column>
        <el-table-column prop="couponPriceDollar" label="优惠金额"></el-table-column>
        <el-table-column prop="priceDollar" label="实付金额"></el-table-column>
        <el-table-column prop="courseName" label="课程名称"></el-table-column>
        <el-table-column prop="courseLableName" label="课程标签"></el-table-column>
        <el-table-column prop="coursePlayClass" label="课程分类">
          <template v-slot="scope">
            <span>{{ scope.row.coursePlayClass | FILTER_CoursePlayClass }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['COURSEORDERMANAGE:VIEW']" @click="crud.onClickView('courseOrderView', scope.row)">查看</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <course-order-view
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></course-order-view>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'courseOrderManage',
  data() {
    return {
      labelList: []
    }
  },
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/order/courseList',
      requestDeleteUrl: '/modules/order/courseDelete',
      formData: {
        courseLableId: '', // 课程标签ID ,
        courseName: '', // 课程名称 ,
        coursePlayClass: '', // 分类 1直播 2录播 ,
        createTimeRange: '', // 添加时间 ,
        outTradeNo: '', // 订单编号 ,
        payStatus: '', // 交易状态（0 待支付 1支付成功 2取消） ,
        payTimeRange: [], // 支付时间 ,
        reportName: '', // 报告名称 ,
        title: '', // 标题 ,
        userName: '', // 下单人 ,
        userPhone: '' // 手机号
      }
    })
    return {
      crud
    }
  },
  mounted() {
    this.crud.requestDataList()
    this.listByType2()
  },
  created() {
  },
  methods: {
    // 获取课程标签的数据字典list   type = 2
    listByType2() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: { id: 2 }
      }).then((resp) => {
        that.labelList = resp.data.map(item => {
          return { value: item.id, label: item.name }
        })
      }).catch(() => {
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
