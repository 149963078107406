<template>
  <div class="page-container">
    <div class="module-container">
      <div style="padding: 32px;">
        <div style="width: 300px;">
          <el-tree :data="moduleTreeData" show-checkbox></el-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
export default {
  name: 'moduleList2',
  data() {
    return {
      staticRoutes: staticRoutes,
      form: {
      }
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
  },
  computed: {
    moduleTreeData() {
      const treeData = []
      const makeChildrenFunction = (staticRoute) => {
        const children = []
        if (!staticRoute.hidden) {
          if (staticRoute.children && staticRoute.children.length > 0) {
            staticRoute.children.forEach((child) => {
              children.push({
                label: child.meta ? child.meta.title : '',
                children: makeChildrenFunction(child)
              })
            })
          }
        }
        return children
      }
      this.staticRoutes.forEach(staticRoute => {
        if (!staticRoute.hidden) {
          treeData.push({
            label: staticRoute.meta ? staticRoute.meta.title : '',
            children: makeChildrenFunction(staticRoute)
          })
        }
      })
      return treeData
    },
    ...mapState({
    })
  }
}
</script>
<style scoped>
  .module-container{background-color: white;width: calc(100% - 40px);margin: 20px 20px 16px;border-radius: 4px;}
</style>
