<template>
  <div class="detail-main" style="width:100%">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'wt-common-detail-footer'
}
</script>
