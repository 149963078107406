<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType === 'add'" v-has-any-premission="['MANAGERLIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['MANAGERLIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item label="头像：" prop="headPhoto">
            <wt-oss-upload :fileList.sync="fileList" public list-type="picture" file-size="150px*150px" file-path="account/" :limit="1"></wt-oss-upload>
<!--            <wt-image-upload v-model="detailForm.headPhoto" public :limit="1"></wt-image-upload>-->
          </el-form-item>
          <el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.username" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' },
          { validator: this.$wtValidator.ValidatorWtPhone, trigger: 'blur' }]">
            <el-input v-model="detailForm.mobile" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="密码" prop="newPassword" :rules="viewType === 'edit'?[]:[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-tooltip class="item" effect="light" :content="passwordText" placement="top">
              <el-input v-model="detailForm.newPassword" type="password" placeholder="请输入" show-password></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword" :rules="viewType === 'edit'?[]:[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.confirmPassword" type="password" placeholder="请输入" show-password></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleList" :rules="[{ required: true, message: '选择内容不能为空', trigger: 'change' }]">
            <el-checkbox-group v-model="detailForm.roleList">
              <el-checkbox v-for="item in roleListFilter" :key="item.id" :label="item.id"
                name="roleListFilter">{{item.roleName}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="设置启用：" prop="isPublish">
            <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0"
            change="" active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
export default {
  name: 'managerEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      roleList: [],
      roleListFilter: [],
      organizationList: [],
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        isPublish: 1,
        roleList: []
      },
      codeText: '获取验证码',
      passwordText: '密码长度8位以上，由数字、字母(字母区分大小写）、特殊字符组成（特殊字符包括!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~）',
      fileList: [] // 文件列表
    }
  },
  async mounted() {
    await this.requestManagerRoleList()
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    onClickAdd() {
      if (!this.passwordCheck()) return
      const that = this
      if (that.fileList && that.fileList.length > 0) {
        that.detailForm.headPhoto = this.fileList[0].name
      }
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      if (that.fileList && that.fileList.length > 0) {
        that.detailForm.headPhoto = this.fileList[0].name
      }
      if (that.detailForm.newPassword && that.detailForm.newPassword.length > 0) {
        if (!that.passwordCheck) return
      }
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    // 密码校验
    passwordCheck() {
      const reg = new RegExp('^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[\\W_])[a-zA-Z\\d\\W_]{8,}$', '')
      if (this.detailForm.newPassword !== '') {
        if (!reg.test(this.detailForm.newPassword)) {
          this.$message.error('密码长度8位以上，由数字、字母(字母区分大小写）、特殊字符组成（特殊字符包括!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~）')
          return false
        }
        if (this.detailForm.newPassword !== this.detailForm.confirmPassword) {
          this.$message.error('两次密码输入不一致')
          return false
        }
      }
      return true
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/auth/accountDetail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        // resp.data.organizationId ? resp.data.type = 2 : resp.data.type = 1
        // that.roleListFilter = that.roleList.filter(item => resp.data.type === item.type)
        that.roleListFilter = that.roleList
        that.detailForm = resp.data || {}
        if (that.detailForm.headPhoto !== '') {
          this.fileList.push(
            {
              name: that.detailForm.headPhoto,
              url: that.detailForm.headPhotoUrl,
              status: 'success'
            }
          )
        }
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    async requestManagerRoleList() {
      const that = this
      that.isFromLoading = true
      await that.$wtRequest({
        url: 'modules/auth/accountRoleList',
        method: 'post',
        data: {}
      }).then((resp) => {
        that.roleList = resp.data
        that.roleList = that.roleList.filter(item => item.isPublish === 1)
        that.roleListFilter = that.roleList
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/auth/accountUpdate',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/auth/accountAdd',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    getCode(phone) {
      if (!phone || phone === '') {
        this.$message({ type: 'error', message: '手机号不能为空' })
      } else {
        if (this.codeText !== '获取验证码' || !(/^1[3456789]\d{9}$/.test(phone))) return
        this.$wtRequest({
          url: 'modules/auth/updateUserSendCode',
          method: 'post',
          data: { phone: phone }
        }).then((resp) => {
          if (resp.success === true) {
            this.$message({ type: 'success', message: '发送成功' })
            let time = 60
            this.codeText = time + '秒后重试'
            const timer = setInterval(() => {
              if (time === 0) {
                clearInterval(timer)
                this.codeText = '获取验证码'
              } else {
                time--
                this.codeText = time + '秒后重试'
              }
            }, 1000)
          } else {
            this.$message({ type: 'error', message: '发送失败' })
          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
