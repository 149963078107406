<template>
  <div class="wt-search-form">
    <div class="wt-search-form-title"><span class="title">筛选查询</span></div>
    <div class="wt-search-form-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtSearchForm'
}
</script>

<style lang="scss" scoped>

</style>

