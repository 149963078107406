<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType === 'add'" v-has-any-premission="['MEMBERLIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['MEMBERLIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="订单信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="订单编号" prop="outTradeNo">
                <span>{{ detailForm.outTradeNo }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="userPhone">
                <span>{{ detailForm.userPhone }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="下单人" prop="userName">
                <span>{{ detailForm.userName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下单时间" prop="createTime">
                <span>{{ detailForm.createTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="支付时间" prop="payTime">
                <span>{{ detailForm.payTime || '--' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="优惠金额" prop="payTime">
                <span>{{ detailForm.couponPriceDollar || 0 }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="实付金额" prop="priceDollar">
                <span>{{ detailForm.priceDollar }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
      <wt-detail-section title="基本信息">
        <el-form :model="reportResult" ref="form" size="small" class="wt-detail-section-form-center" label-width="20%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="报告封面" prop="title">
                <el-image class="user-avatar" :src="reportResult.imgUrl"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="报告标题" prop="name">
                <span>{{ reportResult.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报告摘要" prop="summary">
                <span>{{ reportResult.summary }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="主题" prop="themeName">
                <span>{{ reportResult.themeName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="机构" prop="organizationName">
                <span>{{ reportResult.organizationName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="页数" prop="pageNumber">
                <span>{{ reportResult.pageNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="试看页数" prop="freeNumber">
                <span>{{ reportResult.freeNumber }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="作者" prop="author">
                <span>{{ reportResult.author }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="研报时间" prop="reportTime">
                <span>{{ reportResult.createTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="标签" prop="labelName">
                <span>{{ reportResult.labelName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报告价格" prop="price">
                <span>{{ Number(reportResult.price)/100 }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="关联热门词" prop="hotWordName">
                <span>{{ reportResult.hotWordName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下载次数" prop="downloadNumber">
                <span>{{ reportResult.downloadNumber }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="查看次数" prop="readNumber">
                <span>{{ reportResult.readNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="高级标签" prop="advancedLabel">
                <span>{{ reportResult.advancedLabel | FILTER_AdvancedType }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否动态" prop="isDynamic">
                <span>{{ reportResult.isDynamic | FILTER_YesOrNo }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
      <wt-detail-section title="报告附件">
        <el-row class="wt-padding-left-80">
          <el-col>
            <label>附件</label>
            <span class="wt-margin-left-50 wt-margin-right-50">{{ reportResult.fileName }}</span>
            <el-button type="text" @click="downloadFile">下载</el-button>
          </el-col>
        </el-row>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'reportOrderEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {
      },
      reportResult: {},
      labelList: [],
      hotList: [],
      fileUrl: '' // 附件下载url
    }
  },
  async mounted() {
    this.listByType1()
    this.listByType3()
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    // 获取研报标签的数据字典list   type = 1
    listByType1() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: {
          id: 1
        }
      }).then((resp) => {
        that.labelList = resp.data
      }).catch(() => {
      })
    },
    // 获取热搜词的数据字典list   type = 3
    listByType3() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: {
          id: 3
        }
      }).then((resp) => {
        that.hotList = resp.data
      }).catch(() => {
      })
    },
    // 下载附件
    downloadFile() {
      const that = this
      that.$wtRequest({
        url: 'modules/report/downloadUrl',
        method: 'post',
        data: {
          id: that.reportResult.id
        }
      }).then((resp) => {
        that.fileUrl = resp.data
        window.open(that.fileUrl)
      }).catch(() => {
      })
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      console.log(that.detailData)
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/order/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.reportResult = resp.data.reportResult || {}
        const labelNameMatch = that.labelList.find(item => item.id === that.reportResult.labelId)
        this.reportResult.labelName = labelNameMatch.name
        const hotNameMatch = that.labelList.find(item => item.id === that.reportResult.hotWordId)
        this.reportResult.hotWordName = hotNameMatch.name
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/user/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/user/add',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 50%;
}
.user-avatar {
  height: 80px;
}
</style>
