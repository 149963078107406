<template>
  <div class="wt-detail-section-item">{{label}}：{{value}}</div>
</template>

<script>
export default {
  name: 'WtDetailSectionItem',
  props: ['label', 'value'],
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
