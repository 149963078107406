<template>
  <div class="detail-header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'wt-common-detail-footer'
}
</script>
