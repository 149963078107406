import request from '@/utils/request'
import staticRoutes from '@/router/staticRoutes'
import {
  getToken,
  getPassword,
  setPassword,
  setToken,
  setStorgeItem,
  getStorgeItem,
  clearStorge,
  removeStorge,
  getStorge,
  setStorge
} from '@/utils/auth'

const auth = {
  state: {
    token: getToken(),
    userInfo: getStorgeItem('userInfo'),
    needPassword: getPassword('needPassword'),
    isDynamicRoutesInit: false,
    roleList: [],
    moduleItem: {},
    moduleList: [],
    roleCodeList: [],
    moduleCodeList: [],
    permissionCodeList: [],
    totalTreat: 0, // 诊疗未读
    totalTeam: 0 // 团队未读
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_PASSWORD: (state, password) => {
      state.password = password
      setPassword('needPassword', password)
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data
      setStorgeItem('userInfo', data)
    },
    SET_AUTHINFO: (state, data) => {
      state.roleCodeList = data.roleList
      state.moduleCodeList = data.moduleList
      state.permissionCodeList = data.permissionList
    },
    SET_DYNAMIC_ROUTES: (state, dynamicRoutes) => {
      state.dynamicRoutes = dynamicRoutes
      state.isDynamicRoutesInit = true
    },
    SET_ROLE_LIST: (state, roles) => {
      state.roleList = roles
    },
    SET_MODULE_ITEM: (state, moduleItem) => {
      state.moduleItem = moduleItem
    },
    SET_MODULE_LIST: (state, moduleList) => {
      state.moduleList = moduleList
      if (moduleList && moduleList.length > 0) {
        for (let i = 0; i < moduleList.length; i++) {
          if (!moduleList[i].hidden) {
            state.moduleItem = moduleList[i]
            break
          }
        }
      }
    },
    SET_TOTAL_TREAT: (state, totalTreat) => {
      state.totalTreat = totalTreat
    },
    SET_TOTAL_TEAM: (state, totalTeam) => {
      state.totalTeam = totalTeam
    }
  },

  actions: {
    // 登录
    requestLogin({ commit }, formData) {
      const username = formData.username.trim()
      const password = formData.password
      return new Promise((resolve, reject) => {
        request({
          url: '/modules/auth/login',
          method: 'post',
          data: {
            'username': username,
            'password': password
          }
        }).then(response => {
          setStorge('x-is-remember', formData.isRemember)
          if (formData.isRemember) {
            setStorge('username', formData.username)
            setStorge('password', formData.password)
          } else {
            removeStorge('username')
            removeStorge('password')
          }
          commit('SET_TOKEN', response.data.token)
          commit('SET_PASSWORD', response.data.isNeedSetPassword)
          commit('SET_USERINFO', response.data.accountResult)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    requestLogout({ commit, state }) {
      return new Promise((resolve, reject) => {
        request({
          url: 'modules/auth/logout',
          method: 'post'
        }).then(response => {
          commit('SET_TOKEN', '')
          commit('SET_ROLE_LIST', [])
          clearStorge()
          const isRemember = getStorge('x-is-remember')
          if (isRemember !== 'true') {
            removeStorge('username')
            removeStorge('password')
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        resolve()
      })
    },
    // 获取用户菜单权限
    requestDynamicRoutes({ commit, state }) {
      return new Promise((resolve, reject) => {
        request({
          url: 'modules/auth/authInfo',
          method: 'post',
          data: { }
        }).then(response => {
          // console.log('response', response)
          const resultDatas = []
          // const moduleResultMap = {}
          const resultRoutes = []
          // const makeChildrenFunction = (staticRoute) => {
          //   const children = []
          //   if (staticRoute.children && staticRoute.children.length > 0) {
          //     staticRoute.children.forEach((child) => {
          //       if (response.data.moduleSet && response.data.moduleSet.indexOf((child.name ? child.name : '').toUpperCase()) !== -1) {
          //         const item = Object.assign({}, child)
          //         item.children = makeChildrenFunction(child)
          //         children.push(item)
          //       } else {
          //         const item = Object.assign({}, child)
          //         item.children = makeChildrenFunction(child)
          //         children.push(item)
          //       }
          //     })
          //   }
          //   return children
          // }
          staticRoutes.forEach(staticRoute => {
            // const item = Object.assign({}, staticRoute)
            // item.children = makeChildrenFunction(staticRoute)
            // resultRoutes.push(item)
            resultRoutes.push(staticRoute)
          })
          commit('SET_MODULE_LIST', resultRoutes)
          commit('SET_DYNAMIC_ROUTES', resultDatas)
          commit('SET_AUTHINFO', response.data)
          resolve(resultRoutes)
        }).catch(error => {
          commit('SET_DYNAMIC_ROUTES', [])
          reject(error)
        })
      })
    },
    // 设置的默认模块
    updateModuleItem: ({ commit }, data) => {
      commit('SET_MODULE_ITEM', data)
    },
    // 设置诊疗未读
    setTotalTreat({ commit }, totalTreat) {
      commit('SET_TOTAL_TREAT', totalTreat)
      setStorgeItem('totalTreat', totalTreat)
    },
    // 设置团队未读
    setTotalTeam: ({ commit }, totalTeam) => {
      commit('SET_TOTAL_TEAM', totalTeam)
      setStorgeItem('totalTeam', totalTeam)
    }
  }
}

export default auth
