<template>
  <iframe class="monitor-panel" :src="url" frameborder="0" ></iframe>
</template>

<script>
export default {
  name: 'grafanaPanel',
  isAutoComponent: true,
  props: ['type', 'dashboardId', 'panelId'],
  data() {
    return {
      timeUri: '',
      url: ''
    }
  },
  mounted() {
    this.updateUI()
  },
  methods: {
    updateUI() {
      if (this.$wtUtil.isBlank(this.dashboardId) || this.$wtUtil.isBlank(this.panelId)) {
        return
      }
      if (this.type) {
        this.timeUri = '&from=now-' + this.type + '&to=now'
      }
      this.url = 'http://39.98.215.13:3000/d-solo/' + this.dashboardId + '/new-dashboard?orgId=1&refresh=1m&panelId=' + this.panelId + this.timeUri + '&theme=light&kiosk'
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .monitor-panel{width: 100%;height: 100%;}
</style>
