<template>
  <span v-if="imageUrl === ''">暂无图片</span>
  <el-image v-else fit="contain" :src="imageUrl" :preview-src-list="[imageUrl]" class="wt-table-column-image">
  </el-image>
</template>

<script>
export default {
  name: 'WtTableColumnImage',
  props: ['url'],
  computed: {
    imageUrl() {
      if (this.$wtUtil.isBlank(this.url)) {
        return ''
      }
      if (this.url.indexOf('http') === 0) {
        return this.url
      }
      return window.wtConst.FILE_SERVER_URL + this.url
    }
  }
}
</script>

<style scoped>
.wt-table-column-image{width: 100px; height: 100px}
</style>
