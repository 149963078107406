<template>
  <div class="detail-aside">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'wt-common-detail-footer',
  props: {
  }
}
</script>
