<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="订单信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="20%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="订单编号" prop="outTradeNo">
                <span>{{ detailForm.outTradeNo }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="userPhone">
                <span>{{ detailForm.userPhone }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="下单人" prop="userName">
                <span>{{ detailForm.userName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下单时间" prop="createTime">
                <span>{{ detailForm.createTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="优惠金额" prop="priceDollar">
                <span>{{ detailForm.couponPriceDollar }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实付金额" prop="priceDollar">
                <span>{{ detailForm.priceDollar }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="支付时间" prop="payTime">
                <span>{{ detailForm.payTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
      <wt-detail-section title="课程信息">
        <el-form :model="courseResult" ref="form" size="small" class="wt-detail-section-form-center" label-width="20%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程封面" prop="title">
                <el-image class="user-avatar" :src="courseResult.imgUrl"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程标题" prop="name">
                <span>{{ courseResult.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程标签" prop="summary">
                <span>{{ courseResult.labelName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程时长" prop="themeName">
                <span>{{ courseResult.totalDurationFormat || '00:00:00' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程讲师" prop="organizationName">
                <span>{{ courseResult.teacher }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="排序" prop="pageNumber">
                <span>{{ courseResult.orderNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程分类" prop="freeNumber">
                <span>{{ courseResult.playClass | FILTER_CoursePlayClass }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程价格" prop="author">
                <span>{{ courseResult.priceDollar }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否推荐" prop="reportTime">
                <span>{{ courseResult.isRecommend | FILTER_YesOrNo }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="创建时间" prop="price">
                <span>{{courseResult.createTime}}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
export default {
  name: 'courseOrderView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {},
      courseResult: {}
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    requestDetail() {
      const that = this
      console.log(that.detailData)
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/order/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.courseResult = resp.data.courseResult || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 50%;
}
.user-avatar {
  height: 80px;
}
</style>
