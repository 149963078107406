<template>
  <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form" label-width="150px">
    <slot></slot>
  </el-form>
</template>

<script>
export default {
  name: 'WtDetailForm',
  props: ['detailForm']
}
</script>

<style scoped>

</style>
