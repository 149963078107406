<template>
  <div class="wt-detail-header">
    <div class="wt-detail-header-title">
      <slot name="title">
        {{title}}
      </slot>
    </div>
    <div class="wt-detail-header-buttons">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtDetailHeader',
  props: ['title'],
  data() {
    return {
    }
  },
  created() {
  }
}
</script>
