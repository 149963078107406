/*
  全局过期器定义
  注意：1. 使用字符类型表示key值；2. 使用'default'表示默认值；3.当没有定义'default'值时，将返回空字符串
 */

const filters = {
  FILTER_IsPublish: {
    '0': '未启用',
    '1': '启用',
    default: '-'
  },
  FILTER_IsPublishjy: {
    '0': '禁用',
    '1': '正常',
    default: '-'
  },
  FILTER_RoleType: {
    '1': '平台',
    '2': '机构',
    default: '-'
  },
  FILTER_IsPublish_Self: {
    '0': '已下架',
    '1': '已上架',
    default: '-'
  },
  FILTER_IsEnable: {
    '0': '正常',
    '1': '冻结',
    '2': '注销',
    default: '-'
  },
  FILTER_IsPublish_Class: {
    '0': 'wt-tag',
    '1': 'wt-tag success',
    default: 'wt-tag'
  },
  FILTER_YesOrNo: {
    '0': '否',
    '1': '是'
  },
  FILTER_Gender: {
    '1': '男',
    '2': '女'
  },
  FILTER_BindStatus: {
    '0': '未绑定',
    '1': '已绑定'
  },
  FILTER_Type: {
    '0': '服务器',
    '1': '数据库',
    '2': '中间件',
    '3': '应用'
  },
  FILTER_NodeType: {
    '10000': 'Linux',
    '10001': 'Windows Server',
    '11000': 'MySQL',
    '11001': 'Redis',
    '12000': 'Nginx',
    '12001': 'Tomcat',
    '13000': 'Java Web'
  },
  FILTER_AlertingRuleLog_State: {
    '0': '无异常',
    '1': '异常',
    '2': '未知'
  },
  FILTER_AlertingRuleLog_State_Class: {
    '0': 'span-success',
    '1': 'span-danger',
    '2': '',
    default: ''
  },
  FILTER_OpinionReplyStatus: {
    0: '待回复',
    1: '已回复',
    default: ''
  },
  FILTER_OpinionReplyStatusClass: {
    0: 'span-danger',
    1: 'span-success',
    default: ''
  },
  FILTER_AdvancedType: {
    1: '热门',
    2: '最新',
    3: '爆款',
    default: ''
  },
  FILTER_MemberType: {
    1: '初级会员',
    2: '高级会员',
    3: '优享会员',
    default: ''
  },
  FILTER_CardType: {
    1: '月卡',
    2: '季卡',
    3: '年卡',
    default: ''
  },
  FILTER_RightType: {
    1: '研报',
    2: '课程',
    default: ''
  },
  FILTER_DictType: {
    1: '研报标签',
    2: '课程标签',
    3: '热搜词',
    4: '文章标签',
    5: '动态标签',
    default: ''
  },
  FILTER_PayStatus: {
    0: '待支付',
    1: '支付成功',
    2: '取消',
    default: ''
  },
  FILTER_UseClass: {
    1: '课程',
    2: '研报',
    3: '会员充值',
    default: ''
  },
  FILTER_CouponType: {
    1: '满减券',
    2: '折扣券',
    default: ''
  },
  FILTER_CoursePayType: {
    1: '免费',
    2: '付费',
    default: ''
  },
  FILTER_CoursePlayClass: {
    1: '直播',
    2: '录播',
    default: ''
  },
  FILTER_CommentStatus: {
    0: '待审核',
    1: '审核通过',
    2: '审核驳回',
    default: ''
  }
}

export default filters
