<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="15%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="封面图" prop="imgUrl">
                <el-image class="user-avatar" lazy :src="detailData.imgUrl"></el-image>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="报告名称" prop="name">
                <span>{{ detailForm.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报告摘要" prop="summary">
                <span>{{ detailForm.summary }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="主题" prop="themeName">
                <span>{{ detailForm.themeName ? detailForm.themeName.replace(/,/g, '/'): '' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="机构" prop="organizationName">
                <span>{{ detailForm.organizationName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="页数" prop="pageNumber">
                <span>{{ detailForm.pageNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="试看页数" prop="freeNumber">
                <span>{{ detailForm.freeNumber }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="作者" prop="author">
                <span>{{ detailForm.author }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="研报时间" prop="reportTime">
                <span>{{ detailForm.reportTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="标签" prop="labelName">
                <span>{{ detailForm.labelName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报告价格" prop="price">
                <span>{{ detailForm.price }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="关联热门词" prop="hotWordName">
                <span>{{ detailForm.hotWordName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下载次数" prop="downloadNumber">
                <span>{{ detailForm.downloadNumber }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="查看次数" prop="readNumber">
                <span>{{ detailForm.readNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="高级标签" prop="advancedLabel">
                <span>{{ detailForm.advancedLabel | FILTER_AdvancedType }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否动态" prop="isDynamic">
                <span>{{ detailForm.isDynamic | FILTER_YesOrNo }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
      <wt-detail-section title="报告附件">
        <el-row class="wt-padding-left-80">
          <el-col>
            <label>附件</label>
            <span class="wt-margin-left-50 wt-margin-right-50">{{ detailForm.fileName }}</span>
            <el-button type="text" @click="downloadFile">下载</el-button>
          </el-col>
        </el-row>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
export default {
  name: 'reportView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {
      },
      fileUrl: '' // 附件url
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    // 下载附件
    downloadFile() {
      const that = this
      that.$wtRequest({
        url: 'modules/report/downloadUrl',
        method: 'post',
        data: {
          id: that.detailForm.id
        }
      }).then((resp) => {
        that.fileUrl = resp.data
        window.open(that.fileUrl)
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/report/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm.themeIdList = []
        resp.data.price = resp.data.price / 100
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 40%;
}
.user-avatar {
  height: 80px;
}
</style>
