<template>
  <div class="dashboard-container">
    <!--顶部页面-->
    <div class="dashboard-header">
      <div class="dashboard-welcome">
        <div class="logo"></div>
        <div class="texts">
          <div class="title">下午好，Admin</div>
          <div class="subtitle">欢迎使用车百智库后台管理系统</div>
        </div>
      </div>
      <div>
      </div>
    </div>

    <div class="dashboard-body">
      <!--运维数据概览-->
      <div class="dashboard-section">
        <div class="dashboard-row" style="height: 160px;">
          <div class="dashboard-content dashboard-col-4">
            <div class="header">
              <div class="title">服务器</div>
              <div class="more" @click="$router.push({name: 'monitorServer'})">查看更多</div>
            </div>
            <div class="content">
              <div class="main">{{dashboardData.serverTotalNum}} </div>
              <div class="subtext">
                <template v-if="dashboardData.serverErrorNum > 0">
                  异常<span>{{dashboardData.serverErrorNum}}</span>台
                </template>
                <template v-else>运行正常</template>
              </div>
            </div>
          </div>
          <div class="dashboard-content dashboard-col-4">
            <div class="header">
              <div class="title">中间件</div>
              <div class="more" @click="$router.push({name: 'monitorMiddleware'})">查看更多</div>
            </div>
            <div class="content">
              <div class="main">{{dashboardData.middlewareTotalNum}}</div>
              <div class="subtext">
                <template v-if="dashboardData.middlewareErrorNum > 0">
                  异常<span>{{dashboardData.middlewareErrorNum}}</span>个
                </template>
                <template v-else>运行正常</template>
              </div>
            </div>
          </div>
          <div class="dashboard-content dashboard-col-4">
            <div class="header">
              <div class="title">数据库</div>
              <div class="more" @click="$router.push({name: 'monitorDatabase'})">查看更多</div>
            </div>
            <div class="content">
              <div class="main">{{dashboardData.databaseTotalNum}}</div>
              <div class="subtext">
                <template v-if="dashboardData.databaseErrorNum > 0">
                  异常<span>{{dashboardData.databaseErrorNum}}</span>个
                </template>
                <template v-else>运行正常</template>
              </div>
            </div>
          </div>
          <div class="dashboard-content dashboard-col-4">
            <div class="header">
              <div class="title">应用</div>
              <div class="more" @click="$router.push({name: 'monitorApp'})">查看更多</div>
            </div>
            <div class="content">
              <div class="main">{{dashboardData.appTotalNum}}</div>
              <div class="subtext">
                <template v-if="dashboardData.appErrorNum > 0">
                  异常<span>{{dashboardData.appErrorNum}}</span>个
                </template>
                <template v-else>运行正常</template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--操作记录-->
      <div class="dashboard-section" style="height: 424px;">
        <div class="dashboard-row">
          <div class="dashboard-content dashboard-col-6">
            <div class="header">
              <div class="title">告警信息</div>
              <div class="more">查看更多</div>
            </div>
            <div class="chart-content">
              <div class="chart-body">
                <ops-line-chart :data="alarmDataList"></ops-line-chart>
              </div>
              <div class="chart-header">
                <el-button-group>
                  <el-button size="small" @click="onClickAlarmDataType('24hours')">最近24小时</el-button>
                  <el-button size="small" @click="onClickAlarmDataType('7days')">最近7天</el-button>
                </el-button-group>
              </div>
            </div>
          </div>
          <div class="dashboard-content dashboard-col-6">
            <div class="header">
              <div class="title">知识库</div>
              <div class="more">查看更多</div>
            </div>
            <div class="list-content">
              <div class="search-input">
                <el-input v-model="searchText" placeholder="请输入搜索内容" size="small"></el-input><el-button icon="el-icon-search" size="small" class="search-button">搜索</el-button>
              </div>
              <div class="search-list">
                <div class="search-list-item" v-for="(item, index) in wikiList" :key="index">
                  <div class="title">{{item.title}}</div>
                  <div class="content">{{item.content}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'dashboard',
  data() {
    return {
      treatmentImConversationList: [], // 诊疗过程咨询列表
      teamServiceConversationList: [], // 团队客服咨询列表
      conversationList: [], // 会话列表
      isFromLoading: false,
      dashboardData: {},
      alarmDataType: '最近24小时',
      alarmDataList: [],
      searchText: '',
      wikiList: [{ title: '获取不了网关mac地址', content: '从2.14到3.65（映射地址2.141）网络不通，但是从3端的其他机器到3.65网络OK。' },
        { title: 'telnet很慢/ssh很慢', content: 'because your PC doesn’t do are verse DNSlookup on your IP then …' },
        { title: 'crontab输出结果控制', content: 'cron中执行的程序有输出内容，输出内容会以邮件形式发给cron的用户，而sendmail没有启动所以就产生了/var/' },
        { title: '文件删了磁盘空间没释放', content: '可能某人直接用rm删除某个正在写的文件' },
        { title: 'shell脚本不执行', content: '某天研发某同事找我说帮他看看他写的shell脚本，死活不执行，报错。' },
        { title: 'find文件提升性能', content: '在tmp目录下有大量包含picture_*的临时文件，每天晚上2:30对一天前的文件进行清理。' }]
    }
  },
  watch: {
  },
  mounted() {
  },
  created() {
    this.requestAlarmDataList()
    // this.requestDetail()
  },
  methods: {
    onClickAlarmDataType(type) {
      if (this.alarmDataType !== type) {
        this.alarmDataType = type
        this.requestAlarmDataList()
      }
    },
    requestAlarmDataList() {
      const tempAlarmDataList = []
      if (this.alarmDataType === '7days') {
        const lastDayNum = 7
        for (let i = 1; i <= lastDayNum; i++) {
          tempAlarmDataList.push({ value: 0, name: this.$moment().add(lastDayNum * -1 + i, 'days').format('M月D日') })
        }
        this.alarmDataList = tempAlarmDataList
      } else {
        const lastNum = 24
        for (let i = 1; i <= lastNum; i++) {
          tempAlarmDataList.push({ value: 0, name: this.$moment().add(lastNum * -1 + i, 'hours').format('H时') })
        }
        this.alarmDataList = tempAlarmDataList
      }
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/dashboard/index',
        method: 'post',
        data: {}
      }).then((resp) => {
        that.dashboardData = resp.data
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      isSDKReady: state => state.user.isSDKReady
    })
  }
}
</script>
<style lang="scss" scoped>
  .dashboard-container{position: relative;height: 100%;}
  .dashboard-header{height: 80px;width: 100%;position: absolute;top: -2px;background-color: #FFFFFF;display: flex;justify-content: space-between;padding: 0 24px;}
  .dashboard-welcome{display: flex;}
  .dashboard-welcome > .logo{width: 60px; height: 60px;background: url("../../assets/logo.png");background-size: cover;}
  .dashboard-welcome > .texts{margin-left: 16px;}
  .dashboard-welcome > .texts .title{font-size: 20px;font-weight: bold;color: #333;margin-bottom: 8px;}
  .dashboard-welcome > .texts .subtitle{font-size: 14px;color: #999;}
  .dashboard-body{width: 100%;height: calc(100% - 80px);position: absolute;top: 80px;}
  .dashboard-section{margin-top: 16px;}
  .dashboard-section .dashboard-row{height: 100%;width: calc(100% - 48px);display: flex;margin: 0 24px;}
  .dashboard-row > .dashboard-content{background-color: #FFF;height: 100%;margin-left: 24px;display: flex;flex-direction: column;}
  .dashboard-row > .dashboard-content.dashboard-col-4{width: calc(calc(100% - 72px) / 4);}
  .dashboard-row > .dashboard-content.dashboard-col-6{width: calc(calc(100% - 24px) / 2);}
  .dashboard-row > .dashboard-content:first-child{margin-left: 0;}
  .dashboard-content > .header{height: 40px;border-bottom: 1px solid #F4F4F4;color: #333;display: flex;align-items: center;justify-content: space-between;padding-left: 16px;padding-right: 16px;flex-shrink: 0;}
  .dashboard-content > .header > .title{font-size: 14px;font-weight: bold;}
  .dashboard-content > .header > .more{font-size: 12px;color: #1890ff;cursor: pointer;}
  .dashboard-content > .content{display: flex;flex-direction: column;flex: 1;justify-content: center;align-items: center;}
  .dashboard-content > .content > .main{display: flex; font-size: 40px;font-weight: bold;color: #333; font-family: Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
  .dashboard-content > .content > .subtext{height: 30px;display: flex;align-items: center;color: #333;font-size: 14px;justify-content: center;}
  .dashboard-content > .content > .subtext > span {margin: 0 4px;}
  .dashboard-content > .content .subtext-err{color: #FF0000;}
  .dashboard-content > .chart-content{flex: 1;position: relative;}
  .dashboard-content > .chart-content > .chart-header{margin: 16px 16px 0;}
  .dashboard-content > .chart-content > .chart-body{height: calc(100% - 70px);width: 100%;position: absolute;top: 0;left: 0;margin: 54px 0 16px 0;}
  .dashboard-content > .list-content{flex: 1;display: flex;flex-direction: column;}
  .dashboard-content > .list-content > .search-input{width: calc(100% - 32px);margin: 12px 16px;display: flex;}
  .dashboard-content > .list-content > .search-input > .search-button{margin-left: 8px;}
  .dashboard-content > .list-content > .search-list{display: flex;flex-wrap: wrap;}
  .dashboard-content > .list-content > .search-list > .search-list-item{width: calc(calc(100% - 64px) / 3);height: 146px;margin-left: 16px;background-color: #F7F7F7;margin-bottom: 16px;display: flex;flex-direction: column;}
  .dashboard-content > .list-content > .search-list > .search-list-item > .title{color: #1890ff;font-size: 16px;margin: 12px 8px 0;flex-shrink: 0;cursor: pointer;}
  .dashboard-content > .list-content > .search-list > .search-list-item > .content{color: #666666;font-size: 14px;margin: 8px;flex: 1;cursor: pointer;}
</style>
