<template>
  <div>
    <el-upload v-if="!isReadMode" class="avatar-uploader"
               :accept="accept" :disabled="isUploading" :http-request="handleUpload"
               :on-progress="onFileUploadProgress" :on-error="onFileUploadError"
               action="string" :show-file-list="false" :before-upload="beforeUpload">
      <el-button size="small" type="primary" :loading="isUploading">点击上传</el-button>
    </el-upload>
    <div v-if="!isReadMode" class="el-upload__tip">{{tip}}</div>
    <div class="upload-file-list">
      <div v-for="(item, index) in fileList" :key="index" class="upload-file-item">
        <span class="title">{{item.fileName ? item.fileName : item.fileUrl}}</span>
        <div v-if="!isReadMode" class="upload-file-item-delete" @click="onDeleteItem(index)"><i class="el-icon-close"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter'
import SparkMD5 from 'spark-md5'
export default {
  name: 'WtFileUpload',
  mixins: [Emitter],
  props: {
    value: {
      default: null
    },
    type: {
      type: String,
      default: 'string'// 支持string（单独的url）、list（url的列表）、json（json的列表）、jsonstring(json的string)
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noToken: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: 20
    },
    tip: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '*'
    }
  },
  data() {
    return {
      isInited: false,
      isUploading: false,
      uploadUrl: '',
      fileList: [],
      dataForm: {},
      temporary: '',
      preSignUrl: '',
      signUrl: ''
    }
  },
  mounted() {
    if (this.value && !this.isInited) {
      if (typeof (this.value) === 'string') {
        const tem = JSON.parse(this.value)
        if (typeof (tem) === 'object' && tem instanceof Array) {
          tem.forEach((item) => {
            this.fileList.push(this.buildFileItem(item.name, item.fileName, item.url, item.md5, item.length))
          })
        } else {
          // console.log('3', this.value)
          this.fileList.push(this.buildFileItem(this.value, this.value, '', ''))
        }
      }
      //  else if (typeof (this.value) === 'object' && this.type === 'json' && this.value instanceof Array) {
      //   this.value.length === 0 ? this.fileList = [] : this.fileList = this.value
      //   this.updateValue()
      // } else if (typeof (this.value) === 'object' && this.type === 'json') {
      //   console.error('当wt-file-upload类型为json时，初始化v-model需要为数组，如：[{ name: \'\', url: \'\', md5: \'\', length: \'\' }]')
      // }
    }
    if (this.noToken) {
      this.preSignUrl = 'modules/resourceFile/getSignParam'
      this.signUrl = 'modules/resourceFile/getUrl'
      this.getKey()
    } else {
      this.preSignUrl = 'modules/resourceFile/getPreSignUrl'
      this.signUrl = 'modules/resourceFile/getSignUrl'
    }
  },
  watch: {
    value: {
      handler() {
        if (typeof (this.value) === 'string' && !this.isInited) {
          this.isInited = true
          this.fileList.push({ name: '', fileName: this.$wtUtil.buildImageUrl(this.value), fileUrl: this.value })
          this.updateValue()
        } else if (typeof (this.value) === 'object' && !this.isInited) {
          this.isInited = true
          this.value.length === 0 ? this.fileList = [] : this.fileList = this.value
          this.updateValue()
        }
        this.isInited = true
        this.dispatch('ElFormItem', 'el.form.change', this.value)
      },
      deep: true
    }
  },
  methods: {
    // 获取临时密钥
    getKey() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/resourceFile/getKey',
        method: 'post'
      }).then((resp) => {
        that.temporary = resp.data
        that.$bus.$emit('getNoLoginKey', that.temporary)
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    // 获取上传文件签名
    getPreSignUrl(suffix) {
      return new Promise(resolve => {
        const that = this
        that.$wtRequest({
          url: that.preSignUrl,
          method: 'post',
          data: {
            suffix,
            key: that.temporary
          }
        }).then((resp) => {
          that.dataForm = resp.data
          resolve()
        }).catch(() => {
          this.isUploading = false
          console.log('获取签名失败')
        })
      })
    },
    handleUpload(fileInfo) {
      const that = this
      const form = new FormData() // FormData 对象
      Object.keys(that.dataForm).forEach(key => {
        form.append(key, that.dataForm[key])
      })
      form.append('file', fileInfo.file)
      that.$wtUploadRequest({
        url: '',
        method: 'post',
        data: form
      }).then((resp) => {
        that.$wtRequest({
          url: that.signUrl,
          method: 'post',
          data: {
            fileName: that.dataForm.key,
            key: that.temporary
          }
        }).then((resps) => {
          that.fileList.push({ name: that.dataForm.key, fileName: fileInfo.file.name, fileUrl: resps.data })
          that.updateValue()
          that.isUploading = false
        }).catch(() => {
          this.isUploading = false
          console.log('图片回显失败')
        })
      }).catch(() => {
        this.$message.error('上传失败，请稍后重试')
      })
    },
    onFileUploadError() {
      this.isUploading = false
      this.$message({ type: 'error', message: '上传失败' })
    },
    onFileUploadProgress(event, file, fileList) {
    },
    async beforeUpload(file) {
      const that = this
      if (this.limit && this.fileList && this.fileList.length >= this.limit) {
        this.$message({ type: 'warning', message: `最多可以上传${this.limit}个文件` })
        return false
      }
      if (file.size / 1024 / 1024 > this.max) {
        this.$message({ type: 'warning', message: `上传大小不能超过${this.max}MB` })
        return false
      }
      const extName = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'pdf', 'wgt', 'apk']
      if (whiteList.indexOf(extName) === -1) { // wgt和apk临时添加
        this.$message({
          message: '上传文件只能是jpg/png/pdf格式!',
          type: 'warning'
        })
        return false
      }
      that.isUploading = true
      await this.getPreSignUrl(extName)
      return true
      // return that.checkFileMd5(file).then((md5File) => {
      //   return that.requestCheckFile([md5File])
      // })
    },
    onDeleteItem(index) {
      this.fileList.splice(index, 1)
      this.updateValue()
    },
    onDownloadItem(index) {
      window.open(this.$wtUtil.buildImageUrl(this.fileList[index].url), '_blank')
    },
    updateValue() {
      if (this.type === 'string') {
        if (this.fileList && this.fileList.length > 0) {
          this.$emit('input', this.fileList[0].fileUrl)
        } else {
          this.$emit('input', '')
        }
        return
      } else if (this.type === 'json') {
        if (this.fileList && this.fileList.length > 0) {
          this.$emit('input', this.fileList)
          this.$emit('input', this.fileList)
        } else {
          this.$emit('input', [])
        }
        return
      } else if (this.type === 'jsonstring') {
        if (this.fileList && this.fileList.length > 0) {
          this.$emit('input', JSON.stringify(this.fileList))
        } else {
          this.$emit('input', null)
        }
        return
      }
      this.$emit('input', this.fileList)
    },
    checkFileMd5(file) {
      const that = this
      return new Promise((resolve, reject) => {
        try {
          that.getFileMd5(file, (md5File, data) => {
            if (data.resultCode === 'SUCCESS') {
              file.md5 = data.fileMd5
              resolve(file)
            } else {
              reject(new Error('文件检查失败'))
            }
          })
        } catch (e) {
          reject(new Error(e))
        }
      })
    },
    requestCheckFile(checkFileList) {
      const that = this
      const singleMd5 = checkFileList[0].md5
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: 'modules/resourceFile/checkFile',
          method: 'post',
          data: { fileMd5: [singleMd5] }
        }).then((resp) => {
          if (resp.code === '0' && resp.data && resp.data[singleMd5]) {
            const fileResult = resp.data[singleMd5]
            that.fileList.push({ name: fileResult.name, url: that.$wtUtil.buildImageUrl(fileResult.url), fileUrl: fileResult.url })
            that.updateValue()
            that.isUploading = false
            return reject(new Error(''))
          }
          return resolve()
        }).catch((e) => {
          that.isUploading = false
          return reject(new Error(e))
        })
      })
    },
    requestFileUpload(arg) {
      // console.log('requestFileUpload', arg)
    },
    getFileMd5(file, callback) {
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunkSize = 1024 * 1024 * 512
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          const fileMd5 = spark.end()
          callback(file, { resultCode: 'SUCCESS', fileMd5: fileMd5 })
        }
      }

      fileReader.onerror = function(err) {
        callback(file, { resultCode: 'FAIL', msg: err })
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    },
    buildFileItem(name, fileName, url, md5, length) {
      return { name: name, fileName: fileName, url: this.$wtUtil.buildImageUrl(url), fileUrl: url, md5: md5, length: length }
    }
  },
  computed: {
    isReadMode() {
      return (this.readonly === '') || this.readonly
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-file-list{display: flex;flex-wrap: wrap;}
  .upload-file-item{
    position: relative;width: 100%;margin-right: 16px;min-height: 24px;line-height: 24px; display: flex;justify-content: space-between;
    > .title {
      margin-left: 8px; margin-right: 32px; cursor: pointer;
    }
  }
  .upload-file-item:hover{
    background-color: #F4F4F4;
  }
  .upload-file-item-delete{
    position: absolute;right: 10px;top: 0;cursor: pointer;
    :hover,:active{opacity: 0.75;}
  }
  .upload-file-item-img{width: 80px; height: 80px;margin: 10px;}
  .upload-file-item-ext{
    width: calc(100% - 110px);display: flex;flex-direction: column;justify-content: center;margin-right: 10px;
    > span{line-height: 1;}
  }
</style>
