<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="订单编号" v-model="crud.formData.outTradeNo" type="input" />
        <wt-crud-form-item title="下单人" v-model="crud.formData.userName" type="input" />
        <wt-crud-form-item title="手机号" v-model="crud.formData.userPhone" type="input" />
        <wt-crud-form-item title="会员类型" v-model="crud.formData.memberLevel" type="select" :dataList="$wtConst.MEMBER_TYPE_OPTIONS" />
        <wt-crud-form-item title="下单时间" v-model="crud.formData.createTimeRange" type="daterange" />
        <wt-crud-form-item title="支付时间" v-model="crud.formData.payTimeRange" type="daterange" />
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="outTradeNo" label="订单编号"></el-table-column>
        <el-table-column prop="userName" label="下单人"></el-table-column>
        <el-table-column prop="userPhone" label="手机号"></el-table-column>
        <el-table-column prop="payStatus" label="订单状态">
          <template v-slot="scope">
            <span>{{ scope.row.payStatus | FILTER_PayStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间"></el-table-column>
        <el-table-column prop="payTime" label="支付时间"></el-table-column>
        <el-table-column prop="memberLevel" label="会员类型">
          <template v-slot="scope">
            <span>{{ scope.row.memberLevel | FILTER_MemberType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="memberScope" label="会员卡类型">
          <template v-slot="scope">
            <span>{{ scope.row.memberScope | FILTER_CardType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="couponPriceDollar" label="优惠金额"></el-table-column>
        <el-table-column prop="priceDollar" label="实付金额"></el-table-column>

        <el-table-column label="操作" fixed="right" width="100">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['MEMBERORDERMANAGE:VIEW']" @click="crud.onClickView('memberOrderView', scope.row)">查看</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <member-order-view
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></member-order-view>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'memberOrderManage',
  data() {
    return {
    }
  },
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/userMemberOrder/list',
      requestDeleteUrl: '/modules/order/delete',
      formData: {
        createTimeRange: [], // 添加时间 ,
        memberLevel: '', // 会员类型 ,
        outTradeNo: '', // 订单编号 ,
        payTimeRange: [], // 支付时间时间 ,
        userName: '', // 用户名称 ,
        userPhone: '' // 用户手机号
      }
    })
    return {
      crud
    }
  },
  mounted() {
    this.crud.requestDataList()
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
