<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="姓名" v-model="crud.formData.name" type="input" />
        <wt-crud-form-item title="手机号" v-model="crud.formData.phone" type="input" />
        <wt-crud-form-item title="会员类型" v-model="crud.formData.memberLevel" type="select" :dataList="$wtConst.MEMBER_TYPE_OPTIONS" />
        <wt-crud-form-item title="状态" v-model="crud.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS" />
        <wt-crud-form-item title="会员期限" v-model="crud.formData.memberEndTimeRange" type="datetimerange" />
        <wt-crud-form-item title="注册时间" v-model="crud.formData.createTimeRange" type="datetimerange" />
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="昵称"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="wechatOpenid" label="微信ID"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>
        <el-table-column prop="companyName" label="公司"></el-table-column>
        <el-table-column prop="job" label="职务"></el-table-column>
        <el-table-column prop="memberLevel" label="会员类型">
          <template v-slot="scope">
            <span>{{ scope.row.memberLevel | FILTER_MemberType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="memberEndTime" label="会员期限"></el-table-column>
        <el-table-column prop="createTime" label="注册时间"></el-table-column>
        <el-table-column prop="isPublish" label="发布状态" width="120">
          <template v-slot="scope">
            <wt-crud-publish-switch :id="scope.row.id" :value="scope.row.isPublish" :crud="crud" />
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['MEMBERLIST:VIEW']" @click="crud.onClickView('memberEdit', scope.row)">查看</wt-text-button>
<!--            <wt-text-button @click="crud.onClickDelete(scope.row)" type="danger">删除</wt-text-button>-->
          </template>
        </el-table-column>
      </template>
      <template #view>
        <member-edit
            :detail-data="crud.rowData"
            :view-type="crud.action"
            @on-close="crud.close()"
            @on-refresh="crud.requestDataList()"
        ></member-edit>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'memberList',
  data() {
    return {
    }
  },
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/user/list',
      requestPublishUrl: '/modules/user/updatePublish',
      requestDeleteUrl: '/modules/user/delete',
      formData: {
        name: '',
        phone: '',
        memberLevel: '',
        createTimeRange: '',
        memberEndTimeRange: '',
        isPublish: ''
      }
    })
    return {
      crud
    }
  },
  mounted() {
    this.crud.requestDataList()
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
