<template>
  <div>
    <div style="display: flex; align-items: center;justify-content: space-between;">
      <div class="page-title">{{ title }}</div>
      <div>
        <el-button @click="backHistory" plain icon="el-icon-arrow-left" size="small">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: ['title'],
  methods: {
    backHistory() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.btn {

}

div {
  margin-bottom: 20px;
}

.page-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: black;
  padding: 15px;
}

</style>
