<template>
  <el-select v-model="dataValue" v-bind="$attrs" @change="onSelectChanged">
    <el-option label="请选择" value=""></el-option>
    <el-option v-for="item in dataList" :key="item.dictKey" :label="item.dictName" :value="item.id"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'WtDictSelect',
  props: ['dictKey', 'value'],
  data() {
    return {
      dataList: [],
      dataValue: ''
    }
  },
  watch: {
    value(val, oldVal) {
      this.dataValue = val
    },
    dictKey(val, oldVal) {
      if (this.$wtUtil.isNotBlank(val) && val !== oldVal) {
        this.requestDictList(val)
      }
    }
  },
  methods: {
    onSelectChanged(val) {
      if (this.$wtUtil.isBlank(val)) {
        this.$emit('on-dict-change', {})
        return
      }
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].id === val) {
          this.$emit('on-dict-change', this.dataList[i])
        }
      }
    },
    requestDictList(tempKey) {
      const that = this
      if (this.$wtUtil.isBlank(tempKey)) {
        that.dataList = []
        return
      }
      this.$store.dispatch('queryDictList', { dictKey: tempKey }).then((list) => {
        that.dataList = list
      })
    }
  },
  created() {
    this.requestDictList(this.dictKey)
  }
}
</script>

<style scoped>

</style>
