<template>
  <div class="app-side app-side-menu">
    <div class="app-side-scroll">
      <div class="app-logo">
        <span>{{appName}}</span>
        <!--<img :src="sidebar.opened ? logoImage : logoImage"/>-->
      </div>
      <el-menu ref="wtMenu" class="app-nav app-nav-tree" :collapse="!sidebar.opened" :default-active="defaultMenuIndex">
        <template v-if="!isAppTopTabEnable">
          <wt-side-menu-item  v-for="(item, index) in moduleList" :key="index" :itemIndex="item.name" :item="item"></wt-side-menu-item>
        </template>
        <template v-else>
          <wt-side-menu-item v-for="(item, index) in moduleItem.children" :key="index" :itemIndex="item.name" :item="item"></wt-side-menu-item>
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import logoImage from '@/assets/images/logo.png'
export default {
  name: 'WtSide',
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      isAppTopTabEnable: process.env.VUE_APP_TOP_TAB_ENABLE === 'TRUE'
      // defaultMenuIndex: sessionStorage.getItem('MENU_ITEM_INDEX') || ''
      // logoImage
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    }
  },
  computed: {
    ...mapState({
      moduleItem: state => state.auth.moduleItem,
      moduleList: state => state.auth.moduleList
    }),
    ...mapGetters([
      'sidebar'
    ]),
    defaultMenuIndex() {
      return this.$route.path.replace('/', '')
    }
  },
  mounted() {
    console.log(this.isAppTopTabEnable)
    console.log(this.moduleItem)
    console.log(this.moduleList)
  }
}
</script>
