<template>
  <div class="page-container">
<!--    <div id="videoPlayer" style="width: 500px; height: 300px; background-color: #f44336;"></div>-->
<!--    <wt-button @click="testPlayVideo" type="primary">播放</wt-button>-->
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="课程标题" v-model="crud.formData.name" type="input" />
        <wt-crud-form-item title="课程标签" v-model="crud.formData.labelId" type="select" :dataList="labelList" />
        <wt-crud-form-item title="发布状态" v-model="crud.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH__OPTIONS" />
        <wt-crud-form-item title="收费类型" v-model="crud.formData.payType" type="select" :dataList="$wtConst.COURSE_PAY_TYPE" />
        <wt-crud-form-item title="课程类型" v-model="crud.formData.playClass" type="select" :dataList="$wtConst.COURSE_PLAY_CLASS" />
        <wt-crud-form-item title="发布时间" v-model="crud.formData.publishTimeRange" type="datetimerange" />
      </template>
      <template slot="toolbar-left">
        <wt-button v-has-any-premission="['COURSEMANAGE:ADD']" type="primary" @click="crud.onClickAdd('courseAdd')">新增课程</wt-button>
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="title" label="课程封面">
          <template v-slot="scope">
            <el-image class="imageWidth" :src="scope.row.imgUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="课程标题"></el-table-column>
        <el-table-column prop="orderNumber" label="排序"></el-table-column>
        <el-table-column prop="labelName" label="课程标签"></el-table-column>
        <el-table-column label="课程分类">
          <template v-slot="scope">
            {{ scope.row.playClass | FILTER_CoursePlayClass }}
          </template>
        </el-table-column>
        <el-table-column label="收费类型">
          <template v-slot="scope">
            {{ Number(scope.row.price || 0) > 0 ? '付费' : '免费' }}
          </template>
        </el-table-column>
        <el-table-column prop="publishAccountName" label="发布人"></el-table-column>
        <el-table-column prop="isPublish" label="发布状态" width="120">
          <template v-slot="scope">
            {{ scope.row.isPublish === 1 ? '已发布' : '未发布' }}
          </template>
        </el-table-column>
        <el-table-column prop="publishTime" label="发布时间"></el-table-column>

        <el-table-column label="操作" fixed="right" width="280">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['COURSEMANAGE:VIEW']" @click="crud.onClickView('courseView', scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['COURSEMANAGE:EDIT']" @click="onClickPublish(scope.row)">{{ scope.row.isPublish === 0 ? '发布' : '取消发布' }}</wt-text-button>
            <wt-text-button v-has-any-premission="['COURSEMANAGE:EDIT']" @click="crud.onClickEdit('courseEdit', scope.row)">编辑</wt-text-button>
            <wt-text-button v-has-any-premission="['COURSEMANAGE:EDIT']" @click="onClickHome(scope.row)">{{ scope.row.isFirstPageShow === 0 ? '设为首页' : '取消首页' }}</wt-text-button>
            <wt-text-button v-has-any-premission="['COURSEMANAGE:DELETE']" @click="crud.onClickDelete(scope.row.id)" type="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <course-edit
          v-if="crud.view === 'courseAdd'"
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></course-edit>
        <course-edit
          v-if="crud.view === 'courseEdit'"
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></course-edit>
        <course-view
          v-if="crud.view === 'courseView'"
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></course-view>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'index',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/course/list',
      requestDeleteUrl: '/modules/course/delete',
      publishSuccessText: '操作成功',
      formData: {
        isPublish: '',
        labelId: '',
        name: '',
        payType: '',
        playClass: '',
        publishTimeRange: []
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
      labelList: [], // 标签
      themeList: [], // 主题tree
      orgList: [], // 机构tree
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'children'
      },
      optionPropsOrg: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        checkStrictly: true,
        emitPath: false
      }
    }
  },
  mounted() {
    this.listByType2()
  },
  created() {
  },
  methods: {
    testPlayVideo() {
      const that = this
      const videoId = 'd9655df0e4c671ed8f5d34b6fdcb0102'
      that.$wtRequest({
        url: '/modules/vod/videoPlayAuth',
        method: 'post',
        data: { videoId }
      }).then((resp) => {
        console.log('播放凭证', resp)
        // eslint-disable-next-line no-undef
        const player = new Aliplayer({
          id: 'videoPlayer',
          width: '100%',
          vid: videoId, // 必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
          playauth: resp.data.playAuth // 必选参数。音视频播放凭证。
        }, function(player) {
          console.log('The player is created.', player)
        })
        console.log('播放器实例', player)
      }).catch(() => {})
    },
    // 获取课程标签的数据字典list   type = 2
    listByType2() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: { id: 2 }
      }).then((resp) => {
        that.labelList = resp.data.map(item => {
          return { value: item.id, label: item.name }
        })
      }).catch(() => {
      })
    },
    onClickPublish(row) {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/course/updatePublish',
        method: 'post',
        data: { id: row.id }
      }).then((resp) => {
        that.isFromLoading = false
        that.$message({ type: 'success', message: '操作成功' })
        that.crud.requestDataList()
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    onClickHome(row) {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/course/updateFirstPageShow',
        method: 'post',
        data: { id: row.id }
      }).then((resp) => {
        that.isFromLoading = false
        that.$message({ type: 'success', message: '操作成功' })
        that.crud.requestDataList()
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-cascader {
  line-height: 1;
}
::v-deep .el-cascader .el-input__inner {
  height: 28px;
  line-height: 28px;
}
::v-deep .el-cascader .el-input__icon {
  line-height: 28px;
}
</style>
