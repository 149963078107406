<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="level" label="会员类型">
          <template v-slot="scope">
            <span>{{ scope.row.level | FILTER_MemberType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="scope" label="会员卡类型">
          <template v-slot="scope">
            <span>{{ scope.row.scope | FILTER_CardType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="priceDollar" label="会员价格"></el-table-column>
        <el-table-column prop="isPublish" label="状态" width="120">
          <template v-slot="scope">
            <wt-crud-publish-switch :id="scope.row.id" :value="scope.row.isPublish" :crud="crud" />
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间"></el-table-column>

        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['CARDTYPELIST:EDIT']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-crud>
    <!--编辑-->
    <div class="wt-dialog">
      <el-dialog title="会员卡类型管理" :visible.sync="isEditDialogVisible" width="40%" :close-on-press-escape="false"
                 :close-on-click-modal="false" :modal-append-to-body="false" lock-scroll>
        <el-form :model="dataForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="40%">
          <el-form-item label="" prop="content">
            <span>{{ dataForm.level | FILTER_MemberType }}/{{dataForm.scope | FILTER_CardType}}</span>
          </el-form-item>
          <el-form-item label="会员价格" prop="priceUnit" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="dataForm.priceUnit" placeholder="请输入" @input="inputLimit(dataForm.priceUnit)"></el-input>
          </el-form-item>
          <el-form-item label="是否启用：" prop="isPublish">
            <el-switch v-model="dataForm.isPublish" :active-value="1" :inactive-value="0"
                       active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
          </el-form-item>
        </el-form>
        <template slot="footer">
          <wt-button type="info"
                     @click="isEditDialogVisible = false">取消</wt-button>
          <wt-button type="primary"
                     @click="saveCard()">提交</wt-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'cardTypeList',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/memberLevel/list',
      requestPublishUrl: '/modules/memberLevel/updatePublish',
      requestDeleteUrl: '/modules/memberLevel/delete',
      formData: {
        name: '',
        createTimeRange: ''
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
      isEditDialogVisible: false,
      dataForm: {
      }
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    onClickEdit(row) {
      const that = this
      that.isEditDialogVisible = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/memberLevel/detail',
        method: 'post',
        data: {
          id: row.id
        }
      }).then((resp) => {
        that.isFromLoading = false
        resp.data.priceUnit = resp.data.price / 100
        that.dataForm = resp.data
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    // 提交
    saveCard() {
      const that = this
      that.isFromLoading = true
      that.dataForm.price = that.dataForm.priceUnit * 100
      that.$wtRequest({
        url: 'modules/memberLevel/update',
        method: 'post',
        data: that.dataForm
      }).then((resp) => {
        that.isFromLoading = false
        this.isEditDialogVisible = false
        that.$message({ type: 'success', message: '保存成功' })
        that.crud.requestDataList()
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    inputLimit(price) {
      this.dataForm.priceUnit = price.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
