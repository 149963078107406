<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="搜索" v-model="crud.formData.roleName" type="input" />
      </template>
      <template slot="toolbar-left">
        <wt-button type="primary" v-has-any-premission="['ROLELIST:ADD']" @click="crud.onClickAdd('')">新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['ROLELIST:DELETE']" @click="crud.onClickMultipleDelete()">删除</wt-button>
      </template>
      <template #table-column>
        <el-table-column type="selection" label="选择" :selectable="onSelectable"></el-table-column>
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column prop="createAccountName" label="创建人"></el-table-column>
        <el-table-column prop="isPublish" label="角色状态">
          <template slot-scope="scope">
            <span :class="scope.row.isPublish|FILTER_IsPublish_Class">{{scope.row.isPublish|FILTER_IsPublish}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['ROLELIST:VIEW']" @click="crud.onClickView('RoleEdit', scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['ROLELIST:EDIT']" @click="crud.onClickEdit('RoleEdit', scope.row)">编辑</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <RoleEdit :detailData="crud.rowData" :view-type="crud.action" @on-refresh="crud.requestDataList()" @on-close="crud.close()"/>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'dictList',
  setup() {
    const { crud } = useCrud({
      requestListUrl: 'modules/auth/roleList',
      requestDeleteUrl: 'modules/auth/roleDelete',
      formData: {
        dictName: '',
        dictKey: ''
      },
      staticFormData: {
        isPublish: 1
      }
    })
    return {
      crud
    }
  },
  methods: {
    onSelectable(row, index) {
      return row.id > 1
    }
  }
}
</script>
