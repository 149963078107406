<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="课程详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="15%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程封面" prop="imgUrl">
                <el-image class="user-avatar" lazy :src="detailData.imgUrl"></el-image>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程标题" prop="name">
                <span>{{ detailData.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程标签" prop="labelName">
                <span>{{ detailData.labelName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程时长" prop="totalDurationFormat">
                <span>{{ detailForm.totalDurationFormat }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程讲师" prop="teacher">
                <span>{{ detailData.teacher }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="排序" prop="orderNumber">
                <span>{{ detailData.orderNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程类型" prop="price">
                <span>{{ Number(detailData.price || 0) > 0 ? '付费' : '免费' }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程价格" prop="priceDollar">
                <span>{{ detailData.priceDollar }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否推荐" prop="isRecommend">
                <span>{{ detailData.isRecommend === 1 ? '是' : '否' }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程分类" prop="playClass">
                <span>{{ detailData.playClass | FILTER_CoursePlayClass }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间" prop="createTime">
                <span>{{ detailData.createTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程简介" prop="introduction" class="span24">
                <span>{{ detailData.introduction }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="师资简介" prop="teacherIntroduction" class="span24">
                <span>{{ detailData.teacherIntroduction }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程特色" prop="characteristic" class="span24">
                <span>{{ detailData.characteristic }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程价值" prop="worth" class="span24">
                <span>{{ detailData.worth }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
      <wt-detail-section title="章节信息">
        <wt-crud v-if="showList" :crud="crud" mode="slot" style="width: 100%; padding:0 16px 0 16px;">
          <template #table-column>
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="courseChapterName" label="章节名"></el-table-column>
            <el-table-column prop="fileName" label="节名称"></el-table-column>
            <el-table-column prop="fileDurationFormat" label="时长"></el-table-column>
            <el-table-column label="操作" fixed="right" width="200">
              <template v-slot="scope">
                <wt-text-button @click="downloadFile(scope.row)">下载</wt-text-button>
              </template>
            </el-table-column>
          </template>
        </wt-crud>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
import axios from 'axios'
export default {
  name: 'courseView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {
      },
      crud: {},
      showList: false
    }
  },
  async mounted() {
    const useCrudObj = useCrud({
      requestListUrl: '/modules/courseChapterFile/listByCourse',
      formData: {
        courseId: this.detailData.id
      }
    })
    this.crud = useCrudObj.crud
    setTimeout(() => {
      this.showList = true
    }, 500)
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    // 下载附件
    downloadFile(file) {
      const that = this
      that.$wtRequest({
        url: '/modules/vod/mezzanineInfo',
        method: 'post',
        data: { id: file.fileId }
      }).then((resp) => {
        const loading = this.$loading({
          lock: true,
          text: '下载中...',
          background: 'rgba(255, 255, 255, 0.7)'
        })
        that.downloadFileRequest({
          url: resp.data.mezzanine.fileURL,
          fileName: `${file.fileName}.mp4`
        }).then((res) => {
          loading.close()
        }).catch(() => {})
      }).catch(() => {
      })
    },
    downloadFileRequest({ url, fileName, data, method = 'post' }) {
      return new Promise((resolve, reject) => {
        const downloadRequest = axios.create({
          headers: { 'Content-Type': 'application/json', responseType: 'blob' },
          baseURL: '', // api的base_url
          timeout: 3000000 // 请求超时时间
        })
        downloadRequest({
          url: url,
          method: 'get',
          data: data,
          responseType: 'blob',
          contentType: 'application/json'
        }).then(response => {
          if (response.data.type === 'application/json') {
            reject(new Error('下载失败'))
            return
          }
          const blob = new Blob([response.data])
          if (!fileName) {
            fileName = Date.parse(new Date()) + ''
          }
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            window.URL.revokeObjectURL(link.href)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/course/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm.themeIdList = []
        resp.data.price = resp.data.price / 100
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 80%;
}
::v-deep .wt-detail-section-form-center .span24 .el-form-item__content {
  width: 180%;
}
.user-avatar {
  height: 80px;
}
</style>
