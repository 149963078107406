<template>
  <div class="detail-footer">
    <el-card class="footer-content wt-flex wt-row-right">
      <slot></slot>
    </el-card>
    <div class="footer-seat" :style="{height: footerHeight + 'px'}"></div>
  </div>
</template>

<script>
export default {
  name: 'wt-common-detail-footer',
  mounted() {
    this.getFooterHeight()
  },
  data() {
    return {
      footerHeight: 0
    }
  },
  methods: {
    getFooterHeight() {
      this.footerHeight = document.querySelectorAll('.footer-content')[0].getBoundingClientRect().height * 2
    }
  }
}
</script>

<style scoped lang="scss">
  .detail-footer {
    .footer-content {
      position: fixed;
      bottom: 20px;
      left: 270px;
      right: 20px;
      z-index: 9999999;
      background-color: #fff;
    }
  }

</style>
