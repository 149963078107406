<template>
  <el-select @change="selectChangeCb" v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" size="small" v-bind="$attrs">
    <el-option v-for="item in dataList" :key="item.id" :label="item.title" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'wtDictSelect',
  isAutoComponent: true,
  props: ['dictCode', 'dictId', 'placeholder', 'title', 'value'],
  data() {
    return {
      dictKey: '', // 字典集合名称
      dataValue: ''
    }
  },
  methods: {
    selectChangeCb() {
      this.$emit('input', this.dataValue)
    }
  },
  watch: {
    value(val) {
      if (val !== this.dataValue) {
        this.dataValue = val
      }
    }
  },
  created() {
    this.dataValue = this.value
    this.$store.dispatch('getDictionaryList', { dataType: this.dictCode })
  },
  computed: {
    ...mapGetters(['dictionary']),
    dataList() {
      const _dict = this.dictionary[this.dictCode]
      if (_dict === undefined) {
        return ''
      } else {
        return _dict
      }
    }
  }
}
</script>

<style scoped>

</style>
