import md5 from 'js-md5'
import request from '@/utils/request'

export const getBaseReqData = () => {
  const timestamp = Math.round(new Date().getTime() / 1000)
  const { enterpriseId, departmentToken } = window.wtConst
  return {
    validateType: 2,
    enterpriseId,
    timestamp,
    sign: md5(enterpriseId + '' + timestamp + departmentToken)
  }
}

// eslint-disable-next-line standard/object-curly-even-spacing
export const redirectCtiApi = ({ apiUrl, method = 'get', data = {}, params = {}}) => {
  return new Promise((resolve, reject) => {
    const { ctiCloudUrl } = window.wtConst
    const finalParams = {
      apiUrl: `${ctiCloudUrl}` + apiUrl,
      ...data,
      ...params
    }
    request({
      url: '/middleware/api/webCall/forward',
      method,
      data: finalParams,
      params: finalParams
    }).then(res => {
      console.log(res)
      resolve(res)
    })
  })
}
