<template>
  <div>
    <div class="card-box">
      <div class="search-box">
        <div>
          时间范围：
          <el-date-picker
            v-model="form.dateRange"
            type="daterange"
            size="mini"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <div>
          <wt-button type="primary" @click="initData">查询</wt-button>
          <wt-button @click="resetEvent">重置</wt-button>
        </div>
      </div>
    </div>
    <div class="card-box">
      <div class="card-title">订单金额统计</div>
      <div class="data-panel-card">
        <div v-for="(item, index) in orderPriceTotal" :key="index" class="card">
          <div :style="{ background: item.titleBgColor }">{{ item.title }}</div>
          <div :style="{ borderColor : item.titleBgColor }">￥<span style="padding: 0; font-size: 28px;">{{ item.price }}</span></div>
        </div>
      </div>
    </div>
    <div class="card-box">
      <div class="card-title">会员订单统计</div>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <div id="chart1" style="width: 30%; height: 300px;"></div>
        <div id="chart2" style="width: 30%; height: 300px;"></div>
        <div id="chart3" style="width: 30%; height: 300px;"></div>
      </div>
    </div>
    <div class="card-box">
      <div class="card-title">课程订单统计</div>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <div id="chart4" style="width: 48%; height: 360px;"></div>
        <div id="chart5" style="width: 48%; height: 360px;"></div>
      </div>
    </div>
    <div class="card-box">
      <div class="card-title">研报&动态订单统计</div>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <div id="chart6" style="width: 48%; height: 360px;"></div>
        <div id="chart7" style="width: 48%; height: 360px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ECharts from 'echarts'
export default {
  name: 'orderStatistics',
  data() {
    return {
      form: {
        dateRange: []
      },
      orderPriceTotal: [
        { title: '订单总金额', price: '0', titleBgColor: '#FEEFAC' },
        { title: '会员充值订单金额（0%）', price: '0', titleBgColor: '#FFBBBB' },
        { title: '课程购买金额（0%）', price: '0', titleBgColor: '#B0EEFF' },
        { title: '研报&动态购买金额（0%）', price: '0', titleBgColor: '#86E6CC' }
      ],
      colors: ['#50AAFF', '#64D27A', '#58D2D2'],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      url1: '/modules/orderCount/totalPriceClass', // 订单金额统计
      url2: '/modules/orderCount/memberLevelCount', // 会员类型统计
      url3: '/modules/orderCount/memberScopeCount', // 会员卡类型统计
      url4: '/modules/orderCount/memberPayCount', // 会员充值金额统计
      url5: '/modules/orderCount/coursePayNumber', // 课程购买人数统计
      url6: '/modules/orderCount/coursePayPirce', // 课程购买金额统计
      url7: '/modules/orderCount/reportPayNumber', // 研报购买人数统计
      url8: '/modules/orderCount/reportPayPirce', // 研报购买金额统计
      chartData1: null,
      chartData2: null,
      chartData3: null,
      chartData4: null,
      chartData5: null,
      chartData6: null,
      chartData7: null
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    resetEvent() {
      this.form.dateRange = []
      this.initData()
    },
    async initData() {
      const that = this
      const loading = that.$loading({
        lock: true,
        text: '数据加载中...',
        background: 'rgba(255, 255, 255, 0.8)'
      })
      const res1 = (await that.$wtRequest({ url: that.url1, method: 'post', data: that.form })).data
      const totalPrice = Number(res1.coursePrice) + Number(res1.memberPrice) + Number(res1.reportPrice)
      that.orderPriceTotal = [
        { title: '订单总金额', price: totalPrice.toFixed(4), titleBgColor: '#FEEFAC' },
        { title: `会员充值订单金额（${totalPrice === 0 ? 0 : (Number(res1.memberPrice) / totalPrice * 100).toFixed(2)}%）`, price: res1.memberPrice, titleBgColor: '#FFBBBB' },
        { title: `课程购买金额（${totalPrice === 0 ? 0 : (Number(res1.coursePrice) / totalPrice * 100).toFixed(2)}%）`, price: res1.coursePrice, titleBgColor: '#B0EEFF' },
        { title: `研报&动态购买金额（${totalPrice === 0 ? 0 : (Number(res1.reportPrice) / totalPrice * 100).toFixed(2)}%）`, price: res1.reportPrice, titleBgColor: '#86E6CC' }
      ]

      that.chartData1 = (await that.$wtRequest({ url: that.url2, method: 'post', data: that.form })).data
      that.chartData2 = (await that.$wtRequest({ url: that.url3, method: 'post', data: that.form })).data
      that.chartData3 = (await that.$wtRequest({ url: that.url4, method: 'post', data: that.form })).data
      that.chartData4 = (await that.$wtRequest({ url: that.url5, method: 'post', data: that.form })).data
      that.chartData5 = (await that.$wtRequest({ url: that.url6, method: 'post', data: that.form })).data
      that.chartData6 = (await that.$wtRequest({ url: that.url7, method: 'post', data: that.form })).data
      that.chartData7 = (await that.$wtRequest({ url: that.url8, method: 'post', data: that.form })).data

      const _chartData3 = {}
      that.chartData3.forEach(item => {
        if (!_chartData3[item.date]) {
          _chartData3[item.date] = item
        } else {
          _chartData3[item.date] = { ..._chartData3[item.date], memberLevel2: item.memberLevel, price2: item.price }
        }
      })
      that.chartData3 = Object.keys(_chartData3).map(item => {
        return { date: item, price: _chartData3[item].price, price2: _chartData3[item].price2 }
      })
      console.log(that.chartData3)
      this.init()
      loading.close()
    },
    init() {
      const chart1 = ECharts.init(document.getElementById('chart1'))
      const option1 = {
        title: {
          text: '会员类型统计',
          left: 'center',
          bottom: 0
        },
        tooltip: {
          trigger: 'item',
          formatter: function(data) {
            return `${data.seriesName}<br/>${data.name}：${data.percent}%`
          }
        },
        legend: {
          left: 'center',
          bottom: '12%',
          icon: 'circle'
        },
        color: this.colors,
        series: [
          {
            name: '会员类型统计',
            type: 'pie',
            radius: '65%',
            center: ['50%', '40%'],
            data: [
              { value: (this.chartData1.find(item => item.key === '2') || { value: 0 }).value, name: '高级会员' },
              { value: (this.chartData1.find(item => item.key === '3') || { value: 0 }).value, name: '优享会员' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      chart1.setOption(option1)

      const chart2 = ECharts.init(document.getElementById('chart2'))
      const option2 = {
        title: {
          text: '会员卡类型统计',
          left: 'center',
          bottom: 0
        },
        tooltip: {
          trigger: 'item',
          formatter: function(data) {
            return `${data.seriesName}<br/>${data.name}：${data.percent}%`
          }
        },
        legend: {
          left: 'center',
          bottom: '12%',
          icon: 'circle'
        },
        color: this.colors,
        series: [
          {
            name: '会员类型统计',
            type: 'pie',
            radius: '65%',
            center: ['50%', '40%'],
            data: [
              { value: (this.chartData2.find(item => item.key === '1') || { value: 0 }).value, name: '月卡' },
              { value: (this.chartData2.find(item => item.key === '2') || { value: 0 }).value, name: '季卡' },
              { value: (this.chartData2.find(item => item.key === '3') || { value: 0 }).value, name: '年卡' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      chart2.setOption(option2)

      const chart3 = ECharts.init(document.getElementById('chart3'))
      const option3 = {
        title: {
          text: '会员充值金额统计',
          left: 'center',
          bottom: 0
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['高级会员', '优享会员'],
          left: 'center',
          bottom: '10%',
          itemWidth: 14,
          itemHeight: 14
        },
        grid: {
          top: 10,
          right: 0,
          bottom: '28%',
          left: 60
        },
        color: this.colors,
        xAxis: {
          data: this.chartData3.map(item => item.date)
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '高级会员',
            type: 'bar',
            barGap: 0,
            barWidth: 12,
            data: this.chartData3.map(item => item.price)
          },
          {
            name: '优享会员',
            type: 'bar',
            barWidth: 12,
            data: this.chartData3.map(item => item.price2)
          }
        ]
      }
      chart3.setOption(option3)

      const chart4 = ECharts.init(document.getElementById('chart4'))
      const option4 = {
        title: {
          text: '购买人数统计',
          left: 'center',
          bottom: 0
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(data) {
            return `${data[0].axisValue}<br/>购买人数：${data[0].data}`
          }
        },
        legend: {
          show: false
        },
        grid: {
          top: 10,
          right: 0,
          bottom: '15%',
          left: 60
        },
        color: this.colors,
        xAxis: {
          data: this.chartData4.map(item => item.key)
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            type: 'line',
            barWidth: 28,
            data: this.chartData4.map(item => item.value)
          }
        ]
      }
      chart4.setOption(option4)

      const chart5 = ECharts.init(document.getElementById('chart5'))
      const option5 = {
        title: {
          text: '购买金额统计',
          left: 'center',
          bottom: 0
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(data) {
            return `${data[0].axisValue}<br/>购买金额：${data[0].data}`
          }
        },
        legend: {
          show: false
        },
        grid: {
          top: 10,
          right: 0,
          bottom: '15%',
          left: 60
        },
        color: this.colors,
        xAxis: {
          data: this.chartData5.map(item => item.key)
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 28,
            data: this.chartData5.map(item => item.value)
          }
        ]
      }
      chart5.setOption(option5)

      const chart6 = ECharts.init(document.getElementById('chart6'))
      const option6 = {
        title: {
          text: '购买人数统计',
          left: 'center',
          bottom: 0
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(data) {
            return `${data[0].axisValue}<br/>购买人数：${data[0].data}`
          }
        },
        legend: {
          show: false
        },
        grid: {
          top: 10,
          right: 0,
          bottom: '15%',
          left: 60
        },
        color: this.colors,
        xAxis: {
          data: this.chartData6.map(item => item.key)
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            type: 'line',
            barWidth: 28,
            data: this.chartData6.map(item => item.value)
          }
        ]
      }
      chart6.setOption(option6)

      const chart7 = ECharts.init(document.getElementById('chart7'))
      const option7 = {
        title: {
          text: '购买金额统计',
          left: 'center',
          bottom: 0
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(data) {
            return `${data[0].axisValue}<br/>购买金额：${data[0].data}`
          }
        },
        legend: {
          show: false
        },
        grid: {
          top: 10,
          right: 0,
          bottom: '15%',
          left: 60
        },
        color: this.colors,
        xAxis: {
          data: this.chartData7.map(item => item.key)
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 28,
            data: this.chartData7.map(item => item.value)
          }
        ]
      }
      chart7.setOption(option7)
    }
  },
  computed: {
  }
}
</script>
<style lang="scss">
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.card-box {
  padding: 16px;
  margin-bottom: 16px;
  background-color: #FFF;
}
.card-title {
  width: 100%;
  padding-bottom: 14px;
  margin-bottom: 16px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #E0E0E0;
}
.data-panel-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .card {
    width: 24.2%;
    min-width: 230px;
    :nth-child(1) {
      padding: 10px 8px 9px 8px;
      font-size: 14px;
    }
    :nth-child(2) {
      padding: 18px 0;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      border: 1px solid;
    }
  }
}
</style>
