<template>
  <div class="page-container">
    <wt-data-form ref="dataForm" :form.sync="form" request-list-url="/modules/news/list" request-delete-url="/modules/news/delete"
                  request-detail-url="/modules/news/detail">
      <template slot="search">
        <wt-search-form-item title="模块" v-model="form.formData.title" type="input" />
        <wt-search-form-item title="操作时间" v-model="form.formData.displayTime" type="daterange" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['NEWS:ADD']" @click="$refs.dataForm.toAdd()">新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['NEWS:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">删除</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column prop="title" label="模块"></el-table-column>
        <el-table-column prop="action" label="操作动作"></el-table-column>
        <el-table-column prop="content" label="操作内容"></el-table-column>
        <el-table-column prop="creator" label="操作用户"></el-table-column>
        <el-table-column prop="ip" label="操作IP"></el-table-column>
        <el-table-column prop="createTime" label="操作时间" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['NEWS:VIEW']" @click="$refs.dataForm.toView(scope.row)">查看详情</wt-text-button>
            <wt-text-button v-has-any-premission="['NEWS:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible"><ExampleNewsView :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible"><ExampleNewsEdit :detailData="form.detailData" :view-type="form.dialogViewType" @on-refresh="$refs.dataForm.requestDataList()" @on-close="form.isEditDialogVisible = false"/></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'logList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
