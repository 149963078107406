<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="发布状态" v-model="crud.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH__OPTIONS" />
        <wt-crud-form-item title="显示端" v-model="crud.formData.type" type="select" :dataList="typeList" />
      </template>
      <template slot="toolbar-left">
        <wt-button type="primary" @click="crud.onClickAdd('articleEdit')">新增轮播图</wt-button>
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="title" label="轮播图">
          <template v-slot="scope">
<!--            <img :src="scope.row.imgUrl" />-->
            <el-image class="imageWidth" lazy :src="scope.row.imgUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="显示端">
          <template v-slot="scope">{{scope.row.type === 1 ? '移动端' : 'web端'}}</template>
        </el-table-column>
        <el-table-column prop="skipUrl" label="跳转地址"></el-table-column>
        <el-table-column prop="orderNumber" label="排序"></el-table-column>
        <el-table-column prop="createAccountName" label="发布人"></el-table-column>
        <el-table-column prop="isPublish" label="发布状态" width="120">
          <template v-slot="scope">
            <wt-crud-publish-switch :id="scope.row.id" :value="scope.row.isPublish" :crud="crud" />
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间"></el-table-column>

        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['BANNERLIST:EDIT']" @click="crud.onClickEdit('bannerEdit', scope.row)">编辑</wt-text-button>
            <wt-text-button v-has-any-premission="['BANNERLIST:DELETE']" @click="crud.onClickDelete(scope.row.id)" type="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <banner-edit
            :detail-data="crud.rowData"
            :view-type="crud.action"
            @on-close="crud.close()"
            @on-refresh="crud.requestDataList()"
        ></banner-edit>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'bannerList',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/banner/list',
      requestPublishUrl: '/modules/banner/updatePublish',
      requestDeleteUrl: '/modules/banner/delete',
      formData: {
        isPublish: '',
        type: ''
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
      typeList: [
        { value: 1, label: '移动端' },
        { value: 2, label: 'web端' }
      ]
    }
  },
  created() {
  },
  methods: {
    onPublishChange(val, row) {
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style lang="scss" scoped>
</style>
