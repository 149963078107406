<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header :title="viewType === 'add' ? '新增优惠券' : '编辑优惠券'">
      <wt-button v-if="viewType === 'add'" v-has-any-premission="['ARTICLELIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['ARTICLELIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="20%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="优惠券用途" prop="useClass" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-select v-model="detailForm.useClass" placeholder="请选择标签" class="width300">
                  <el-option v-for="item in useClassList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="优惠券类型" prop="type" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-select v-model="detailForm.type" placeholder="请选择标签" class="width300">
                  <el-option v-for="item in couponTypeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="detailForm.type === 1">
            <el-col :span="12">
              <el-form-item label="满减条件" prop="fullSubtractPrice" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input-number v-model="detailForm.fullSubtractPrice" controls-position="right" :min="0" :max="10000" class="width300"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="detailForm.type === 1">
            <el-col :span="12">
              <el-form-item label="优惠价格" prop="favourablePrice" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input-number v-model="detailForm.favourablePrice" controls-position="right" :min="0" :max="10000" class="width300"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="detailForm.type === 2">
            <el-col :span="12">
              <el-form-item label="折扣值" prop="discount" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input-number v-model="detailForm.discount" controls-position="right" :min="0" :max="10" class="width300"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="有效期" prop="dateRange" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-date-picker
                    v-model="detailForm.dateRange"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发放范围" prop="givingRange" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-checkbox-group v-model="detailForm.givingRange" class="width300">
                  <el-checkbox v-for="item in rangeList" :label="item.id" :key="'RANGE_' + item.id">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
export default {
  name: 'couponEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {
        discount: '', // 折扣
        endDate: '', // 结束时间
        favourablePrice: '', // 优惠价格
        fullSubtractPrice: '', // 满减价格
        givingRange: [], // 发放范围  1,2,3  初级会员,高级会员,优享会员
        id: '', // 主键
        isPublish: 0, // 是否发布
        startDate: '', // 开始时间
        type: '', // 类型 1:满减  2:折扣
        useClass: '', // 用途  1:课程 2:研报 3:会员充值
        dateRange: [] // 临时存储有效期
      },
      useClassList: [
        { id: 1, name: '课程' },
        { id: 2, name: '研报' },
        { id: 3, name: '会员充值' }
      ],
      couponTypeList: [
        { id: 1, name: '满减券' },
        { id: 2, name: '折扣券' }
      ],
      rangeList: [
        { id: 1, name: '初级会员' },
        { id: 2, name: '高级会员' },
        { id: 3, name: '优享会员' }
      ]
    }
  },
  async mounted() {
    if (this.viewType !== 'add') await this.requestDetail()
  },
  methods: {
    onClickAdd() {
      const that = this
      that.$wtUtil.validateDetailFormsAndConfirm(that, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/coupon/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        const data = {
          discount: resp.data.discount, // 折扣
          endDate: resp.data.endDate, // 结束时间
          favourablePrice: resp.data.favourablePriceDollar, // 优惠价格
          fullSubtractPrice: resp.data.fullSubtractPriceDollar, // 满减价格
          givingRange: resp.data.givingRange.split(',').map(range => Number(range)), // 发放范围  1,2,3  初级会员,高级会员,优享会员
          id: resp.data.id, // 主键
          isPublish: resp.data.isPublish, // 是否发布
          startDate: resp.data.startDate, // 开始时间
          type: resp.data.type, // 类型 1:满减  2:折扣
          useClass: resp.data.useClass, // 用途  1:课程 2:研报 3:会员充值
          dateRange: [resp.data.startDate, resp.data.endDate] // 临时存储有效期
        }
        that.detailForm = data
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/coupon/update',
        method: 'post',
        data: this.getParams()
      }).then(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/coupon/add',
        method: 'post',
        data: this.getParams()
      }).then(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    getParams() {
      const that = this
      const detailForm = JSON.parse(JSON.stringify(that.detailForm))
      const params = {
        discount: detailForm.discount, // 折扣
        endDate: detailForm.dateRange[1], // 结束时间
        favourablePrice: detailForm.favourablePrice * 100, // 优惠价格
        fullSubtractPrice: detailForm.fullSubtractPrice * 100, // 满减价格
        givingRange: detailForm.givingRange.join(','), // 发放范围  1,2,3  初级会员,高级会员,优享会员
        id: detailForm.id, // 主键
        isPublish: detailForm.isPublish, // 是否发布
        startDate: detailForm.dateRange[0], // 开始时间
        type: detailForm.type, // 类型 1:满减  2:折扣
        useClass: detailForm.useClass // 用途  1:课程 2:研报 3:会员充值
      }
      return params
    }
  }
}
</script>
<style scoped lang="scss">
.width300 {
  width: 300px;
}
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 40%;
}
::v-deep .wt-detail-section-form-center  .el-form-item.tinymce  .el-form-item__content {
  width: 90%;
}
.red {
  color: red;
  .blue {
    color: blue;
  }
}
</style>
