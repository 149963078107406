<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container editor-container" v-loading="isLoading">
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <div class="editor-custom-btn-container">
      <div class="upload-container editor-upload-btn">
        <input :ref="'file-' + tinymceId" type="file" style="display: none;" accept="image/png,image/jpeg,image/gif,image/jpg" @change="onWtImageFilePicker" multiple/>
      </div>
    </div>
  </div>
</template>

<script>
import plugins from './plugins'
import toolbar from './toolbar'
import asyncPool from './asyncPool'
import OSS from 'ali-oss'
import SparkMD5 from "spark-md5";
// eslint-disable-next-line no-unused-vars
const FILE_STATE = {
  PROCESS: 'process',
  FAIL: 'fail',
  SUCCESS: 'success'
}
const printLog = (...args) => {
  // console.log(...args)
}
export default {
  name: 'WtTinymce',
  components: { },
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: Number,
      required: false,
      default: 360
    }
  },
  data() {
    return {
      wtBaseApi: '',
      wtFileServerUrl: process.env.VUE_APP_FILE_SERVER_URL,
      dataList: [],
      isLoading: false,
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        'en': 'en',
        'zh': 'zh_CN'
      },
      ossData: {}
    }
  },
  computed: {
    language() {
      return this.languageTypeList[this.$store.getters.language]
    }
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal && (!oldVal || oldVal === '')) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
        return
      }
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
      if (this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    },
    readonly(val, oldVal) {
      if (val !== oldVal) {
        this.$nextTick(() => {
          this.destroyTinymce()
          this.$nextTick(() => this.initTinymce())
        })
      }
    },
    language() {
      this.destroyTinymce()
      this.$nextTick(() => this.initTinymce())
    }
  },
  mounted() {
    this.initTinymce()
  },
  activated() {
    this.initTinymce()
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    initTinymce() {
      const _this = this
      window.tinymce.init({
        readonly: this.readonly,
        language: this.language,
        selector: `#${this.tinymceId}`,
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        font_formats: 'Arial=arial,helvetica,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Impact=impact,Haettenschweiler;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;',
        plugins: plugins,
        end_container_on_empty_block: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        default_link_target: '_blank',
        link_title: false,
        powerpaste_word_import: 'propmt', //  clean,propmt,merge
        powerpaste_html_import: 'propmt', //  clean,propmt,merge
        powerpaste_allow_local_images: true,
        paste_data_images: true,
        images_upload_handler: (blobInfo, success, failure) => {
          // this.$refs['wtUpload'].uploadFile(blobInfo, success, failure)
          // 将base64转为blob，后面的文件名随便写，反正上传oss的时候要生成随机文件名
          let f = _this.dataURLtoFile(blobInfo.base64(), "123.png");
          console.log(f)
          _this.fileToOss(f, (url) => {
            // 上传成功后调用插件api，将图片替换到富文本中
            success(url);
          });
        },
        wtImagePicker: (wtImageCallback) => {
          _this.wtImageCallback = wtImageCallback
          _this.$refs['file-' + this.tinymceId].click()
        },
        nonbreaking_force_tab: true,
        paste_preprocess: (plugin, args) => {
          console.log('paste_preprocess', plugin, args)
        },
        // init_instance_callback: editor => {
        //   if (_this.value) {
        //     editor.setContent(_this.value)
        //   }
        //   _this.hasInit = true
        //   editor.on('NodeChange Change KeyUp SetContent', () => {
        //     this.hasChange = true
        //     this.$emit('input', editor.getContent())
        //   })
        // },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
          editor.on('change', function(e) {
            const val = editor.getContent()
            _this.$emit('input', val)
          })
          editor.on('init', function(e) {
            this.getDoc().body.style.fontSize = '12pt';
            this.getDoc().body.style.fontFamily = 'Arial';
          })
        }
        // content_style: `
        //   *                         { padding:0; margin:0; }
        //   html, body                { height:100%; }
        //   img                       { max-width:100%; display:block;height:auto; }
        //   a                         { text-decoration: none; }
        //   iframe                    { width: 100%; }
        //   p                         { line-height:1.6; margin: 0px; }
        //   table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }
        //   .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }
        //   ul,ol                     { list-style-position:inside; }
        // `
      }).then(resolve => {
        if (_this.value) {
          _this.setContent(_this.value)
        }
      })
    },
    // base64转blob
    dataURLtoFile(dataurl, filename) {
      var mime = "png",
          bstr = atob(dataurl),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // base64转为oss上传方法
    fileToOss(file, callBack = () => {}) {
      const that = this
      // const files = file
      // 这里获取OSS的配置信息
      that.$wtRequest({
        url: '/modules/oss/publicPutObjectStsGrantAuthorization',
        method: 'post',
        data: {}
      }).then(response => {
        that.ossData = response.data
        // 上传实现
        const client = new OSS({
          region: 'oss-' + that.ossData.region,
          accessKeyId: that.ossData.accessKeyId,
          accessKeySecret: that.ossData.accessKeySecret,
          bucket: that.ossData.bucket,
          stsToken: that.ossData.securityToken
        })

        // 组织数据
        let fileNames = ''
        console.log(file)
        // 将文件进行md5
        that.getFileMd5(file, (file, result) => {
          if (result.resultCode === 'SUCCESS') {
            fileNames = result.fileMd5 + '.' + file.name.split('.').pop()
            console.log('fileNames', fileNames)
            // sdk上传
            client.put('/article/' + fileNames, file).then(res => {
              callBack(res.url);
            })
          }
        })
        // TODO 上传失败未处理
      })
    },
    getFileMd5(file, callback) {
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunkSize = 1024 * 1024 * 2
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          const fileMd5 = spark.end()
          callback(file, { resultCode: 'SUCCESS', fileMd5: fileMd5 })
        }
      }

      fileReader.onerror = function(err) {
        callback(file, { resultCode: 'FAIL', msg: err })
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      return window.tinymce.get(this.tinymceId).getContent()
    },
    //  上传图片完成
    onWtImageFileUploadFinished(url) {
      printLog('onWtImageFileUploadFinished', url)
      const that = this
      if (url) {
        window.tinymce.get(that.tinymceId).insertContent(`<img src="${url}" >`)
      }
    },
    onWtImageFilePicker(event) {
      const that = this
      try {
        const files = event.target.files
        if (!files || files.length <= 0) {
          that.$refs['file-' + this.tinymceId].value = ''
          return
        }
        that.isLoading = true
        that.fileToOss(files[0], (url) => {
          // 上传成功后调用插件api，将图片替换到富文本中
          that.onWtImageFileUploadFinished(url);
          that.isLoading = false
        });
      } catch (ex) {
        that.isLoading = false
        console.log('上传失败', ex)
      }
      that.$refs['file-' + this.tinymceId].value = ''
    },
  }
}
</script>

<style scoped>
  .tinymce-container {
    position: relative;
  }
  .tinymce-container>>>.mce-fullscreen {
    z-index: 10000;
  }
  .tinymce-textarea {
    visibility: hidden;
    z-index: -1;
  }
  .editor-custom-btn-container {
    position: absolute;
    right: 4px;
    top: 4px;
    /*z-index: 2005;*/
  }
  .fullscreen .editor-custom-btn-container {
    z-index: 10000;
    position: fixed;
  }
  .editor-upload-btn {
    display: inline-block;
  }
</style>
