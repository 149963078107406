<template>
  <div class="wt-form-pagination">
    <el-pagination
      :current-page="form.formResult.currentPage"
      :page-size="form.formResult.pageSize"
      :total="form.formResult.total"
      layout="prev, pager, next, total"
      @current-change="onPageCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'WtEasyFormPagination',
  props: ['form'],
  methods: {
    onPageCurrentChange(pageNum) {
      //  切换页码
      this.form.pageData.currentPage = pageNum
      this.$emit('request-form-list')
    }
  }
}
</script>

<style scoped>

</style>
