<template>
  <el-button type="text" @click="onClick" class="wt-text-button" v-bind="$attrs">
    <slot></slot>
  </el-button>
</template>
<script>
export default {
  name: 'WtTextButton',
  isAutoComponent: true,
  methods: {
    onClick() {
      this.$emit('click', '')
    }
  }
}
</script>

<style scoped>
.wt-text-button {
  padding: 4px 0;
}
</style>
