<template>
  <div class="page-container">
    <wt-data-form ref="dataForm" :form.sync="form" request-list-url="modules/auth/roleList" request-delete-url="modules/auth/roleDelete"
                  request-detail-url="modules/auth/roleDetail">
      <template slot="search">
        <wt-search-form-item title="搜索" v-model="form.formData.roleName" type="input" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['ROLELIST:ADD']" @click="$refs.dataForm.toAdd()">新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['ROLELIST:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">删除</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择" :selectable="onSelectable"></el-table-column>
        <el-table-column prop="roleName" label="名称"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column prop="isPublish" label="是否启用">
          <template slot-scope="scope">
            <span :class="scope.row.isPublish|FILTER_IsPublish_Class">{{scope.row.isPublish|FILTER_IsPublish}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['ROLELIST:VIEW']" @click="$refs.dataForm.toView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['ROLELIST:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible">
      <RoleEdit :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible">
      <RoleEdit :detailData="form.detailData" :view-type="form.dialogViewType" @on-refresh="$refs.dataForm.requestDataList()" @on-close="form.isEditDialogVisible = false"/></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'roleList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {
          roleType: 0
        },
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {
        },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.formData.roleType = 0
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    onSelectable(row, index) {
      return row.id > 1
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
