<template>
  <div>
    <el-button v-if="!isReadMode" size="small" type="primary" :loading="isUploading" @click="onClickUpload">点击上传</el-button>
    <span class="upload-file-state">{{isChecking ? '正在检查文件' : ''}}</span>
    <input type="file" style="display: none;" ref="fileInput" accept="video/mp4" @change="onFileChanged" />
    <div v-if="!isReadMode" class="el-upload__tip">{{tip}}</div>
    <div class="upload-file-list">
      <div v-for="(item, index) in fileList" :key="index" class="upload-file-item">
        <span class="title">{{item.name ? item.name : item.url}}</span>
        <div v-if="!isReadMode" class="upload-file-item-delete" @click="onDeleteItem(index)"><i class="el-icon-close"></i></div>
        <el-progress v-show="fileProgressMap[item.md5] && fileProgressMap[item.md5].percent < 100" class="progreess" :percentage="fileProgressMap[item.md5] ? fileProgressMap[item.md5].percent : 0"></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter'
import SparkMD5 from 'spark-md5'
import uploadHelper from './uploadHelper'

export default {
  name: 'WtChunkFileUpload',
  mixins: [Emitter],
  props: {
    value: {
      default: null
    },
    type: {
      type: String,
      default: 'string'// 支持string（单独的url）、list（url的列表）、json（json的列表）、jsonstring(json的string)
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: null
    },
    tip: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '*'
    },
    chunkSize: {
      type: Number,
      default: 1024 * 1024 * 5
    }
  },
  data() {
    return {
      isChecking: false,
      uploaderMap: {},
      fileProgressMap: {},
      fileData: null,
      isInited: false,
      retry: 3,
      isUploading: false,
      uploadUrl: window.wtConst.BASE_API + '/modules/fileChunks/v2/upload',
      fileList: []
    }
  },
  mounted() {
    if (this.value && !this.isInited) {
      if (typeof (this.value) === 'string') {
        this.fileList.push(this.buildFileItem(this.value, this.value, '', ''))
      } else if (typeof (this.value) === 'object' && this.type === 'json' && this.value instanceof Array) {
        this.value.forEach((item) => {
          this.fileList.push(this.buildFileItem(item.name, item.url, item.md5, item.length))
        })
      } else if (typeof (this.value) === 'object' && this.type === 'json') {
        console.error('当wt-file-upload类型为json时，初始化v-model需要为数组，如：[{ name: \'\', url: \'\', md5: \'\', length: \'\' }]')
      }
    }
  },
  beforeDestroy() {
    const that = this
    Object.keys(that.uploaderMap).forEach((item) => {
      if (that.uploaderMap[item]) {
        that.uploaderMap[item].cancelUpload()
      }
    })
  },
  watch: {
    value: {
      handler() {
        if (this.value && !this.isInited) {
          if (typeof (this.value) === 'string') {
            this.isInited = true
            this.fileList.push(this.buildFileItem(this.value, this.value, '', ''))
            this.updateValue()
          } else if (typeof (this.value) === 'object' && this.type === 'json' && this.value instanceof Array && this.fileList.length <= 0) {
            this.isInited = true
            this.value.forEach((item) => {
              this.fileList.push(this.buildFileItem(item.name, item.url, item.md5, item.length))
            })
          }
        } else if (this.value && this.fileList.length <= 0) {
          this.value.forEach((item) => {
            this.fileList.push(this.buildFileItem(item.name, item.url, item.md5, item.length))
          })
        }
        this.isInited = true
        this.dispatch('ElFormItem', 'el.form.change', this.value)
      },
      deep: true
    }
  },
  methods: {
    onClickUpload() {
      this.$refs.fileInput.click()
    },
    onFileChanged(file) {
      if (this.$refs.fileInput.files) {
        for (let i = 0; i < this.$refs.fileInput.files.length; i++) {
          this.beforeUpload(this.$refs.fileInput.files[i])
        }
      }
      this.$refs.fileInput.value = null
    },
    onFileUploadSuccess(response, file, fileList) {
      this.isUploading = false
      if (response.code !== '0') {
        this.$message({ type: 'error', message: '上传失败' })
        return
      }
      const that = this
      const fileResult = response.data
      that.fileList.push(that.buildFileItem(fileResult.name, fileResult.url, fileResult.md5, fileResult.length))
      that.updateValue()
    },
    onFileUploadError() {
      this.isUploading = false
      this.$message({ type: 'error', message: '上传失败' })
    },
    beforeUpload(file) {
      const that = this
      if (this.limit && this.fileList && this.fileList.length >= this.limit) {
        this.$message({ type: 'warning', message: `最多可以上传${this.limit}个文件` })
        return false
      }
      that.isChecking = true
      that.isUploading = true
      that.checkFileMd5(file).then((md5File) => {
        return that.requestCheckFile(md5File)
      }).then((md5File, resp) => {
        return that.requestCheckChunk(md5File)
      }).then(({ md5File, resp }) => {
        that.isChecking = false
        const uploader = uploadHelper({
          md5File: md5File,
          chunkSize: that.chunkSize,
          onUploadStart: (md5File) => {
            that.fileList.push(that.buildFileItem(md5File.name, '', md5File.md5, md5File.size))
            that.updateFileItemState(md5File.md5, 'UPLOADING', md5File.percent)
          },
          onUploadProgress: (md5File) => {
            that.updateFileItemState(md5File.md5, 'UPLOADING', md5File.percent)
          },
          onUploadSuccess: (md5File) => {
            that.requestMergeFile(md5File)
          },
          onUploadFail: (md5File) => {
            that.isUploading = false
            that.updateFileItemState(md5File.md5, 'FAIL', md5File.percent)
          }
        })
        uploader.start(resp.data)
        if (that.uploaderMap[md5File.md5]) {
          that.uploaderMap[md5File.md5].cancelUpload()
        }
        that.uploaderMap[md5File.md5] = uploader
      }).catch(() => {
        that.isChecking = false
        that.isUploading = false
      })
      return false
    },
    onDeleteItem(index) {
      const that = this
      const fileMd5 = this.fileList[index].md5
      this.fileList = this.fileList.filter((item, itemIndex) => {
        return index !== itemIndex
      })
      this.updateValue()
      if (that.uploaderMap[fileMd5]) {
        that.uploaderMap[fileMd5].cancelUpload()
        that.uploaderMap[fileMd5] = null
      }
    },
    onDownloadItem(index) {
      window.open(this.$wtUtil.buildImageUrl(this.fileList[index].url), '_blank')
    },
    updateValue() {
      if (this.type === 'string') {
        if (this.fileList && this.fileList.length > 0) {
          this.$emit('input', this.fileList[0].url)
        } else {
          this.$emit('input', '')
        }
        return
      } else if (this.type === 'json') {
        if (this.fileList && this.fileList.length > 0) {
          this.$emit('input', this.fileList)
        } else {
          this.$emit('input', [])
        }
        return
      } else if (this.type === 'jsonstring') {
        if (this.fileList && this.fileList.length > 0) {
          this.$emit('input', JSON.stringify(this.fileList))
        } else {
          this.$emit('input', null)
        }
        return
      }
      this.$emit('input', this.fileList)
    },
    checkFileMd5(file) {
      const that = this
      return new Promise((resolve, reject) => {
        try {
          that.getFileMd5(file, (md5File, data) => {
            if (data.resultCode === 'SUCCESS') {
              file.md5 = data.fileMd5
              resolve(file)
            } else {
              reject(new Error('文件检查失败'))
            }
          })
        } catch (e) {
          reject(new Error(e))
        }
      })
    },
    requestCheckFile(md5File) {
      const that = this
      const singleMd5 = md5File.md5
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/modules/fileChunks/v2/checkFile',
          method: 'post',
          data: { list: [singleMd5] }
        }).then((resp) => {
          if (resp.code === '0' && resp.data && resp.data[singleMd5]) {
            const fileResult = resp.data[singleMd5]
            that.fileList.push(that.buildFileItem(fileResult.name, fileResult.url, fileResult.md5, fileResult.length))
            that.updateValue()
            that.isUploading = false
            return reject(new Error(''))
          }
          return resolve(md5File, resp)
        }).catch((e) => {
          that.isUploading = false
          return reject(new Error(e))
        })
      })
    },
    requestCheckChunk(md5File) {
      const that = this
      const fileMd5 = md5File.md5
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/modules/fileChunks/v2/checkChunk',
          method: 'post',
          data: { md5: fileMd5 }
        }).then((resp) => {
          resolve({ md5File, resp })
        }).catch((e) => {
          that.isUploading = false
          reject(new Error(e))
        })
      })
    },
    requestMergeFile(md5File) {
      const that = this
      that.$wtRequest({
        url: '/modules/fileChunks/v2/mergeFile',
        method: 'post',
        data: { md5: md5File.md5, fileSize: md5File.size, chunkSize: that.chunkSize, fileName: md5File.name }
      }).then((resp) => {
        if (resp.code === '0') {
          const video = resp.data || {}
          that.updateFileItemState(md5File.md5, 'SUCCESS', md5File.percent)
          that.updateFileItemUrl(md5File.md5, video.videoUrl)
          that.updateValue()
        } else {
          that.$message({ type: 'warning', message: '上传文件失败，请稍候再试' })
          that.fileList = that.fileList.filter((item) => {
            return item.md5 !== md5File.md5
          })
          that.updateValue()
        }
        that.isUploading = false
      }).catch((e) => {
        that.isUploading = false
      })
    },
    getFileMd5(file, callback) {
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunkSize = 1024 * 1024 * 512
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          const fileMd5 = spark.end()
          callback(file, { resultCode: 'SUCCESS', fileMd5: fileMd5 })
        }
      }

      fileReader.onerror = function(err) {
        callback(file, { resultCode: 'FAIL', msg: err })
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    },
    buildFileItem(name, url, md5, length) {
      return { name: name, url: url, fileUrl: this.$wtUtil.buildImageUrl(url), md5: md5, length: length }
    },
    updateFileItemUrl(md5, url) {
      this.fileList[this.fileList.length - 1].url = url
      this.fileList[this.fileList.length - 1].fileUrl = this.$wtUtil.buildImageUrl(url)
    },
    updateFileItemState(md5, state, percent) {
      const percentVal = (percent * 100).toFixed(0)
      this.$set(this.fileProgressMap, md5, {
        state: state,
        percent: parseInt(percentVal)
      })
    }
  },
  computed: {
    isReadMode() {
      return (this.readonly === '') || this.readonly
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-file-list{display: flex;flex-wrap: wrap;}
  .upload-file-item{
    position: relative;width: 100%;margin-right: 16px;min-height: 24px;line-height: 24px; display: flex;justify-content: space-between;
    > .title {
      margin-left: 8px; margin-right: 32px; cursor: pointer;
    }
    > .progreess{
      position: absolute;bottom: 0;width: 100%;height: 4px;
    }
  }
  .upload-file-item:hover{
    background-color: #F4F4F4;
  }
  .upload-file-item-delete{
    position: absolute;right: 10px;top: 0;cursor: pointer;
    :hover,:active{opacity: 0.75;}
  }
  .upload-file-state{
    color: #999999;font-size: 14px;margin-left: 16px;
  }
  .upload-file-item-img{width: 80px; height: 80px;margin: 10px;}
  .upload-file-item-ext{
    width: calc(100% - 110px);display: flex;flex-direction: column;justify-content: center;margin-right: 10px;
    > span{line-height: 1;}
  }
</style>
