<template>
  <div>
    <el-form size="small" class="wt-form-btns" :inline="true">
      <template v-if="!$slots.default">
        <template v-for="(item, index) in form.formButtonsData" >
          <template v-if="!item.permission">
            <el-button size="small" :type="item.className ? item.className : 'primary'" v-bind:key="index" @click="toClickAction(item)">{{item.name}}</el-button>
          </template>
          <wt-auth v-else class="search-form-btn" :hasAnyPremission="form.formCode + ':' + item.permission" v-bind:key="index">
            <el-button size="small" :type="item.className ? item.className : 'primary'" @click="toClickAction(item)">{{item.name}}</el-button>
          </wt-auth>
        </template>
      </template>
      <slot name="default"></slot>
      <slot name="custom"></slot>
    </el-form>
    <wt-detail-page :visible.sync="isAddDialogVisible" :component-name="form.formComponent.add.componentName" component-type="add" />
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  name: 'wt-form-buttons',
  props: ['form'],
  data() {
    return {
      isAddDialogVisible: false
    }
  },
  methods: {
    toClickAction(item) {
      if (item.action === 'DELETE_LIST') {
        this.toDeleteMutil()
      } else if (item.action === 'ADD') {
        this.isAddDialogVisible = true
        if (this.form.formComponent && this.form.formComponent.add) {
          this.$store.dispatch(this.form.formComponent.add.value)
        }
      }
      this.$emit('on-button-action', { item })
    },
    toDeleteMutil() {
      if (!this.form.formSelectedList || this.form.formSelectedList.length <= 0) {
        this.$alert('请选中至少一行数据', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        }).then(() => {
        }).catch(() => {})
        return
      }
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const dataIdList = []
        that.form.formSelectedList.forEach((item) => {
          dataIdList.push(item.id)
        })
        that.requestDeleteMutil(dataIdList)
      }).catch(() => {
      })
    },
    requestDeleteMutil(dataIdList) {
      const url = (this.form.formComponent && this.form.formComponent.delete ? this.form.formComponent.delete.value : '')
      const that = this
      request({
        url: url,
        method: 'post',
        data: { list: dataIdList }
      }).then(response => {
        that.requestList()
      }).catch(error => {
        console.log('error', error)
      })
    },
    requestList() {
      if (this._events && this._events['request-form-list']) {
        this.$emit('request-form-list')
      } else if (this.$parent && this.$parent.requestFormList) {
        this.$parent.requestFormList()
      }
    }
  }
}
</script>

<style scoped>

</style>
