<template>
  <el-select v-model="dataValue" placeholder="请选择" @change="onValueChange" v-bind="$attrs">
    <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'wtFormSimpleSelectItem',
  isAutoComponent: true,
  props: ['value', 'placeholder'],
  data() {
    return {
      dataValue: '',
      dataList: [
        { label: '是', value: 1 },
        { label: '否', value: 0 }]
    }
  },
  methods: {
    onValueChange(val) {
      this.$emit('input', val)
    }
  },
  watch: {
    value: function(newVal) {
      this.dataValue = newVal
    }
  },
  mounted() {
    if ((typeof this.value) !== 'undefined') {
      this.dataValue = this.value
    }
  }
}
</script>

<style>
</style>
