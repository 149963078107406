<template>
  <wt-detail-dialog :bgc="bgc" title="" custom-class="app-dialog" append-to-element="app-page-container"
             :destroy-on-close="true" :modal="false" :visible.sync="visible">
    <slot v-if="visible"></slot>
  </wt-detail-dialog>
</template>

<script>
import WtDetailDialog from '../WtDetailDialog'
export default {
  name: 'wt-detail-page',
  props: ['visible', 'bgc'],
  components: {
    WtDetailDialog
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
