<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header :title="viewType === 'add' ? '新增课程' : '编辑课程'">
<!--      <wt-button v-if="viewType === 'add'" v-has-any-premission="['ARTICLELIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>-->
<!--      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['ARTICLELIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>-->
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section>
        <el-steps :active="stepActive" finish-status="success" style="width: 85%;padding-left: 16px;margin: 0 auto;">
          <el-step title="课程信息维护"></el-step>
          <el-step title="课程章节维护"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
      </wt-detail-section>
      <wt-detail-section style="padding-left: 16px;">
        <el-form v-if="stepActive === 0" :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="15%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="封面图" prop="imgId">
                <label slot="label"><span class="red">*</span> 封面图</label>
                <wt-oss-upload
                    :fileList.sync="coverList"
                    public
                    file-path="course/"
                    file-size="150px*200px"
                    list-type="picture"
                    :limit="1"
                    :file-limit="1024 * 200"
                    style="width: 200%;">
                </wt-oss-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程名称" prop="name" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input v-model="detailForm.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="排序" prop="orderNumber">
                <el-input-number v-model="detailForm.orderNumber" :min="0"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="标签" prop="labelId" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-select v-model="detailForm.labelId" placeholder="请选择标签" class="wt-w-100">
                  <el-option v-for="item in labelList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程分类" prop="playClass" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-select v-model="detailForm.playClass" placeholder="请选择课程分类" class="wt-w-100">
                  <el-option v-for="item in $wtConst.COURSE_PLAY_CLASS" :label="item.label" :value="item.value" :key="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="价格" prop="price" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input v-model="detailForm.price" type="number" placeholder="请输入" @input="inputLimit(detailForm.price)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程讲师" prop="teacher" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input v-model="detailForm.teacher" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设为推荐" prop="isRecommend" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-radio-group v-model="detailForm.isRecommend">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="师资简介" prop="teacherIntroduction" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input type="textarea" v-model="detailForm.teacherIntroduction" placeholder="请输入" rows="4" maxlength="1000" show-word-limit style="width: 280%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程简介" prop="introduction" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input type="textarea" v-model="detailForm.introduction" placeholder="请输入" rows="4" maxlength="1000" show-word-limit style="width: 280%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程特色" prop="characteristic">
                <el-input type="textarea" v-model="detailForm.characteristic" placeholder="请输入" rows="4" maxlength="1000" show-word-limit style="width: 280%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程价值" prop="worth">
                <el-input type="textarea" v-model="detailForm.worth" placeholder="请输入" rows="4" maxlength="1000" show-word-limit style="width: 280%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row v-if="stepActive === 1" style="width: 100%;">
          <wt-button @click="chapterAddAndUpdate('add')" type="primary">新增章</wt-button>
          <el-row class="row-th">
            <el-col :span="2">序号</el-col>
            <el-col :span="10">名称</el-col>
            <el-col :span="6">时长</el-col>
            <el-col :span="6">操作</el-col>
          </el-row>
          <el-row v-for="(item, index) in chapterList" :key="`CH_${index}`">
            <el-row class="title-row">
              <el-col :span="18">{{item.name}}</el-col>
              <el-col :span="6">
                <wt-text-button @click="sectionAddAndUpdate(item)">新增小节</wt-text-button>
                <wt-text-button @click="chapterAddAndUpdate(item)">修改</wt-text-button>
                <wt-text-button @click="chapterDelete(item)">删除</wt-text-button>
              </el-col>
            </el-row>
            <el-row v-for="(itm, idx) in item.fileList" :key="`ITM_${idx}`" class="row-tr">
              <el-col :span="2">{{idx + 1}}</el-col>
              <el-col :span="10">{{itm.fileName}}</el-col>
              <el-col :span="6">{{ itm.fileDurationFormat }}</el-col>
              <el-col :span="6">
                <wt-text-button @click="sectionAddAndUpdate(item, itm)">编辑</wt-text-button>
                <wt-text-button @click="sectionMove('up', itm)">上移</wt-text-button>
                <wt-text-button @click="sectionMove('down', itm)">下移</wt-text-button>
                <wt-text-button @click="sectionDelete(itm)">删除</wt-text-button>
              </el-col>
            </el-row>
          </el-row>
        </el-row>
        <div v-if="stepActive === 3" class="success">
          <div class="success-tip">课程创建成功</div>
          <wt-button type="primary" @click="$emit('on-close')">返回课程列表</wt-button>
        </div>
        <el-row v-if="stepActive !== 3" class="bottom-operation">
          <wt-button v-if="stepActive > 0" type="default" @click="onClickStep(-1)">上一步</wt-button>
          <wt-button type="primary" @click="onClickStep(1)">下一步</wt-button>
        </el-row>
      </wt-detail-section>
    </div>
    <el-dialog
      :title="chapterForm.id ? '修改章' : '新增章'"
      :visible.sync="chapterNameDialogVisible"
      append-to-body
      width="40%">
      <el-row style="display: flex;align-items: center;">
        <el-col :span="3">
          <span style="color: #f44336;">*</span>章名称
        </el-col>
        <el-col :span="21">
          <el-input v-model="chapterForm.name" size="medium" placeholder="请输入名称"/>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <wt-button @click="chapterNameDialogVisible = false">取 消</wt-button>
        <wt-button type="primary" @click="requestChapterAddAndUpdate">确 定</wt-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="stepActive === 1"
      title="新增小节"
      :visible.sync="sectionDialogVisible"
      append-to-body
      destroy-on-close
      width="40%">
      <el-form :model="chapterFileForm" ref="sectionForm" size="small" class="wt-detail-section-form-center" label-width="15%">
        <el-form-item label="小节名称" prop="fileName" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input v-model="chapterFileForm.fileName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="支持试看">
          <el-switch v-model="trySeeSwitch"></el-switch>
        </el-form-item>
        <el-form-item v-if="trySeeSwitch" label="试看时长" prop="trySeeDuration" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-input-number controls-position="right" v-model="chapterFileForm.trySeeDuration" :min="0"></el-input-number>
          <span style="position: relative; top: 3px; margin: 0 0 0 12px; font-size: 18px;">秒</span>
        </el-form-item>
      </el-form>
      <wt-aliyun-video-upload @uploadSuccess="videoUploadSuccess"/>
      <span slot="footer" class="dialog-footer">
        <wt-button @click="sectionDialogVisible = false">取 消</wt-button>
        <wt-button type="primary" @click="confirmVideoEvent">确 定</wt-button>
      </span>
    </el-dialog>
  </wt-crud-page>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'courseEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      stepActive: 0,
      stepFlag: 0,
      chapterList: [], // 课程章节列表数据
      coverList: [], // 封面图列表
      labelList: [], // 课程标签
      chapterNameDialogVisible: false, // 新增or修改章弹窗
      sectionDialogVisible: false, // 新增or修改小节弹窗
      detailForm: {
        characteristic: '', // 课程特色
        id: '', // 主键
        imgId: '', // 封面图
        introduction: '', // 课程简介
        isFirstPageShow: 0, // 是否首页显示
        isRecommend: '', // 是否推荐
        labelId: '', // 标签 dictionary表 type = 2的数据
        name: '', // 课程名称
        orderNumber: '', // 排序数字
        playClass: '', // 分类 1直播 2录播
        price: '', // 金额
        teacher: '', // 课程老师
        teacherIntroduction: '', // 师资简介
        worth: '' // 课程价值
      },
      chapterForm: {
        courseId: '', // 课程ID
        id: '', // 主键
        name: '' // 章节名称
      },
      chapterFileForm: {
        courseChapterId: '', // 课程章节ID
        fileDuration: '', // 附件时长
        fileId: '', // 文件ID
        fileName: '', // 附件名称
        fileSize: '', // 附件大小
        id: '', // 主键
        orderNumber: '', // 排序数字
        trySeeDuration: '' // 试看时长（秒)
      },
      trySeeSwitch: true
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
    this.listByType2()
  },
  created() {
  },
  watch: {
    stepActive(newVal) {
      if (newVal === 1) {
        this.getChapterListData()
      }
    }
  },
  methods: {
    // 视频上传成功自定义事件
    videoUploadSuccess(e) {
      this.chapterFileForm.fileId = e.videoId
      this.chapterFileForm.fileSize = e.file.size
    },
    // 获取课程标签的数据字典list   type = 2
    listByType2() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: { id: 2 }
      }).then((resp) => {
        that.labelList = resp.data
      }).catch(() => {
      })
    },
    // 上一步 下一步操作
    onClickStep(step) {
      this.stepFlag = step
      if (this.stepActive === 0) {
        if (!this.detailForm.id) {
          console.log('调新增课程接口')
          this.onClickAdd()
        } else {
          console.log('调更新课程接口')
          this.onClickSave()
        }
      } else {
        this.stepActive = Math.min(Math.max(this.stepActive + this.stepFlag, 0), 3)
        if (this.stepFlag === 1 && this.stepActive === 2) this.stepActive = 3
        if (this.stepFlag === -1 && this.stepActive === 2) this.stepActive = 1
      }
    },

    // 新增or更新章事件
    chapterAddAndUpdate(item) {
      if (item === 'add') {
        this.resetChapterForm()
      } else {
        this.chapterForm.id = item.id
        this.chapterForm.name = item.name
      }
      this.chapterNameDialogVisible = true
    },
    // 请求新增or更新章
    requestChapterAddAndUpdate() {
      const that = this
      if (!that.chapterForm.name) return that.$message.warning('章名称不能为空')
      let url = '/modules/courseChapter/add'
      if (that.chapterForm.id) url = '/modules/courseChapter/update'
      that.chapterForm.courseId = that.detailData.id
      that.$wtRequest({
        url,
        method: 'post',
        data: that.chapterForm
      }).then((resp) => {
        that.chapterNameDialogVisible = false
        that.getChapterListData()
        that.resetChapterForm()
        that.$message({ type: 'success', message: '请求成功' })
      })
    },
    // 删除章
    chapterDelete(item) {
      const that = this
      that.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$wtRequest({
          url: '/modules/courseChapter/delete',
          method: 'post',
          data: { list: [item.id] }
        }).then(() => {
          that.getChapterListData()
          that.$message({ type: 'success', message: '请求成功' })
        })
      }).catch(() => {})
    },
    // 重置章表单
    resetChapterForm() {
      this.chapterForm = {
        courseId: '', // 课程ID
        id: '', // 主键
        name: '' // 章节名称
      }
    },

    // 新增or更新小节事件
    sectionAddAndUpdate(chapter, chapterFile) {
      if (!chapterFile) {
        this.trySeeSwitch = true
        this.resetChapterFileForm()
        this.chapterFileForm.courseChapterId = chapter.id
      } else {
        this.trySeeSwitch = chapterFile.trySeeDuration > 0
        this.chapterFileForm = {
          courseChapterId: chapterFile.courseChapterId, // 课程章节ID
          fileId: chapterFile.fileId, // 文件ID
          fileName: chapterFile.fileName, // 附件名称
          fileSize: chapterFile.fileSize, // 附件大小
          id: chapterFile.id, // 主键
          trySeeDuration: chapterFile.trySeeDuration // 试看时长（秒)
        }
      }
      this.sectionDialogVisible = true
    },
    // 确认新增or更新小节事件
    confirmVideoEvent() {
      const that = this
      if (!that.chapterFileForm.fileId) {
        that.$message({ type: 'error', message: '请上传课程视频' })
        return
      }
      that.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?', 'sectionForm').then(() => {
        that.requestSectionAddAndUpdate()
      }).catch(() => {
      })
    },
    // 请求新增or更新小节
    requestSectionAddAndUpdate() {
      const that = this
      let url = '/modules/courseChapterFile/add'
      if (that.chapterFileForm.id) url = '/modules/courseChapterFile/update'
      if (!that.trySeeSwitch) that.chapterFileForm.trySeeDuration = 0
      that.$wtRequest({
        url,
        method: 'post',
        data: that.chapterFileForm
      }).then((resp) => {
        that.sectionDialogVisible = false
        that.getChapterListData()
        that.resetChapterFileForm()
        that.$message({ type: 'success', message: '请求成功' })
      })
    },
    // 移动小节
    sectionMove(direction, item) {
      const that = this
      let url = '/modules/courseChapterFile/moveUp'
      if (direction === 'down') url = '/modules/courseChapterFile/moveDown'
      that.$wtRequest({
        url,
        method: 'post',
        data: { id: item.id }
      }).then(() => {
        that.getChapterListData()
        // that.$message({ type: 'success', message: '请求成功' })
      })
    },
    // 删除小节
    sectionDelete(item) {
      const that = this
      that.$confirm('是否确认删除小节?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$wtRequest({
          url: '/modules/courseChapterFile/delete',
          method: 'post',
          data: { list: [item.id] }
        }).then(() => {
          that.getChapterListData()
          that.$message({ type: 'success', message: '请求成功' })
        })
      }).catch(() => {})
    },
    // 重置小节表单

    resetChapterFileForm() {
      this.chapterFileForm = {
        courseChapterId: '', // 课程章节ID
        fileDuration: '', // 附件时长
        fileId: '', // 文件ID
        fileName: '', // 附件名称
        fileSize: '', // 附件大小
        id: '', // 主键
        orderNumber: '', // 排序数字
        trySeeDuration: '' // 试看时长（秒)
      }
    },
    // 获取章节列表数据
    getChapterListData() {
      const that = this
      that.$wtRequest({
        url: '/modules/course/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.chapterList = resp.data.chapterList
      })
    },
    // 新增课程事件
    onClickAdd() {
      const that = this
      if (that.coverList && that.coverList.length < 1) {
        that.$message({ type: 'error', message: '请上传封面图' })
        return
      }
      that.detailForm.imgId = this.coverList[0].name
      that.$wtUtil.validateDetailFormsAndConfirm(that, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    // 修改课程事件
    onClickSave() {
      const that = this
      if (that.coverList && that.coverList.length < 1) {
        that.$message({ type: 'error', message: '请上传封面图' })
        return
      }
      that.detailForm.imgId = this.coverList[0].name
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    // 获取课程详情
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/course/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        resp.data.price = resp.data.priceDollar
        that.detailForm = resp.data || {}
        // // 文件处理
        this.coverList = []
        this.coverList.push(
          {
            name: that.detailForm.imgId,
            url: that.detailForm.imgUrl,
            status: 'success'
          }
        )
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    // 请求更新课程
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/course/update',
        method: 'post',
        data: that.getParams()
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        this.stepActive = Math.min(Math.max(this.stepActive + this.stepFlag, 0), 3)
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    // 请求新增课程
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/course/add',
        method: 'post',
        data: that.getParams()
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.detailData.id = resp.data
        that.stepActive = Math.min(Math.max(that.stepActive + that.stepFlag, 0), 3)
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    // 处理新增or编辑课程入参
    getParams() {
      const params = JSON.parse(JSON.stringify(this.detailForm))
      params.price = Number(parseFloat(params.price * 100).toPrecision(12))
      return params
    },
    // 价格输入格式处理
    inputLimit(price) {
      this.detailForm.price = price.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
.bottom-operation {
  margin: 16px 0 0 auto;
}
.row-th {
  padding: 0 16px;
  margin-top: 12px;
}
.row-tr {
  display: flex;
  align-items: center;
  padding: 0 16px 11px 0;
  margin-top: 12px;
  border-bottom: 1px solid #E8EAF0;
}
.title-row {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #EDF1F8;
}
.success {
  width: 100%;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.success-tip {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 36px;
}
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 40%;
}
::v-deep .wt-detail-section-form-center  .el-form-item.tinymce  .el-form-item__content {
  width: 90%;
}
.red {
  color: red;
  .blue {
    color: blue;
  }
}
</style>
