<template>
  <div>
    <div v-if="isShowDay" class="wt-countdown" :style="{fontSize:fontSize+'px', color:color}">
      <div class="wt-countdown-item"  v-if="showDays && (hideZeroDay || (!hideZeroDay && d != '0'))">
        <div class="wt-countdown-time">
          {{ d }}
        </div>
      </div>
      <div
        class="wt-countdown-colon"
        :style="{paddingBottom: separator == 'colon' ? '2px' : 0}"
        v-if="showDays && (hideZeroDay || (!hideZeroDay && d != '0'))">
        {{ separator == 'colon' ? ':' : '天' }}
      </div>
      <div class="wt-countdown-item" v-if="showHours">
        <div class="wt-countdown-time">
          {{ h }}
        </div>
      </div>
      <div
        class="wt-countdown-colon"
        :style="{paddingBottom: separator == 'colon' ? '2px' : 0}"
        v-if="showHours"
      >
        {{ separator == 'colon' ? ':' : '时' }}
      </div>
      <div class="wt-countdown-item" v-if="showMinutes">
        <div class="wt-countdown-time">
          {{ i }}
        </div>
      </div>
      <div
        class="wt-countdown-colon"
        :style="{paddingBottom: separator == 'colon' ? '2px' : 0}"
        v-if="showMinutes"
      >
        {{ separator == 'colon' ? ':' : '分' }}
      </div>
      <div class="wt-countdown-item"  v-if="showSeconds">
        <div class="wt-countdown-time">
          {{ s }}
        </div>
      </div>
      <div
        class="wt-countdown-colon"
        :style="{paddingBottom: separator == 'colon' ? '2rpx' : 0}"
        v-if="showSeconds && separator == 'zh'"
      >
        秒
      </div>
    </div>
    <div v-if="!isShowDay" class="wt-countdown" :style="{fontSize:fontSize+'px', color:color}">
      <div class="wt-countdown-item" v-if="showHours">
        <div class="wt-countdown-time">
          {{ Number(d) * 24 + Number(h) }}
        </div>
      </div>
      <div
        class="wt-countdown-colon"
        :style="{paddingBottom: separator == 'colon' ? '2px' : 0}"
        v-if="showHours"
      >
        {{ separator == 'colon' ? ':' : '时' }}
      </div>
      <div class="wt-countdown-item" v-if="showMinutes">
        <div class="wt-countdown-time">
          {{ i }}
        </div>
      </div>
      <div
        class="wt-countdown-colon"
        :style="{paddingBottom: separator == 'colon' ? '2px' : 0}"
        v-if="showMinutes"
      >
        {{ separator == 'colon' ? ':' : '分' }}
      </div>
      <div class="wt-countdown-item"  v-if="showSeconds">
        <div class="wt-countdown-time">
          {{ s }}
        </div>
      </div>
      <div
        class="wt-countdown-colon"
        :style="{paddingBottom: separator == 'colon' ? '2rpx' : 0}"
        v-if="showSeconds && separator == 'zh'"
      >
        秒
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WtCountDown',
  isAutoComponent: true,
  props: {
    // 是否显示天数
    isShowDay: {
      type: Boolean,
      default: true
    },
    // 倒计时的时间，秒为单位
    timestamp: {
      type: [Number, String],
      default: 0
    },
    // 是否自动开始倒计时
    autoplay: {
      type: Boolean,
      default: true
    },
    // 用英文冒号(colon)或者中文(zh)当做分隔符，如："11:22"或"11时22秒"
    separator: {
      type: String,
      default: 'colon'
    },
    // 是否显示秒
    showSeconds: {
      type: Boolean,
      default: true
    },
    // 是否显示分钟
    showMinutes: {
      type: Boolean,
      default: true
    },
    // 是否显示小时
    showHours: {
      type: Boolean,
      default: true
    },
    // 是否显示“天”
    showDays: {
      type: Boolean,
      default: true
    },
    // 当"天"的部分为0时，不显示
    hideZeroDay: {
      type: Boolean,
      default: false
    },
    // 字体
    fontSize: {
      type: [Number, String],
      default: 14
    },
    // 颜色
    color: {
      type: String,
      default: '#333333'
    }
  },
  data() {
    return {
      d: '00', // 天的默认值
      h: '00', // 小时的默认值
      i: '00', // 分钟的默认值
      s: '00', // 秒的默认值
      timer: null, // 定时器
      seconds: 0 // 记录不停倒计过程中变化的秒数
    }
  },
  mounted() {
    // console.log(this.timestamp)
    // 如果自动倒计时
    if (this.autoplay && this.timestamp) {
      this.start()
    } else if (this.timestamp > 0) {
      this.formatTime(Number(this.timestamp))
    }
  },
  methods: {
    // 倒计时
    start() {
      // 避免可能出现的倒计时重叠情况
      this.clearTimer()
      if (this.timestamp <= 0) return
      this.seconds = Number(this.timestamp)
      this.formatTime(this.seconds)
      this.timer = setInterval(() => {
        this.seconds--
        // 发出change事件
        this.$emit('change', this.seconds)
        if (this.seconds < 0) {
          return this.end()
        }
        this.formatTime(this.seconds)
      }, 1000)
    },
    // 格式化时间
    formatTime(seconds) {
      // 小于等于0的话，结束倒计时
      seconds <= 0 && this.end()
      let [day, hour, minute, second] = [0, 0, 0, 0]
      day = Math.floor(seconds / (60 * 60 * 24))
      // 判断是否显示“天”参数，如果不显示，将天部分的值，加入到小时中
      // hour为给后面计算秒和分等用的(基于显示天的前提下计算)
      hour = Math.floor(seconds / (60 * 60)) - day * 24
      // showHour为需要显示的小时
      let showHour = null
      if (this.showDays) {
        showHour = hour
      } else {
        // 如果不显示天数，将“天”部分的时间折算到小时中去
        showHour = Math.floor(seconds / (60 * 60))
      }
      minute = Math.floor(seconds / 60) - hour * 60 - day * 24 * 60
      second = Math.floor(seconds) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
      // 如果小于10，在前面补上一个"0"
      showHour = showHour < 10 ? '0' + showHour : showHour
      minute = minute < 10 ? '0' + minute : minute
      second = second < 10 ? '0' + second : second
      this.d = day
      this.h = showHour
      this.i = minute
      this.s = second
    },
    // 停止倒计时
    end() {
      this.clearTimer()
      this.$emit('end', {})
    },
    // 清除定时器
    clearTimer() {
      if (this.timer) {
        // 清除定时器
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style scoped lang="scss">

  .wt-countdown {
    display: inline-flex;
    align-items: center;
  }

  .wt-countdown-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 6px;
    white-space: nowrap;
    transform: translateZ(0);
  }

  .wt-countdown-time {
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  .wt-countdown-colon {
    display: flex;
    justify-content: center;
    line-height: 1;
    align-items: center;
  }

  .wt-countdown-scale {
    transform: scale(0.9);
    transform-origin: center center;
  }
</style>
