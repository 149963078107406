<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType === 'add'" v-has-any-premission="['ARTICLELIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['ARTICLELIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="15%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="报告名称" prop="name" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input v-if="viewType !== 'view'" v-model="detailForm.name" placeholder="请输入"></el-input>
                <span v-else>{{ detailForm.name }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="封面图" prop="imgId">
                <label slot="label"><span class="red">*</span> 封面图</label>
                <wt-oss-upload
                    :fileList.sync="coverList"
                    public
                    file-path="report/"
                    file-size="150px*200px"
                    list-type="picture" :limit="1" :file-limit="1024 * 200">
                </wt-oss-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否动态" prop="isDynamic" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-radio-group v-model="detailForm.isDynamic" @change="dynamicChange(detailForm.isDynamic)">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="排序" prop="orderNumber">
                <el-input-number v-model="detailForm.orderNumber" :min="0"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="报告摘要" prop="summary" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input type="textarea" v-model="detailForm.summary" placeholder="请输入" rows="5" maxlength="5000" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="主题" prop="themeIdList" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-cascader
                    v-model="detailForm.themeIdList"
                    :options="themeList"
                    @change="handleChange"
                    :props="optionProps"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标签" prop="labelId" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-select v-model="detailForm.labelId" placeholder="请选择标签" class="wt-w-100">
                  <el-option v-for="item in labelChangeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="研报时间" prop="reportTime">
                <el-date-picker
                    v-model="detailForm.reportTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="价格" prop="priceUnit" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input v-model="detailForm.priceUnit" placeholder="请输入" @change="priceChange()" @input="inputLimit(detailForm.priceUnit)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="页数" prop="pageNumber" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input-number v-model="detailForm.pageNumber" @change="priceChange()" :min="0"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="试看页数" prop="freeNumber" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input-number v-model="detailForm.freeNumber" @change="priceChange()" :min="0"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="关联热门词" prop="hotWordId" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-select v-model="detailForm.hotWordId" placeholder="请选择关联热门词" class="wt-w-100">
                  <el-option v-for="item in hotList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="作者" prop="author">
                <el-input type="textarea" v-model="detailForm.author" placeholder="请输入" rows="5" maxlength="1000" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="高级标签" prop="advancedLabel">
                <el-radio-group v-model="detailForm.advancedLabel">
                  <el-radio v-for="item in $wtConst.ADVANCED_TYPE_OPTIONS" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="机构" prop="organizationIdList" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-cascader
                    v-model="detailForm.organizationIdList"
                    :options="orgList"
                    :show-all-levels="false"
                    :props="optionPropsOrg"
                    clearable></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="上传附件" prop="fileId">
                <label slot="label"><span class="red">*</span> 上传附件</label>
                <wt-oss-upload
                    :fileList.sync="attachList"
                    file-path="report/"
                    :accept="'.pdf'"
                    :limit="1">
                </wt-oss-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
export default {
  name: 'reportEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {
        themeIdList: [],
        organizationIdList: []
      },
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        emitPath: false
      },
      optionPropsOrg: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        checkStrictly: true,
        emitPath: false
      },
      coverList: [], // 封面图列表
      attachList: [], // 附件列表
      themeList: [], // 主题tree
      orgList: [], // 机构tree
      labelList: [], // 研报标签
      hotList: [], // 热搜词标签
      dynamicList: [], // 动态标签
      labelChangeList: []
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
    this.listByType1()
    this.listByType3()
    this.listByType5()
    this.listByTypeTheme()
    this.listByTypeOrg()
  },
  created() {
  },
  methods: {
    handleChange(value) {
      console.log(value)
      this.detailForm.themeIdList = [value]
    },
    // 价格变动change事件
    priceChange() {
      if (this.detailForm.priceUnit > 0) {
        if (this.detailForm.pageNumber <= this.detailForm.freeNumber) {
          this.$message({ type: 'error', message: '总页数应大于试看页数' })
        }
      } else {
        if (this.detailForm.pageNumber < this.detailForm.freeNumber) {
          this.$message({ type: 'error', message: '总页数应大于或等于试看页数' })
        }
      }
    },
    // 获取研报标签的数据字典list   type = 1
    listByType1() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: {
          id: 1
        }
      }).then((resp) => {
        that.labelList = resp.data
        that.labelChangeList = that.labelList
      }).catch(() => {
      })
    },
    // 获取热搜词的数据字典list   type = 3
    listByType3() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: {
          id: 3
        }
      }).then((resp) => {
        that.hotList = resp.data
      }).catch(() => {
      })
    },
    // 获取热搜词的数据字典list   type = 3
    listByType5() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: {
          id: 5
        }
      }).then((resp) => {
        that.dynamicList = resp.data
      }).catch(() => {
      })
    },
    // 获取主题tree
    listByTypeTheme() {
      const that = this
      that.$wtRequest({
        url: 'modules/theme/tree',
        method: 'post',
        data: {}
      }).then((resp) => {
        that.themeList = resp.data
      }).catch(() => {
      })
    },
    // 获取机构tree
    listByTypeOrg() {
      const that = this
      that.$wtRequest({
        url: 'modules/organization/tree',
        method: 'post',
        data: {}
      }).then((resp) => {
        that.orgList = resp.data
      }).catch(() => {
      })
    },
    // 是否动态change事件
    dynamicChange(val) {
      if (val === 1) {
        this.labelChangeList = this.dynamicList
      } else {
        this.labelChangeList = this.labelList
      }
    },
    onClickAdd() {
      const that = this
      console.log(that.detailForm)
      if (that.coverList && that.coverList.length < 1) {
        that.$message({ type: 'error', message: '请上传封面图' })
        return
      }
      that.detailForm.imgId = this.coverList[0].name
      if (that.attachList && that.attachList.length < 1) {
        that.$message({ type: 'error', message: '请上传附件' })
        return
      }
      if (that.detailForm.priceUnit > 0) {
        if (that.detailForm.pageNumber <= that.detailForm.freeNumber) {
          that.$message({ type: 'error', message: '总页数应大于试看页数' })
          return
        }
      } else {
        if (that.detailForm.pageNumber < that.detailForm.freeNumber) {
          that.$message({ type: 'error', message: '总页数应大于或等于试看页数' })
          return
        }
      }
      that.detailForm.fileId = this.attachList[0].name
      that.detailForm.fileName = this.attachList[0].label
      that.detailForm.fileSize = this.attachList[0].size
      that.$wtUtil.validateDetailFormsAndConfirm(that, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      if (that.coverList && that.coverList.length < 1) {
        that.$message({ type: 'error', message: '请上传封面图' })
        return
      }
      that.detailForm.imgId = this.coverList[0].name
      if (that.orgList && that.orgList.length < 1) {
        that.$message({ type: 'error', message: '请上传附件' })
        return
      }
      if (that.detailForm.priceUnit > 0) {
        if (that.detailForm.pageNumber <= that.detailForm.freeNumber) {
          that.$message({ type: 'error', message: '总页数应大于试看页数' })
          return
        }
      } else {
        if (that.detailForm.pageNumber < that.detailForm.freeNumber) {
          that.$message({ type: 'error', message: '总页数应大于或等于试看页数' })
          return
        }
      }
      that.detailForm.fileId = this.attachList[0].name
      that.detailForm.fileName = this.attachList[0].label
      that.detailForm.fileSize = this.attachList[0].size
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/report/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm.themeIdList = []
        resp.data.priceUnit = resp.data.price / 100
        that.detailForm = resp.data || {}
        // 主题和机构数据处理
        this.$set(that.detailForm, 'themeIdList', resp.data.reportThemeList[0].themeId)
        console.log(this.detailForm)
        var orgList = []
        resp.data.reportOrganizationList.forEach(item => {
          orgList.push(item.organizationId)
        })
        this.$set(that.detailForm, 'organizationIdList', orgList)
        // 文件处理
        this.coverList.push(
          {
            name: that.detailForm.imgId,
            url: that.detailForm.imgUrl,
            status: 'success'
          }
        )
        this.attachList.push(
          {
            name: that.detailForm.fileId,
            status: 'success',
            label: that.detailForm.fileName,
            size: that.detailForm.fileSize
          }
        )
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.detailForm.price = that.detailForm.priceUnit * 100
      that.detailForm.downloadNumber = 0
      that.detailForm.readNumber = 0
      that.$delete(that.detailForm, 'reportOrganizationList')
      that.$delete(that.detailForm, 'reportThemeList')
      that.$delete(that.detailForm, 'organizationName')
      that.$delete(that.detailForm, 'themeName')
      console.log(that.detailForm)
      that.$wtRequest({
        url: 'modules/report/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.detailForm.price = that.detailForm.priceUnit * 100
      that.detailForm.downloadNumber = 0
      that.detailForm.readNumber = 0
      console.log(that.detailForm)
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/report/add',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    inputLimit(price) {
      this.detailForm.priceUnit = price.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 40%;
}
::v-deep .wt-detail-section-form-center  .el-form-item.tinymce  .el-form-item__content {
  width: 90%;
}
.red {
  color: red;
  .blue {
    color: blue;
  }
}
</style>
