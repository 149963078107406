<template>
<!--  (字典类型：1产品类别 2职级 3药品类型 4药品剂量单位 5药品数量单位 6药品包装单位 7药品服用方式 8药品服用频率 9医生职级 10专家职级 11民族 12职业)-->
    <span>{{value}}</span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'wtDictText',
  isAutoComponent: true,
  props: ['dictCode', 'dictId'],
  data() {
    return {
      dictKey: '' // 字典集合名称
    }
  },
  created() {
    this.$store.dispatch('getDictionaryList', { dataType: this.dictCode })
  },
  computed: {
    ...mapGetters(['dictionary']),
    value() {
      const _dict = this.dictionary[this.dictCode]
      if (_dict === undefined) {
        return ''
      } else {
        const match = _dict.find(item => item.id === this.dictId)
        // console.info(this.dictId)
        return match ? match.title : ''
      }
    }
  }
}
</script>

<style scoped>

</style>
