<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="文章标题" v-model="crud.formData.title" type="input" />
        <wt-crud-form-item title="发布状态" v-model="crud.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS" />
        <wt-crud-form-item title="发布时间" v-model="crud.formData.createTimeRange" type="datetimerange" />
      </template>
      <template slot="toolbar-left">
        <wt-button type="primary" @click="crud.onClickAdd('articleEdit')">新增文章</wt-button>
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="title" label="标题图">
          <template v-slot="scope">
            <el-image v-if="scope.row.imgUrl" class="imageWidth" :src="scope.row.imgUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="文章标题"></el-table-column>
        <el-table-column prop="orderNumber" label="排序"></el-table-column>
        <el-table-column prop="labelName" label="标签"></el-table-column>
        <el-table-column prop="createAccountName" label="发布人"></el-table-column>
        <el-table-column prop="isPublish" label="发布状态" width="120">
          <template v-slot="scope">
            <wt-crud-publish-switch :id="scope.row.id" :value="scope.row.isPublish" :crud="crud" />
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间"></el-table-column>

        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['ARTICLELIST:EDIT']" @click="crud.onClickEdit('articleEdit', scope.row)">编辑</wt-text-button>
            <wt-text-button v-has-any-premission="['ARTICLELIST:DELETE']" @click="crud.onClickDelete(scope.row.id)" type="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <article-edit
            :detail-data="crud.rowData"
            :view-type="crud.action"
            @on-close="crud.close()"
            @on-refresh="crud.requestDataList()"
        ></article-edit>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'articleList',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/article/list',
      requestPublishUrl: '/modules/article/updatePublish',
      requestDeleteUrl: '/modules/article/delete',
      formData: {
        title: '',
        isPublish: '',
        createTimeRange: ''
      },
      page: {
        currentPage: 1, pageSize: 10, sortName: 'createTime', sortOrder: 'desc'
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    onPublishChange(val, row) {
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
