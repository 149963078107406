<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="管理员" v-model="crud.formData.username" type="input"/>
        <wt-crud-form-item title="手机号" v-model="crud.formData.mobile" type="input"/>
        <wt-crud-form-item title="状态" v-model="crud.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS" />
      </template>
      <template slot="toolbar-left">
        <wt-button type="primary" v-has-any-premission="['MANAGERLIST:ADD']" @click="crud.onClickAdd('managerEdit')">+ 新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['MANAGERLIST:DELETE']" @click="crud.onClickMultipleDelete()">
          删除
        </wt-button>
      </template>
      <template #table-column>
        <el-table-column label="序号" type="selection" :selectable="onSelectable"></el-table-column>
        <el-table-column prop="id" label="用户ID"></el-table-column>
        <el-table-column prop="username" label="管理员账号"></el-table-column>
        <el-table-column prop="mobile" label="手机号"></el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><span :class="scope.row.isPublish|FILTER_OpinionReplyStatusClass">{{scope.row.isPublish|FILTER_IsPublishjy}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['MANAGERLIST:EDIT']" @click="crud.onClickEdit('managerEdit', scope.row)">编辑
            </wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <manager-edit :detail-data="crud.rowData" :view-type="crud.action"
                     @on-refresh="crud.requestDataList()" @on-close="crud.close()"/>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'dictList',
  setup() {
    const { crud } = useCrud({
      requestListUrl: 'modules/auth/accountList',
      requestDeleteUrl: 'modules/auth/accountDelete',
      formData: {
        username: '',
        mobile: '',
        isPublish: ''
      }
      // staticFormData: {
      //   isPublish: 1
      // }
    })
    return {
      crud
    }
  },
  mounted() {
    // this.crud.requestDataList()
  },
  methods: {
    onSelectable(row, index) {
      return row.id > 1
    }
  }
}
</script>
