<template>
  <div class="page-container">
    <wt-data-form ref="dataForm" :form.sync="form" request-list-url="/modules/news/list" request-delete-url="/modules/news/delete"
                  request-detail-url="/modules/news/detail">
      <template slot="search">
          <wt-search-form-item title="动态名称" v-model="form.formData.title" type="input" />
          <wt-search-form-item title="副标题" v-model="form.formData.subtitle" type="input" />
          <wt-search-form-item title="动态类型" v-model="form.formData.type" type="select" />
          <wt-search-form-item title="显示发布时间" v-model="form.formData.displayTime" type="daterange" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['NEWS:ADD']" @click="$refs.dataForm.toAdd()">新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['NEWS:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">删除</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
            <el-table-column prop="id" label="主键ID"></el-table-column>
            <el-table-column prop="categoryId" label="动态分类ID"></el-table-column>
            <el-table-column prop="creatorId" label="发布人ID"></el-table-column>
            <el-table-column prop="orgId" label="机构ID"></el-table-column>
            <el-table-column prop="title" label="动态名称"></el-table-column>
            <el-table-column prop="subtitle" label="副标题"></el-table-column>
            <el-table-column prop="cover" label="动态封面"></el-table-column>
            <el-table-column prop="type" label="动态类型 0/默认" sortable></el-table-column>
            <el-table-column prop="displayTime" label="显示发布时间" sortable></el-table-column>
            <el-table-column prop="displayAvator" label="显示发布作者"></el-table-column>
            <el-table-column prop="isSource" label="是否存在原文" sortable></el-table-column>
            <el-table-column prop="sourceUrl" label="原文链接"></el-table-column>
            <el-table-column prop="status" label="审核状态 0/默认" sortable></el-table-column>
            <el-table-column prop="version" label="版本" sortable></el-table-column>
            <el-table-column prop="orderNum" label="排序" sortable></el-table-column>
        <el-table-column prop="isPublish" label="发布状态"><template slot-scope="scope"><span :class="scope.row.isPublish|FILTER_IsPublishClass">{{scope.row.isPublish|FILTER_IsPublish}}</span></template></el-table-column>
        <el-table-column prop="updateTime" label="修改时间" sortable></el-table-column>
            <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
            <el-table-column prop="introduction" label="简介"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['NEWS:VIEW']" @click="$refs.dataForm.toView(scope.row)">查看详情</wt-text-button>
            <wt-text-button v-has-any-premission="['NEWS:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible">
      <ExampleView :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible">
      <ExampleEdit :detailData="form.detailData" :view-type="form.dialogViewType" @on-refresh="$refs.dataForm.requestDataList()" @on-close="form.isEditDialogVisible = false"/></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'exampleList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
    console.log(this.form)
  },
  created() {
  },
  methods: {

  },
  computed: {
    ...mapState({
    })
  }
}
</script>
