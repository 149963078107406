<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="报告标题" v-model="crud.formData.name" type="input" />
        <wt-crud-form-item title="标签" v-model="crud.formData.labelId" type="select" :dataList="labelList" />
        <wt-crud-form-item title="发布状态" v-model="crud.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH__OPTIONS" />
        <wt-crud-form-item title="主题">
          <template>
            <el-cascader
                v-model="crud.formData.themeIdList"
                :options="themeList"
                :props="optionProps"></el-cascader>
          </template>
        </wt-crud-form-item>
        <wt-crud-form-item title="是否动态" v-model="crud.formData.isDynamic" type="select" :dataList="$wtConst.ISDYNAMIC__OPTIONS" />
        <wt-crud-form-item title="机构">
          <template>
            <el-cascader
                v-model="crud.formData.organizationIdList"
                :options="orgList"
                :show-all-levels="false"
                :props="optionPropsOrg"
                collapse-tags
                clearable></el-cascader>
          </template>
        </wt-crud-form-item>
        <wt-crud-form-item title="发布时间" v-model="crud.formData.createTimeRange" type="datetimerange" />
      </template>
      <template slot="toolbar-left">
        <wt-button type="primary" @click="crud.onClickAdd('reportEdit')">新增报告</wt-button>
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="title" label="封面图">
          <template v-slot="scope">
            <el-image class="imageWidth" :src="scope.row.imgUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="报告标题"></el-table-column>
        <el-table-column prop="labelName" label="标签"></el-table-column>
        <el-table-column prop="themeName" label="主题"></el-table-column>
        <el-table-column prop="organizationName" label="机构"></el-table-column>
        <el-table-column prop="pageNumber" label="页数"></el-table-column>
        <el-table-column prop="orderNumber" label="排序"></el-table-column>
        <el-table-column prop="isFirstPageShow" label="首页展示">
          <template v-slot="scope">
            <span>{{ scope.row.isFirstPageShow | FILTER_YesOrNo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isDynamic" label="是否动态">
          <template v-slot="scope">
            <span>{{ scope.row.isDynamic | FILTER_YesOrNo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createAccountName" label="发布人"></el-table-column>
        <el-table-column prop="isPublish" label="发布状态" width="120">
          <template v-slot="scope">
            <wt-crud-publish-switch :id="scope.row.id" :value="scope.row.isPublish" :crud="crud" />
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间"></el-table-column>

        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['REPORTCLASSIFY:VIEW']" @click="crud.onClickView('reportView', scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['REPORTCLASSIFY:EDIT']" @click="crud.onClickEdit('reportEdit', scope.row)">编辑</wt-text-button>
            <wt-text-button v-has-any-premission="['REPORTCLASSIFY:DELETE']" @click="crud.onClickDelete(scope.row.id)" type="danger">删除</wt-text-button>
            <wt-text-button v-if="scope.row.isFirstPageShow === 0 && scope.row.isDynamic !== 1" v-has-any-premission="['ARTICLELIST:EDIT']" @click="onClickHome(1, scope.row)">设为首页</wt-text-button>
            <wt-text-button v-if="scope.row.isFirstPageShow === 1 && scope.row.isDynamic !== 1" v-has-any-premission="['ARTICLELIST:EDIT']" @click="onClickHome(0, scope.row)">取消首页</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <report-edit
            v-if="crud.view === 'reportEdit'"
            :detail-data="crud.rowData"
            :view-type="crud.action"
            @on-close="crud.close()"
            @on-refresh="crud.requestDataList()"
        ></report-edit>
        <report-view
            v-if="crud.view === 'reportView'"
            :detail-data="crud.rowData"
            :view-type="crud.action"
            @on-close="crud.close()"
            @on-refresh="crud.requestDataList()"
        ></report-view>
      </template>
    </wt-crud>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'reportClassify',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/report/list',
      requestPublishUrl: '/modules/report/updatePublish',
      requestDeleteUrl: '/modules/report/delete',
      publishSuccessText: '操作成功',
      formData: {
        name: '',
        isPublish: '',
        isDynamic: '',
        labelId: '',
        organizationIdList: '',
        themeIdList: '',
        createTimeRange: ''
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
      labelList: [], // 标签
      themeList: [], // 主题tree
      orgList: [], // 机构tree
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'children'
      },
      optionPropsOrg: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        checkStrictly: true,
        emitPath: false
      }
    }
  },
  mounted() {
    this.listByType1()
    this.listByTypeTheme()
    this.listByTypeOrg()
  },
  created() {
  },
  methods: {
    // 获取研报标签的数据字典list   type = 1
    listByType1() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: {
          id: 1
        }
      }).then((resp) => {
        that.labelList = resp.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
      }).catch(() => {
      })
    },
    // 获取主题tree
    listByTypeTheme() {
      const that = this
      that.$wtRequest({
        url: 'modules/theme/tree',
        method: 'post',
        data: {}
      }).then((resp) => {
        that.themeList = resp.data
      }).catch(() => {
      })
    },
    // 获取机构tree
    listByTypeOrg() {
      const that = this
      that.$wtRequest({
        url: 'modules/organization/tree',
        method: 'post',
        data: {}
      }).then((resp) => {
        that.orgList = resp.data
      }).catch(() => {
      })
    },
    onClickHome(val, row) {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/report/updateFirstPageShow',
        method: 'post',
        data: {
          id: row.id
        }
      }).then((resp) => {
        that.isFromLoading = false
        that.$message({ type: 'success', message: '操作成功' })
        that.crud.requestDataList()
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-cascader {
  line-height: 1;
}
::v-deep .el-cascader .el-input__inner {
  height: 28px;
  line-height: 28px;
}
::v-deep .el-cascader .el-input__icon {
  line-height: 28px;
}
</style>
