<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="课程名称" v-model="crud.formData.courseName" type="input" />
        <wt-crud-form-item title="手机号" v-model="crud.formData.userPhone" type="input" />
        <wt-crud-form-item title="评论状态" v-model="crud.formData.status" type="select" :dataList="$wtConst.COMMENT_STATUS" />
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="courseName" label="课程名称"></el-table-column>
        <el-table-column prop="userName" label="评论人"></el-table-column>
        <el-table-column prop="userPhone" label="手机号"></el-table-column>
        <el-table-column prop="status" label="评论状态" width="120">
          <template v-slot="scope">
            {{ scope.row.status | FILTER_CommentStatus }}
          </template>
        </el-table-column>
        <el-table-column prop="isPublish" label="状态" width="120">
          <template v-slot="scope">
            {{ scope.row.isPublish === 1 ? '启用' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="评论时间"></el-table-column>
        <el-table-column prop="content" label="评论内容"></el-table-column>

        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button @click="updatePublish(scope.row)">{{ scope.row.isPublish === 1 ? '禁用' : '启用' }}</wt-text-button>
            <wt-text-button @click="showAudit(scope.row)">审核</wt-text-button>
            <wt-text-button @click="crud.onClickDelete(scope.row.id)">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
      <template #view>
        <report-edit
          v-if="crud.view === 'reportEdit'"
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></report-edit>
        <report-view
          v-if="crud.view === 'reportView'"
          :detail-data="crud.rowData"
          :view-type="crud.action"
          @on-close="crud.close()"
          @on-refresh="crud.requestDataList()"
        ></report-view>
      </template>
    </wt-crud>
    <el-dialog title="评论审核" :visible.sync="visibleAudit" :destroy-on-close="true" append-to-body width="40%">
      <div style="margin-bottom: 32px;">{{ comment.content }}</div>
      <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="18%">
        <el-form-item label="审核结果" prop="status" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-select v-model="detailForm.status" placeholder="请选择审核结果" class="wt-w-100">
            <el-option v-for="item in [{ value: 1, label: '审核通过' }, { value: 2, label: '审核驳回' }]" :label="item.label" :value="item.value" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用" prop="isPublish" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <el-select v-model="detailForm.isPublish" placeholder="请选择是否启用" class="wt-w-100">
            <el-option v-for="item in [{ value: 0, label: '禁用' }, { value: 1, label: '启用' }]" :label="item.label" :value="item.value" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="mini" @click="visibleAudit = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="confirmAudit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'commentManage',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/courseEvaluation/list',
      requestDeleteUrl: '/modules/courseEvaluation/delete',
      formData: {
        courseName: '', // 课程名称
        status: '', // 审核状态 0待审核 1审核通过 2审核拒绝
        userPhone: '' // 用户手机号
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
      comment: {},
      visibleAudit: false,
      detailForm: {
        id: '',
        status: '',
        isPublish: ''
      }
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    showAudit(row) {
      this.comment = row
      this.visibleAudit = true
    },
    updatePublish(row) {
      const that = this
      that.$wtRequest({
        url: '/modules/courseEvaluation/updatePublish',
        method: 'post',
        data: { id: row.id }
      }).then((resp) => {
        that.crud.requestDataList()
      }).catch(() => {})
    },
    confirmAudit() {
      const that = this
      that.$wtUtil.validateDetailFormsAndConfirm(that, '是否提交?').then(() => {
        that.detailForm.id = that.comment.id
        that.$wtRequest({
          url: '/modules/courseEvaluation/audit',
          method: 'post',
          data: that.detailForm
        }).then((resp) => {
          that.visibleAudit = false
          that.detailForm.status = ''
          that.detailForm.isPublish = ''
          that.crud.requestDataList()
        }).catch(() => {})
      }).catch(() => {})
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-cascader {
  line-height: 1;
}
::v-deep .el-cascader .el-input__inner {
  height: 28px;
  line-height: 28px;
}
::v-deep .el-cascader .el-input__icon {
  line-height: 28px;
}
</style>
