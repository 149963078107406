<template>
  <el-table class="wt-table" cell-class-name="wt-cell" @sort-change="sortChange"
  row-class-name="wt-row" row-key="id" header-cell-class-name="wt-header-cell" header-row-class-name="wt-header-row"
            :data="dataList" @selection-change="handleSelectionChange"
            v-loading="isTableLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)"
            :height="height" :size="size ? size : 'small'" v-bind="form.table ? form.table : {}" >
    <slot></slot>
  </el-table>
</template>

<script>
export default {
  name: 'WtFormTable',
  isAutoComponent: true,
  props: ['form', 'height', 'isTableLoading', 'size'],
  data() {
    return {
    }
  },
  methods: {
    // 当表格的排序条件发生变化的时候会触发该事件
    sortChange(params) {
      this.$emit('sort-change', params)
    },
    handleSelectionChange(val) {
      this.$emit('selection-change', val)
    }
  },
  computed: {
    dataList() {
      if (this.form == null || this.form.formResult == null) {
        return []
      }
      // console.log(this.form)
      return this.form.formResult.list
    }
  }
}
</script>

<style>
</style>
