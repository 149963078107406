<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType === 'add'" v-has-any-premission="['ARTICLELIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['ARTICLELIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="10%">
          <el-form-item label="文章标题" prop="title" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.title" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="标题图" prop="imgId">
            <label slot="label"><span class="red">*</span> 标题图</label>
            <wt-oss-upload
              :fileList.sync="coverList"
              public
              file-path="article/"
              list-type="picture" :limit="1" :file-limit="1024 * 200">
            </wt-oss-upload>
          </el-form-item>
          <el-form-item label="排序" prop="orderNumber">
            <el-input-number v-model="detailForm.orderNumber" :min="0"></el-input-number>
          </el-form-item>
          <el-form-item label="文章标签" prop="labelId" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-select v-model="detailForm.labelId" placeholder="请选择标签" class="wt-w-100">
              <el-option v-for="item in labelList" :label="item.name" :value="item.id" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文章摘要" prop="summary" :rules="[{ required: true, message: '内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input type="textarea" v-model="detailForm.summary" placeholder="请输入" rows="3" maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="文章内容：" prop="content" class="tinymce">
            <WtTinymce v-model="detailForm.content"></WtTinymce>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
export default {
  name: 'articleEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {
      },
      labelList: [],
      coverList: [] // 封面图列表
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
    this.listByType()
  },
  created() {
  },
  methods: {
    // 获取文章标签的数据字典list
    listByType() {
      const that = this
      that.$wtRequest({
        url: 'modules/dictionary/listByType',
        method: 'post',
        data: {
          id: 4
        }
      }).then((resp) => {
        that.labelList = resp.data
      }).catch(() => {
      })
    },
    onClickAdd() {
      const that = this
      if (that.coverList && that.coverList.length < 1) {
        that.$message({ type: 'error', message: '请上传标题图' })
        return
      }
      that.detailForm.imgId = this.coverList[0].name
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      if (that.coverList && that.coverList.length < 1) {
        that.$message({ type: 'error', message: '请上传标题图' })
        return
      }
      that.detailForm.imgId = this.coverList[0].name
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/article/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        // 文件处理
        that.detailForm = resp.data || {}
        this.coverList.push(
          {
            name: that.detailForm.imgId,
            url: that.detailForm.imgUrl,
            status: 'success'
          }
        )
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/article/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/article/add',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 40%;
}
::v-deep .wt-detail-section-form-center  .el-form-item.tinymce  .el-form-item__content {
  width: 90%;
}
</style>
