<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template slot="search">
        <wt-crud-form-item title="字典名称" v-model="crud.formData.name" type="input" />
        <wt-crud-form-item title="字典类型" v-model="crud.formData.type" type="select" :dataList="$wtConst.DICT_TYPE_OPTIONS" />
        <wt-crud-form-item title="状态" v-model="crud.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS" />
      </template>
      <template slot="toolbar-left">
        <wt-button type="primary" @click="onClickAdd('add')">新增字典</wt-button>
      </template>
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="字典名称"></el-table-column>
        <el-table-column prop="orderNumber" label="排序"></el-table-column>
        <el-table-column prop="type" label="字典类型">
          <template v-slot="scope">
            <span>{{ scope.row.type | FILTER_DictType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createAccountName" label="创建人"></el-table-column>
        <el-table-column prop="isPublish" label="状态" width="120">
          <template v-slot="scope">
            <wt-crud-publish-switch :id="scope.row.id" :value="scope.row.isPublish" :crud="crud" />
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>

        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['DICTLIST:EDIT']" @click="onClickAdd('edit', scope.row)">编辑</wt-text-button>
            <wt-text-button v-has-any-premission="['DICTLIST:DELETE']" @click="crud.onClickDelete(scope.row.id)" type="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-crud>
    <!--新增编辑-->
    <div class="wt-dialog">
      <el-dialog :title="viewType === 'add'?'新增字典':'编辑字典'" :visible.sync="isEditDialogVisible" width="40%" :close-on-press-escape="false"
                 :close-on-click-modal="false" :modal-append-to-body="false" lock-scroll>
        <el-form :model="detailForm" ref="detailForm" size="small" class="wt-detail-section-form-center" label-width="40%">
          <el-form-item label="字典类型" prop="type" :rules="[{ required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-select v-model="detailForm.type" placeholder="请选择角色">
              <el-option v-for="item in $wtConst.DICT_TYPE_OPTIONS" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字典名称" prop="name" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="orderNumber" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input-number v-model="detailForm.orderNumber" :min="0"></el-input-number>
          </el-form-item>
          <el-form-item label="是否启用：" prop="isPublish">
            <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0"
                       active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
          </el-form-item>
        </el-form>
        <template slot="footer">
          <wt-button type="info"
                     @click="closeCard()">取消</wt-button>
          <wt-button type="primary"
                     @click="saveDict()">提交</wt-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'dictList',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/dictionary/list',
      requestPublishUrl: '/modules/dictionary/updatePublish',
      requestDeleteUrl: '/modules/dictionary/delete',
      formData: {
        name: '',
        createTimeRange: ''
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
      isEditDialogVisible: false,
      viewType: '',
      detailForm: {
        type: '',
        name: '',
        orderNumber: '',
        isPublish: 0
      }
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    onClickAdd(type, row) {
      this.viewType = type
      this.isEditDialogVisible = true
      if (type === 'add') {
        this.detailForm = {
          type: '',
          name: '',
          orderNumber: '',
          isPublish: 0

        }
      } else {
        const that = this
        that.isFromLoading = true
        that.$wtRequest({
          url: 'modules/dictionary/detail',
          method: 'post',
          data: {
            id: row.id
          }
        }).then((resp) => {
          that.isFromLoading = false
          that.detailForm = resp.data
        }).catch(() => {
          that.isFromLoading = false
        })
        this.detailForm = row
      }
      if (this.$refs['detailForm'] !== undefined && type === 'add') {
        this.$nextTick(() => {
          this.$refs['detailForm'].clearValidate()
        })
      }
    },
    // 提交
    saveDict() {
      if (this.viewType === 'add') {
        var dictUrl = 'modules/dictionary/add'
      } else {
        dictUrl = 'modules/dictionary/update'
      }
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: dictUrl,
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isFromLoading = false
        this.isEditDialogVisible = false
        that.$message({ type: 'success', message: '保存成功' })
        that.crud.requestDataList()
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    // 取消
    closeCard() {
      this.isEditDialogVisible = false
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-input-number {
  width: 100% !important;
}
</style>
