<template>
  <div>
    <template v-if="!readonly">
      <div>
        <input type="file" v-show="false" :accept="wtFileAccept" :multiple="multiple" ref="input" @change="onFilePicker">
<!--        <el-button size="small" type="primary" @click="$refs.input.click()">-->
<!--          选择文件-->
<!--        </el-button>-->
        <i class="el-icon-plus" @click="$refs.input.click()"></i>
        <el-button size="small" type="success" @click="onStartUpload" v-if="!isAutoUpload">
          开始上传
        </el-button>
      </div>
      <div class="wt-file-tip">{{wtTipText}}</div>
    </template>
    <div :class="{'wt-list-files': !readonly}">

      <template v-if="fileType === 'image'">
        <div class="wt-list-image" v-for="(item) in fileList">
          <div class="wt-image">
            <div class="wt-image-cover" v-if="item.state == 'success'" :ref="'res_' + item.fileId" :style="{backgroundImage: 'url(' + wtBaseFileServer + '/' + item.fileUrl + ')'}" />
          </div>
          <div class="wt-mask-panl"></div>
          <template v-if="!readonly">
            <span class="wt-state wt-state-text" v-show="item.state == 'uploading'">{{item.percent}}</span>
            <span class="wt-state wt-state-text">{{item.state | stateText}}</span>
            <span class="wt-state wt-state-icon" @click="onDeleteFileItem(item)"><i class="el-icon-close"></i></span>
            <div class="processbar-container" v-show="item.state == 'uploading'">
              <div class="processbar" :style="{width: item.percent}"></div>
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="fileType === 'file'">
        <div class="wt-list-file" v-for="(item, index) in fileList" :key="index">
          <div class="wt-file">
            <div class="wt-file-title">{{item.name}}</div>
          </div>
          <template v-if="!readonly">
            <span class="wt-state wt-state-text" v-show="item.state == 'uploading'">{{item.percent}}</span>
            <span class="wt-state wt-state-text">{{item.state | stateText}}</span>
            <span class="wt-state wt-state-icon" @click="onDeleteFileItem(item)"><i class="el-icon-close"></i></span>
            <div class="processbar-container" v-show="item.state == 'uploading'">
              <div class="processbar" :style="{width: item.percent}"></div>
            </div>
          </template>
          <template v-else>
            <span class="wt-file-preview" @click="onPreviewFile(item)">查看</span>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="wt-list-file" v-for="(item, index) in fileList" :key="index">
          <el-popover trigger="click" placement="right"
                      :title="item.name" @show="onPreviewPopoverShow(item, index)" @hide="onPreviewPopoverHide(item, index)">
            <span v-if="item.state !== 'success'">上传完成后可预览</span>
            <div v-if="item.state === 'success'">
              <img v-if="fileType === 'image'" :ref="'res_' + item.fileId" :src="wtBaseFileServer + '/' + item.fileUrl" style="width: 400px;" />
            </div>
            <div slot="reference" class="wt-title">{{item.name}}</div>
          </el-popover>
          <template v-if="!readonly">
            <span class="wt-state wt-state-text" v-show="item.state === 'uploading'">{{item.percent}}</span>
            <span class="wt-state wt-state-text">{{item.state | stateText}}</span>
            <span class="wt-state wt-state-icon" @click="onDeleteFileItem(item)"><i class="el-icon-close"></i></span>
            <div class="processbar-container" v-show="item.state === 'uploading'">
              <div class="processbar" :style="{width: item.percent}"></div>
            </div>
          </template>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter'
import { valueEquals } from 'element-ui/src/utils/util'
import request from '@/utils/request'
import SparkMD5 from 'spark-md5'
import wtUploadRequest from '@/utils/wtUploadRequest'

export default {
  name: 'wt-upload',
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default() { return [] }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    fileSize: {
      type: Number,
      default: null
    },
    fileAccept: {
      type: String,
      default: 'image/*'
    },
    fileType: {
      type: String,
      default: 'image'
    },
    tipText: {
      type: String,
      default: ''
    },
    isAutoUpload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      uploader: null,
      fileList: [],
      wtFileAccept: this.fileAccept,
      wtTipText: this.tipText,
      wtBaseApi: window.wtConst.BASE_API,
      wtBaseFileServer: window.wtConst.FILE_SERVER_URL
    }
  },
  watch: {
    value(val, oldVal) {
      if (!oldVal || oldVal.length <= 0) {
        if (val) {
          val = val.filter(item => {
            item.state = 'success'
            item.fileId = this.$wtUtil.uuid()
            return true
          })
        }
      }
      this.fileList = val || []
      if (!valueEquals(val, oldVal)) {
        this.dispatch('ElFormItem', 'el.form.change', val)
      }
    }
  },
  activated() {
  },
  created() {
    // const that = this
    this.fileList = this.value.filter(item => {
      item.state = 'success'
      item.fileId = this.$wtUtil.uuid()
      return true
    })
    if (this.fileType === 'audio') {
      this.wtFileAccept = 'audio/*'
    } else if (this.fileType === 'image') {
      this.wtFileAccept = 'image/*'
    }
    if (!this.wtTipText) {
      if (this.limit) {
        this.wtTipText += `最多可以上传${this.limit}个文件 `
      }
      if (this.fileSize) {
        this.wtTipText += `单文件最大${this.$wtUtil.covertByteToString(this.fileSize)} `
      }
      if (this.fileAccept) {
        this.wtTipText += `支持文件格式：${this.fileAccept}`
      }
    }
  },
  methods: {
    onFilePicker() {
      if (!event.target.files || event.target.files.length <= 0) {
        this.$refs.input.value = null
        return false
      }
      //  检查文件数量
      if (this.limit && this.limit < this.fileList.length + event.target.files.length) {
        this.$message.warning(`最多可以上传${this.limit}个文件`)
        this.$refs.input.value = null
        return false
      }
      //  检查格式 使用input的accept
      //  使用 fileAccept
      //  检查单文件大小
      for (let i = 0; i < event.target.files.length; i++) {
        const size = event.target.files[i].size
        if (size > this.fileSize) {
          this.$message.warning(`单文件最大大小为${this.$wtUtil.covertByteToString(this.fileSize)}`)
          this.$refs.input.value = null
          return false
        }
      }
      //  添加文件
      for (let i = 0; i < event.target.files.length; i++) {
        const fileItem = event.target.files[i]
        fileItem.fileId = this.$wtUtil.uuid()
        this.fileList.push({ file: fileItem, name: fileItem.name, url: null, state: 'init', percent: '0%', fileId: fileItem.fileId })
        this.onStartUploadFile(fileItem)
      }
      this.$refs.input.value = null
    },
    onStartUploadFile(file) {
      const that = this
      this.handleBeforeUpload(file).then((resp) => {
        if (resp.code === '0' && resp.data != null && resp.data.fileUrl != null && resp.data.fileUrl !== undefined) {
          that.updateFileState(file.fileId, 'success', { 'fileUrl': resp.data.fileUrl, 'uuid': resp.data.uuid })
        } else if (resp.code === '0' && that.isAutoUpload) {
          that.onStartUpload()
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    onStartUpload() {
      if (!this.fileList) {
        return
      }
      const files = []
      this.fileList.forEach((item) => {
        if (item.state !== 'success') {
          files.push(item)
        }
      })
      if (files.length <= 0) {
        return
      }
      const that = this
      let promise = Promise.resolve()
      files.forEach((item) => {
        const file = item.file
        promise = promise.then(() => {
          return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('file', file, file.name)
            wtUploadRequest.post('/modules/authFile/uploadFile', formData).then((resp) => {
              if (resp.code === '0' && resp.data) {
                that.fileList.forEach(sourceFile => {
                  if (sourceFile.file && sourceFile.file.fileMd5 && sourceFile.file.fileMd5 === file.fileMd5) {
                    sourceFile.uuid = resp.data.uuid
                    sourceFile.fileUrl = resp.data.fileUrl
                    sourceFile.fileName = file.name
                    that.updateFileState(file.fileId, 'success', { 'fileUrl': resp.data.fileUrl, 'uuid': resp.data.uuid })
                  }
                })
              }
              resolve(files)
            }).catch(() => {
              resolve(files)
            })
          })
        })
      })
      promise.then((data) => {
        that.isLoading = false
      }).catch(() => {
        this.isLoading = false
        that.$message.warning('上传失败')
      })
    },
    onDeleteFileItem(fileItem) {
      if (fileItem && fileItem.fileId) {
        this.fileList = this.fileList.filter((item) => item.fileId !== fileItem.fileId)
        const array = this.fileList.filter((item) => item.state === 'success')
        this.$emit('update:value', array)
      }
    },
    handleBeforeUpload(file) {
      const fileSize = file.size / 1024.0 / 1024.0
      if (this.wtSizeLimit < fileSize) {
        this.$message.warning('超过上传文件大小限制')
        return false
      }
      const that = this
      return new Promise((resolve, reject) => {
        that.getFileMd5(file, (file, result) => {
          //  获取文件md5成功，检查文件在云端是否已上传
          if (result.resultCode === 'SUCCESS') {
            file.fileMd5 = result.fileMd5
            that.requestCheckFile(result.fileMd5, resolve, reject)
          } else {
            reject(new Error(''))
          }
        })
      })
    },
    getFileMd5(file, callback) {
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunkSize = 1024 * 1024 * 2
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          const fileMd5 = spark.end()
          callback(file, { resultCode: 'SUCCESS', fileMd5: fileMd5 })
        }
      }

      fileReader.onerror = function(err) {
        callback(file, { resultCode: 'FAIL', msg: err })
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    },
    //  网络获取文件是否已上传，如果已经上传，直接使用
    requestCheckFile(fileMd5, resolve, reject) {
      request({
        url: '/modules/authFile/checkFile',
        method: 'post',
        data: { fileMd5: fileMd5 }
      }).then(resp => {
        if (resp.code === '0') {
          resolve(resp)
        } else {
          this.$message.warning('检查文件失败,请稍候再试')
          reject()
        }
      }).catch(() => {
        reject()
      })
    },
    onPreviewPopoverShow(item, index) {
    },
    onPreviewPopoverHide(item, index) {
    },
    updateFileState(fileId, state, dict) {
      dict = dict || {}
      this.fileList.forEach((item, index) => {
        if (item.fileId === fileId) {
          item = Object.assign(item, { state: state }, dict)
          this.$set(this.fileList, index, item)
        }
      })
      if (state === 'success') {
        const tempFileList = this.fileList.filter((item) => item.state === 'success')
        this.$emit('update:value', tempFileList)
      }
    },
    updateFilePercent(fileId, percent) {
      this.fileList.forEach((item, index) => {
        if (item.fileId === fileId) {
          item.percent = percent
          this.$set(this.fileList, index, item)
        }
      })
    },
    wtImageCompleteUploadFiles(files) {
      const fileList = []
      const _this = this
      files.forEach((file) => {
        const item = {}
        item.name = file.name
        if (file && file.fileUrl) {
          item.fileUrl = _this.wtBaseFileServer + '/' + file.fileUrl
        }
        fileList.push(item)
      })
      this.wtImageCallback(fileList)
    },
    onPreviewFile(item) {
      const url = this.wtBaseFileServer + '/' + item.fileUrl
      window.open(url, '_blank')
    }
  },
  filters: {
    stateText(state) {
      if (state === 'init') {
        return '待上传'
      } else if (state === 'uploading') {
        return '上传中'
      } else if (state === 'success') {
        return '已上传'
      } else if (state === 'fail') {
        return '上传失败'
      }
      return '上传失败'
    }
  },
  computed: {
    uploadClass() {
      if (this.readonly) {
        return 'wt-image-limit-active'
      }
      return (this.value.length >= this.wtLimit ? 'wt-image-limit-active' : '')
    }
  }
}
</script>

<style scoped>
.wt-list-files{margin: 8px 0;}
.wt-list-file{color: #ffffff;position: relative;display: block;font-size: 13px;}
.wt-file-tip{font-size: 12px;color: #ffffff;margin-top: 7px;    line-height: 14px; opacity: 0.7}
.wt-list-file .wt-title{cursor: pointer;margin-right: 70px;padding-bottom: 6px;}
.wt-list-image{display: inline-block;position: relative;color: #FFFFFF;background-color: #E0E0E0;margin-right: 4px;}
.wt-list-image .wt-image{cursor: pointer;width: 120px;height: 120px;overflow: hidden;}
.wt-image .wt-image-cover{height: 100%;width: 100%;background-size: contain;background-position: center;background-repeat: no-repeat;}

.wt-list-file .wt-video{width: 400px;}
.wt-list-file .processbar-container{width: 100%;height: 2px;position: absolute;bottom: 0;background-color: #F0F0F0;overflow: hidden;}
.wt-list-file .processbar-container .processbar{width: 10%;background-color: rgb(30, 106, 188);height: 100%;}
.wt-list-file:hover{background-color: #094A59;}
.wt-mask-panl{width: 120px;height: 30px;background-color: #33000000;left: 0;top: 0;position: absolute;background-color: #00000066;}
.wt-state{float: right;margin: 0 8px;position: absolute;top: 0;right: 0;}
.wt-state-icon{display: none;}
.wt-list-file:hover .wt-state-text, .wt-list-image:hover .wt-state-text, .wt-mask-panl{display: none;}
.wt-list-file:hover .wt-state-icon, .wt-list-image:hover .wt-state-icon, .wt-mask-panl{display: block;cursor: pointer;}
.wt-list-file video,audio{outline: none;}
.wt-list-file .wt-file{line-height: 32px}
.wt-file .wt-file-title{padding-left: 12px;padding-right: 100px;line-height: 20px;padding-bottom: 6px;padding-top: 6px;}
.wt-file-preview{float: right;margin: 0 8px;position: absolute;top: 0;right: 0;cursor: pointer;display: none;}
.wt-list-file:hover .wt-file-preview{display: block;}
.el-icon-plus {
  font-size: 26px;
  color: #fff;
  border: 1px dashed rgba(0, 255, 255, 0.8470588235);
  padding: 5px;
  cursor: pointer;
}
</style>
