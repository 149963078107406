import wtUploadRequest from '@/utils/wtUploadRequest'
import wtUtil from '@/utils/wtUtil'
export const uploadHelper = ({ md5File, chunkSize, onUploadStart, onUploadProgress, onUploadSuccess, onUploadFail }) => {
  const isDebug = false
  let uploadIndex = 0
  let isEnable = true
  let chunkArray = []
  let totalUpload = 0
  const logInfo = (...args) => {
    if (isDebug) {
      console.log(...args)
    }
  }
  const getChunkInfo = (file, currentChunk, chunkSize) => {
    const start = currentChunk * chunkSize
    const end = Math.min(file.size, start + chunkSize)
    const chunk = file.slice(start, end)
    const total = Math.ceil(file.size / chunkSize)
    return { start, end, chunk, total }
  }
  const uploadNext = () => {
    if (!isEnable) {
      logInfo('上传已停止')
      md5File.state = 'FAIL'
      md5File.percent = 0
      onUploadFail(md5File)
      return
    }
    if (uploadIndex >= chunkArray.length) {
      logInfo('上传已完成')
      totalUpload = md5File.size
      md5File.state = 'SUCCESS'
      md5File.percent = 1.0
      onUploadSuccess(md5File)
      return
    }
    const chunkIndex = parseInt(chunkArray[uploadIndex])
    const chunkInfo = getChunkInfo(md5File, chunkIndex, chunkSize)
    requestFileUpload(md5File, chunkInfo, chunkIndex).then(() => {
      uploadIndex++
      uploadNext()
    }).catch(() => {
      onUploadFail(md5File)
    })
  }
  const requestFileUpload = (md5File, chunkInfo, index) => {
    logInfo('开始上传' + index)
    return wtUtil.calculationFileMd5(chunkInfo.chunk).then((chunkFile) => {
      const formData = new FormData()
      formData.append('md5', md5File.md5)
      formData.append('total', chunkInfo.total)
      formData.append('index', parseInt(index))
      formData.append('chunkMd5', chunkFile.md5)
      formData.append('file', chunkInfo.chunk)
      return wtUploadRequest.post('/modules/fileChunks/v2/upload', formData, {
        onUploadProgress: (event) => {
          logInfo('onUploadProgress', event)
          md5File.state = 'UPLOADING'
          md5File.percent = currentPercent()
          onUploadProgress(md5File)
        }
      }).then((resp) => {
        totalUpload += chunkInfo.chunk.size
        logInfo('当前进度: ', currentPercent(), resp)
      }).catch((err) => {
        throw new Error('上传失败: ' + err)
      })
    })
  }
  const currentPercent = () => {
    return Math.min(1.0, totalUpload * 1.0 / md5File.size)
  }
  const start = (uploadMap) => {
    logInfo('开始上传')
    const chunkCount = Math.ceil(md5File.size / chunkSize)
    chunkArray = []
    for (let i = 0; i < chunkCount; i++) {
      if (uploadMap[i + ''] === null || uploadMap[i + ''] === undefined) {
        chunkArray.push(i)
      } else {
        totalUpload += chunkSize
      }
    }
    md5File.state = 'UPLOADING'
    md5File.percent = currentPercent()
    onUploadStart(md5File)
    uploadNext()
  }
  const cancelUpload = () => {
    isEnable = false
  }
  return {
    cancelUpload: cancelUpload,
    start: start
  }
}

export default uploadHelper
