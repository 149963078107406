import Vue from 'vue'
import Router from 'vue-router'
import WtLayout from '@/components/WtLayout/WtLayout'
// console.log(mapState.state.auth.userInfo.organizationId)
// import WtContainer from '@/components/WtLayout/WtContainer'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
// type值权限：1平台，2机构，3平台+机构
const staticRoutes = [{
  hidden: true,
  path: '/dashboard',
  name: 'dashboard',
  component: WtLayout,
  meta: { title: '首页', icon: '' },
  children: [{
    path: '',
    name: 'dashboardIndex',
    component: () =>
      import ('@/modules/dashboard/index.vue'),
    meta: { title: '首页', icon: '' }
  }]
},
{
  path: '/memberManage',
  name: 'memberManage',
  component: WtLayout,
  meta: { title: '会员管理', icon: '', level: 1 },
  props: { type: 3 },
  children: [
    {
      path: '/memberList',
      name: 'memberList',
      component: () => import ('@/modules/memberManage/memberUser/memberList.vue'),
      meta: { title: '会员用户管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/cardTypeList',
      name: 'cardTypeList',
      component: () => import ('@/modules/memberManage/cardType/cardTypeList.vue'),
      meta: { title: '会员卡类型管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/rightInterestsList',
      name: 'rightInterestsList',
      component: () => import ('@/modules/memberManage/rightInterests/rightInterestsList.vue'),
      meta: { title: '会员权益管理', icon: '' },
      props: { type: 3 }
    }
  ]
},
{
  path: '/articleVideo',
  name: 'articleVideo',
  component: WtLayout,
  meta: { title: '文章管理', icon: '', level: 1 },
  props: { type: 3 },
  children: [
    {
      path: '/articleList',
      name: 'articleList',
      component: () => import ('@/modules/articleVideo/articleManage/articleList.vue'),
      meta: { title: '文章管理', icon: '' },
      props: { type: 3 }
    }
  ]
},
{
  path: '/reportManage',
  name: 'reportManage',
  component: WtLayout,
  meta: { title: '研报管理', icon: '', level: 1 },
  props: { type: 3 },
  children: [
    {
      path: '/orgClassify',
      name: 'orgClassify',
      component: () => import ('@/modules/researchReport/orgClassify.vue'),
      meta: { title: '机构分类', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/themeClassify',
      name: 'themeClassify',
      component: () => import ('@/modules/researchReport/themeClassify.vue'),
      meta: { title: '主题分类', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/reportClassify',
      name: 'reportClassify',
      component: () => import ('@/modules/researchReport/reportClassify.vue'),
      meta: { title: '报告管理', icon: '' },
      props: { type: 3 }
    }
  ]
},
{
  path: '/course',
  name: 'course',
  component: WtLayout,
  meta: { title: '课程管理', icon: '', level: 1 },
  props: { type: 3 },
  children: [
    {
      path: '/courseManage',
      name: 'courseManage',
      component: () => import ('@/modules/course/courseManage/index.vue'),
      meta: { title: '课程管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/commentManage',
      name: 'commentManage',
      component: () => import ('@/modules/course/courseManage/commentManage.vue'),
      meta: { title: '评论管理', icon: '' },
      props: { type: 3 }
    }
  ]
},
{
  path: '/coupon',
  name: 'coupon',
  component: WtLayout,
  meta: { title: '优惠券管理', icon: '', level: 1 },
  props: { type: 3 },
  children: [
    {
      path: '/couponManage',
      name: 'couponManage',
      component: () => import ('@/modules/coupon/couponManage/index.vue'),
      meta: { title: '优惠券管理', icon: '' },
      props: { type: 3 }
    }
  ]
},
{
  path: '/orderManage',
  name: 'orderManage',
  component: WtLayout,
  meta: { title: '订单管理', icon: '', level: 1 },
  props: { type: 3 },
  children: [
    {
      path: '/reportOrderList',
      name: 'reportOrderList',
      component: () => import ('@/modules/reportManage/reportOrder/reportOrderList.vue'),
      meta: { title: '报告订单管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/courseOrderManage',
      name: 'courseOrderManage',
      component: () => import ('@/modules/orderManage/courseOrderManage.vue'),
      meta: { title: '课程订单管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/memberOrderManage',
      name: 'memberOrderManage',
      component: () => import ('@/modules/orderManage/memberOrderManage.vue'),
      meta: { title: '会员订单管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/orderStatistics',
      name: 'orderStatistics',
      component: () => import ('@/modules/orderManage/orderStatistics.vue'),
      meta: { title: '订单统计', icon: '' },
      props: { type: 3 }
    }
  ]
},
{
  path: '/system',
  name: 'system',
  component: WtLayout,
  meta: { title: '系统设置', icon: '', level: 1 },
  props: { type: 3 },
  children: [
    {
      path: '/bannerList',
      name: 'bannerList',
      component: () => import ('@/modules/auth/banner/bannerList.vue'),
      meta: { title: '轮播图管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/managerList',
      name: 'managerList',
      component: () => import ('@/modules/auth/manager/managerList.vue'),
      meta: { title: '管理员管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/roleList',
      name: 'roleList',
      component: () => import ('@/modules/auth/role/roleList.vue'),
      meta: { title: '角色管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/dictList',
      name: 'dictList',
      component: () => import ('@/modules/auth/dict/dictList.vue'),
      meta: { title: '字典管理', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: () => import ('@/modules/auth/agreement/agreement.vue'),
      meta: { title: '隐私政策', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/purchaseAgreement',
      name: 'purchaseAgreement',
      component: () => import ('@/modules/auth/agreement/purchaseAgreement.vue'),
      meta: { title: '购买协议', icon: '' },
      props: { type: 3 }
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import ('@/modules/auth/agreement/aboutUs.vue'),
      meta: { title: '关于我们', icon: '' },
      props: { type: 3 }
    }
    // {
    //   path: 'permissionList',
    //   name: 'permissionList',
    //   component: () => import ('@/modules/auth/permission/permissionList.vue'),
    //   meta: { title: '权限管理', icon: '' },
    //   props: { type: 3 }
    // }
  ]
},
//   {
//   path: '/example',
//   name: 'example',
//   component: WtLayout,
//   meta: { title: '示例页面', icon: '' },
//   children: [{
//     path: 'exampleList',
//     name: 'exampleList',
//     component: () =>
//         import ('@/modules/example/exampleList.vue'),
//     meta: { title: '示例页面', icon: '' }
//   }]
// },
// {
//   path: '/template',
//   name: 'template',
//   component: WtLayout,
//   meta: { title: '模板管理', icon: '' },
//   children: [{
//     path: 'template',
//     name: 'template',
//     component: () =>
//       import ('@/modules/template/template.vue'),
//     meta: { title: '模板管理', icon: '' }
//   }]
// },
{ path: '*', redirect: '/404', hidden: true, name: '404' }
]
export default staticRoutes
