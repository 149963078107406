<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType === 'add'" v-has-any-premission="['MEMBERLIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['MEMBERLIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="订单信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="订单编号" prop="outTradeNo">
                <span>{{ detailForm.outTradeNo }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="userPhone">
                <span>{{ detailForm.userPhone }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="下单人" prop="userName">
                <span>{{ detailForm.userName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下单时间" prop="createTime">
                <span>{{ detailForm.createTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="会员类型" prop="priceDollar">
                <span>{{ detailForm.memberLevel | FILTER_MemberType }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员卡类型" prop="payTime">
                <span>{{ detailForm.memberScope | FILTER_CardType }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="支付时间" prop="payTime">
                <span>{{ detailForm.payTime }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="优惠金额" prop="priceDollar">
                <span>{{ detailForm.couponPriceDollar }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="实付金额" prop="priceDollar">
                <span>{{ detailForm.priceDollar }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
export default {
  name: 'memberOrderView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      detailForm: {}
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    requestDetail() {
      const that = this
      console.log(that.detailData)
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/userMemberOrder/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.reportResult = resp.data.reportResult || {}
        const labelNameMatch = that.labelList.find(item => item.id === that.reportResult.labelId)
        this.reportResult.labelName = labelNameMatch.name
        const hotNameMatch = that.labelList.find(item => item.id === that.reportResult.hotWordId)
        this.reportResult.hotWordName = hotNameMatch.name
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 50%;
}
.user-avatar {
  height: 80px;
}
</style>
