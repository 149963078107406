<!--
 * @Author: your name
 * @Date: 2021-06-15 10:33:00
 * @LastEditTime: 2021-07-28 11:17:57
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \medical-cms\src\components\WtButton\index.vue
-->
<template>
  <el-button size="small" @click="onClick" class="wt-button" v-bind="$attrs">
    <slot></slot>
  </el-button>
</template>
<script>
export default {
  name: 'WtButton',
  isAutoComponent: true,
  methods: {
    onClick() {
      this.$emit('click', '')
    }
  }
}
</script>
<style scoped>
</style>
