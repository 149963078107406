<template>
  <div class="wt-easy-search-form">
    <div class="wt-easy-search-form-content wt-flex wt-flex-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wtEasySearchForm'
}
</script>

<style lang="scss" scoped>
.wt-easy-search-form {
  background-color: #fff;
  &-content {

  }
}
</style>

