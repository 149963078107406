<template>
  <div class="page-container">
    <wt-crud :crud="crud" mode="slot">
      <template #table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="level" label="会员类型">
          <template v-slot="scope">
            <span>{{ scope.row.level | FILTER_MemberType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="权益类型">
          <template v-slot="scope">
            <span>{{ scope.row.type | FILTER_RightType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isFreeRead" label="免费查看">
          <template v-slot="scope">
            <span>{{ scope.row.isFreeRead | FILTER_YesOrNo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="readDayLimitNumber" label="查看次数/天"></el-table-column>
        <el-table-column prop="isFreeDownload" label="免费下载">
          <template v-slot="scope">
            <span>{{ scope.row.isFreeDownload | FILTER_YesOrNo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="downloadDayLimitNumber" label="下载次数/天"></el-table-column>

        <el-table-column label="操作" fixed="right" width="200">
          <template v-slot="scope">
            <wt-text-button v-has-any-premission="['RIGHTINTERESTSLIST:EDIT']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-crud>
    <!--编辑-->
    <div class="wt-dialog">
      <el-dialog title="会员权益" :visible.sync="isEditDialogVisible" width="40%" :close-on-press-escape="false"
                 :close-on-click-modal="false" :modal-append-to-body="false" lock-scroll>
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="40%">
          <el-form-item label="" prop="content">
            <span>{{ detailForm.level | FILTER_MemberType }}/{{detailForm.type | FILTER_RightType}}</span>
          </el-form-item>
          <el-form-item label="免费查看" prop="isFreeRead" :rules="[{ required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-select v-model="detailForm.isFreeRead" @change="readChange(detailForm.isFreeRead)" placeholder="请选择">
              <el-option label="是" :value="1">是</el-option>
              <el-option label="否" :value="0">否</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="查看次数：" prop="readDayLimitNumber" :rules="[{ required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-input-number v-model="detailForm.readDayLimitNumber" :min="0"></el-input-number>
          </el-form-item>
          <el-form-item label="免费下载" prop="isFreeDownload" :rules="[{ required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-select v-model="detailForm.isFreeDownload" @change="downloadChange(detailForm.isFreeDownload)" placeholder="请选择">
              <el-option label="是" :value="1">是</el-option>
              <el-option label="否" :value="0">否</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下载次数：" prop="downloadDayLimitNumber" :rules="[{ required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-input-number v-model="detailForm.downloadDayLimitNumber" :min="0"></el-input-number>
          </el-form-item>
        </el-form>
        <template slot="footer">
          <wt-button type="info"
                     @click="closeCard()">取消</wt-button>
          <wt-button type="primary"
                     @click="saveCard()">提交</wt-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCrud } from '@wangtiansoft/wt-crud'
export default {
  name: 'rightInterestsList',
  setup() {
    const { crud } = useCrud({
      requestListUrl: '/modules/memberFunction/list',
      formData: {
        createTimeRange: ''
      }
    })
    return {
      crud
    }
  },
  data() {
    return {
      isEditDialogVisible: false,
      detailForm: {
      }
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    readChange(val) {
      if (val === 0) {
        this.detailForm.readDayLimitNumber = 0
      }
    },
    downloadChange(val) {
      if (val === 0) {
        this.detailForm.downloadDayLimitNumber = 0
      }
    },
    onClickEdit(row) {
      this.isEditDialogVisible = true
      const that = this
      that.isEditDialogVisible = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/memberFunction/detail',
        method: 'post',
        data: {
          id: row.id
        }
      }).then((resp) => {
        that.isFromLoading = false
        that.detailForm = resp.data
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    // 提交
    saveCard() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/memberFunction/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isFromLoading = false
        this.isEditDialogVisible = false
        that.$message({ type: 'success', message: '保存成功' })
        that.crud.requestDataList()
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    // 取消
    closeCard() {
      this.isEditDialogVisible = false
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-input-number {
  width: 100% !important;
}
</style>
