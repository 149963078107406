<template>
  <div v-if="!data.hidden" class="wt-module-item">
    <div class="title">
      <el-checkbox class="ck-all" :indeterminate="isIndeterminate" v-model="isCheckAll" @change="handleCheckAllChanged">{{data.meta ? data.meta.title : '-'}}</el-checkbox>
    </div>
    <div class="content" v-if="data.children && data.children.length > 0">
      <module-item v-for="(item, index) in data.children" :data="item" :key="index" @on-item-value-changed="onItemValueChanged"></module-item>
    </div>
    <div v-else class="permissions">
      <div class="permission"><el-checkbox v-model="permissionDict[permissionKey + ':VIEW']" @change="handleValueChanged">查看</el-checkbox></div>
      <div class="permission"><el-checkbox v-model="permissionDict[permissionKey + ':ADD']" @change="handleValueChanged">新增</el-checkbox></div>
      <div class="permission"><el-checkbox v-model="permissionDict[permissionKey + ':EDIT']" @change="handleValueChanged">编辑</el-checkbox></div>
      <div class="permission"><el-checkbox v-model="permissionDict[permissionKey + ':DELETE']" @change="handleValueChanged">删除</el-checkbox></div>
      <div class="permission"><el-checkbox v-model="permissionDict[permissionKey + ':TEST']" @change="handleValueChanged">测试</el-checkbox></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'module-item',
  props: ['data'],
  data() {
    return {
      isCheckAll: false,
      isIndeterminate: false,
      permissionKey: ''
    }
  },
  mounted() {
  },
  created() {
    this.permissionKey = (this.data.permission ? this.data.permission : (this.data.name ? '#' + this.data.name : '')).toUpperCase()
    // const permission = this.data.permission ? this.data.permission : ''
    // const viewKey = permission + ':VIEW'
    // const addKey = permission + ':ADD'
    // const editKey = permission + ':EDIT'
    // const deleteKey = permission + ':DELETE'
    // const keyArray = [viewKey, addKey, editKey, deleteKey]
    // for (let i = 0; i < keyArray; i++) {
    //   const key = keyArray[i]
    //   if (!this.permissionDict[key]) {
    //     this.$store.dispatch('updatePermissionDict', { [key]: '' })
    //   }
    // }
  },
  methods: {
    handleValueChanged(val) {
      this.$emit('on-item-value-changed')
    },
    handleCheckAllChanged(val) {
      // const keys = Object.keys(this.permissionDict)
      // keys.forEach((key) => {
      //   if (keys.indexOf(this.permissionKey + ':') === 0) {
      //     this.permissionDict.
      //   }
      // })
      this.$emit('on-item-value-changed')
    },
    onItemValueChanged() {
      console.log('onItemValueChanged', this.permissionKey)
      this.$emit('on-item-value-changed')
    }
  },
  computed: {
    ...mapState({
      permissionDict: state => state.auth.permissionDict
    })
  }
}
</script>
<style scoped>
  .wt-module-item{display: flex;border-left: 1px solid #F0F0F0;border-top: 1px solid #F0F0F0;padding-left: 16px;font-size: 14px;color: #333;}
  .wt-module-item > .title{min-width: 150px;min-height: 30px;display: flex;align-items: center;}
  .wt-module-item > .title .ck-all{margin-right: 16px;}
  .wt-module-item > .content{flex: 1;min-height: 30px;}
  .wt-module-item > .permissions{flex: 1;min-height: 30px;display: flex;flex-wrap: wrap;align-items: center;}
  .wt-module-item > .permissions > .permission{margin: 8px 12px;text-decoration: underline; color: #666;}
  .wt-module-item > .content > .wt-module-item:first-child{border-top: 0;}
</style>
