<template>
  <wt-crud-page>
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType === 'add'" v-has-any-premission="['MEMBERLIST:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType === 'edit'" v-has-any-premission="['MEMBERLIST:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="20%">
          <el-row>
            <el-col :span="12">
              <el-form-item label="会员头像" prop="wechatPhotoUrl">
                <img class="wechatPhoto" :src="detailForm.wechatPhotoUrl" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="昵称" prop="name">
                <span>{{ detailForm.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="phone">
                <span>{{ detailForm.phone }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="微信ID" prop="wechatOpenid">
                <span>{{ detailForm.wechatOpenid }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员类型" prop="memberLevel">
                <span>{{ detailForm.memberLevel | FILTER_MemberType }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item v-if="detailForm.memberLevel !== 1" label="有效期至" prop="memberEndTime">
                <span>{{ detailForm.memberEndTime }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="注册时间" prop="createTime">
                <span>{{ detailForm.createTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="邮箱" prop="email">
                <span>{{ detailForm.email }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司" prop="companyName">
                <span>{{ detailForm.companyName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="职务" prop="job">
                <span>{{ detailForm.job }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="isPublish">
                <span>{{ detailForm.isPublish | FILTER_IsPublish }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </wt-detail-section>
      <wt-detail-section title="会员充值记录">
        <div class="tableBox">
          <el-table :data="form.list" :header-cell-style="{background:'#F3F4F7',color:'#555', padding: '8px 0'}">
            <el-table-column type="index" label="序号" prop="name"></el-table-column>
            <el-table-column label="会员类型" prop="memberLevel">
              <template v-slot="scope">
                <span>{{ scope.row.memberLevel | FILTER_MemberType }}</span>
              </template>
            </el-table-column>
            <el-table-column label="会员卡类型" prop="memberScope">
              <template v-slot="scope">
                <span>{{ scope.row.memberScope | FILTER_CardType }}</span>
              </template>
            </el-table-column>
            <el-table-column label="会员期限" prop="endDate"></el-table-column>
            <el-table-column label="充值金额" prop="price"></el-table-column>
            <el-table-column label="充值时间" prop="createTime"></el-table-column>
          </el-table>
          <div class="wt-form-pagination">
            <el-pagination
                :current-page="form.currentPage"
                :page-sizes="[ 10, 20, 50, 100]"
                :page-size="form.pageSize"
                layout="prev, pager, next, sizes, jumper"
                :total="form.total"
                @current-change="onPageCurrentChange"
                @size-change="onPageSizeChange">
            </el-pagination>
          </div>
        </div>
      </wt-detail-section>
      <wt-detail-section title="报告下载记录">
        <div class="tableBox">
          <el-table :data="reportForm.list" :header-cell-style="{background:'#F3F4F7',color:'#555', padding: '8px 0'}">
            <el-table-column type="index" label="序号" prop="name"></el-table-column>
            <el-table-column label="报告名称" prop="reportName"></el-table-column>
            <el-table-column label="下载次数" prop="downloadNumber"></el-table-column>
            <el-table-column label="最近下载时间" prop="createTime"></el-table-column>
          </el-table>
          <div class="wt-form-pagination">
            <el-pagination
                :current-page="reportForm.currentPage"
                :page-sizes="[ 10, 20, 50, 100]"
                :page-size="reportForm.pageSize"
                layout="prev, pager, next, sizes, jumper"
                :total="reportForm.total"
                @current-change="onPageCurrentChange"
                @size-change="onPageSizeChange">
            </el-pagination>
          </div>
        </div>
      </wt-detail-section>
    </div>
  </wt-crud-page>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
export default {
  name: 'memberEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      userId: '',
      detailForm: {
      },
      labelList: [
        { labelId: 1, label: '精品推荐' },
        { labelId: 2, label: '杂志' },
        { labelId: 3, label: '精品资讯' }
      ],
      form: {
        formData: {},
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      reportForm: {
        formData: {},
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
    console.log(this.detailData)
  },
  created() {
  },
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      console.log(that.detailData)
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/user/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        // that.userId = that.detailForm.id
        that.requestMemberList()
        that.requestReportList()
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    // 会员充值记录
    requestMemberList() {
      const that = this
      that.form.formData.userId = that.detailForm.id
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/userMemberOrder/listByUser',
        method: 'post',
        data: this.form
      }).then((resp) => {
        that.form = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    // 报告下载记录
    requestReportList() {
      const that = this
      that.reportForm.formData.userId = that.detailForm.id
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/userReportDownloadRecord/listByUser',
        method: 'post',
        data: this.reportForm
      }).then((resp) => {
        that.reportForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/user/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: 'modules/user/add',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    onPageCurrentChange(pageNum) {
      this.form.pageData.currentPage = pageNum
      this.requestIMList()
    },
    onPageSizeChange(pageSize) {
      this.form.pageData.pageSize = pageSize
      this.requestIMList()
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .wt-detail-section-form-center .el-form-item .el-form-item__content {
  width: 30%;
}
.wt-table-full {
  width: 100%;
}
.tableBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.wechatPhoto {
  height: 80px;
}
</style>
