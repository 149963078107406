<template>
  <div>
    <!--查询条件 开始-->
    <wt-search-form>
      <slot name="search"></slot>
      <slot name="search-buttons">
        <wt-search-form-item class="wt-search-form-buttons">
          <wt-button type="primary" :loading="form.isFromLoading" @click="onClickSearch">查询</wt-button>
          <wt-button v-if="!resetHide" @click="onClickReset">重置</wt-button>
        </wt-search-form-item>
      </slot>
    </wt-search-form>
    <!--查询条件 结束-->
    <!--表格按钮 开始-->
    <wt-form-toolbar :form="form">
      <div v-if="form.toolbarLeft" slot="left">{{form.toolbarLeft}}</div>
      <slot name="toolbar"></slot>
    </wt-form-toolbar>
    <!--表格按钮 结束-->
    <!--表格 开始-->
    <wt-form-table :form="form" @selection-change="handleSelectionChange" @sort-change="sortChange">
      <slot name="table"></slot>
    </wt-form-table>
    <!--表格 结束-->
    <!--分页 开始-->
    <wt-form-pagination :form="form" @request-form-list="requestDataList"/>
    <!--分页 结束-->
  </div>
</template>

<script>
export default {
  name: 'WtDataForm',
  isAutoComponent: true,
  props: ['form', 'exportFileName', 'height', 'isFromLoading', 'resetHide',
    'requestListUrl', 'requestExportUrl', 'requestDeleteUrl', 'requestUnPublishUrl', 'requestPublishUrl', 'requestUp', 'requestDown'],
  data() {
    return {
      formData: {}
    }
  },
  created() {
  },
  methods: {
    // 当表格的排序条件发生变化的时候会触发该事件
    sortChange(params) {
      this.$emit('sort-change', params)
    },
    onClickSearch() {
      this.formData = Object.assign(this.form.formData, this.form.extFormData)
      this.form.pageData.currentPage = 1
      this.requestDataList()
    },
    onClickReset() {
      this.form.formData = Object.assign({}, this.form.extFormData)
      this.formData = this.form.formData
      this.requestDataList()
    },
    toView(rowData) {
      this.form.detailData = rowData
      this.form.isViewDialogVisible = true
    },
    toAdd() {
      this.form.dialogViewType = 'add'
      this.form.isEditDialogVisible = true
    },
    toEdit(rowData) {
      this.form.detailData = rowData
      this.form.dialogViewType = 'edit'
      this.form.isEditDialogVisible = true
    },
    onClickDelete(dataId) {
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.requestDataDelete([dataId])
      }).catch(() => {
      })
    },
    // 批量上架
    onClickMultipleUp() {
      if (!this.form.formSelectedList || this.form.formSelectedList.length <= 0) {
        this.$alert('请选中至少一行数据', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        }).then(() => {
        }).catch(() => {})
        return
      }
      const that = this
      this.$confirm('是否上架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const dataIdList = []
        that.form.formSelectedList.forEach((item) => {
          dataIdList.push(item.id)
        })
        that.requestDataUp(dataIdList)
      }).catch(() => {
      })
    },
    requestDataUp(dataIdList) {
      const that = this
      that.form.isFromLoading = true
      that.$wtRequest({
        url: this.requestUp,
        method: 'post',
        data: dataIdList
      }).then((resp) => {
        if (resp.code === '0') {
          that.$message({ type: 'success', message: '操作成功' })
          that.requestDataList()
        } else {
          that.form.isFromLoading = false
        }
      }).catch(() => {
        that.form.isFromLoading = false
      })
    },
    // 批量下架
    onClickMultipleDown() {
      if (!this.form.formSelectedList || this.form.formSelectedList.length <= 0) {
        this.$alert('请选中至少一行数据', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        }).then(() => {
        }).catch(() => {})
        return
      }
      const that = this
      this.$confirm('是否下架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const dataIdList = []
        that.form.formSelectedList.forEach((item) => {
          dataIdList.push(item.id)
        })
        that.requestDataDown(dataIdList)
      }).catch(() => {
      })
    },
    requestDataDown(dataIdList) {
      const that = this
      that.form.isFromLoading = true
      that.$wtRequest({
        url: this.requestDown,
        method: 'post',
        data: dataIdList
      }).then((resp) => {
        if (resp.code === '0') {
          that.$message({ type: 'success', message: '操作成功' })
          that.requestDataList()
        } else {
          that.form.isFromLoading = false
        }
      }).catch(() => {
        that.form.isFromLoading = false
      })
    },
    onClickIsPublish(flag, dataId) {
      const that = this
      this.$confirm('是否确定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.requestIsPublish(flag, [dataId])
      }).catch(() => {
      })
    },
    /**
     * 批量上下架
     * @param flag 0 下架 1上架
     */
    onClickMultipleIsPublish(flag) {
      if (!this.form.formSelectedList || this.form.formSelectedList.length <= 0) {
        this.$alert('请选中至少一行数据', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        }).then(() => {
        }).catch(() => {
        })
        return
      }
      this.$confirm('是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const dataIdList = []
        this.form.formSelectedList.forEach((item) => {
          dataIdList.push(item.id)
        })
        if (this._events && this._events['on-request-publish']) {
          this.$emit('on-request-publish')
          return
        }
        this.requestIsPublish(flag, dataIdList)
      }).catch(() => {
      })
    },
    requestIsPublish(flag, dataIdList) {
      this.form.isFromLoading = true
      this.$wtRequest({
        url: flag === 0 ? this.requestUnPublishUrl : this.requestPublishUrl,
        method: 'post',
        data: dataIdList
      }).then((resp) => {
        if (resp.code === '0') {
          this.$message({ type: 'success', message: '操作成功' })
          this.requestDataList()
        } else {
          this.form.isFromLoading = false
        }
      }).catch(() => {
        this.form.isFromLoading = false
      })
    },
    onClickMultipleDelete() {
      if (!this.form.formSelectedList || this.form.formSelectedList.length <= 0) {
        this.$alert('请选中至少一行数据', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        }).then(() => {
        }).catch(() => {
        })
        return
      }
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const dataIdList = []
        that.form.formSelectedList.forEach((item) => {
          dataIdList.push(item.id)
        })
        that.requestDataDelete(dataIdList)
      }).catch(() => {
      })
    },

    handleSelectionChange(val) {
      this.form.formSelectedList = val
      this.$emit('handle-selection-change', val)
    },
    requestDataDelete(dataIdList) {
      if (this.form.formResult.list.length === dataIdList.length && this.form.pageData.currentPage > 1) {
        this.form.pageData.currentPage--
      }
      if (this._events && this._events['on-request-delete']) {
        this.$emit('on-request-delete')
        return
      }
      const that = this
      that.form.isFromLoading = true
      that.$wtRequest({
        url: this.requestDeleteUrl,
        method: 'post',
        data: {
          list: dataIdList
        }
      }).then((resp) => {
        if (resp.code === '0') {
          that.$message({ type: 'success', message: '删除成功' })
          that.requestDataList()
        } else {
          that.form.isFromLoading = false
        }
      }).catch(() => {
        that.form.isFromLoading = false
      })
    },
    requestDataList() {
      if (this._events && this._events['on-request-list']) {
        this.$emit('on-request-list')
        return
      }
      const that = this
      that.form.isFromLoading = true
      that.$wtRequest({
        url: this.requestListUrl,
        method: 'post',
        data: {
          formData: that.form.formData,
          pageData: that.form.pageData
        }
      }).then((resp) => {
        that.form.isFromLoading = false
        that.form.formResult = resp.data
        this.$emit('on-request-list-done')
      }).catch(() => {
        that.form.isFromLoading = false
        this.$emit('on-request-list-done')
      })
    },
    requestExportList() {
      this.$confirm('确认导出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$wtUtil.downloadFile({
          url: '/order/modules/order/consultation/export',
          fileName: this.exportFileName + Date.parse(new Date()) + '.xlsx',
          data: this.formData
        })
      }).catch(() => {})
    }
  },
  computed: {
    dataList() {
      if (this.form == null || this.form.formResult == null) {
        return []
      }
      return this.form.formResult.list
    }
  }
}
</script>

<style>
</style>
