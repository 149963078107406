<template>
  <el-select v-model="dataValue" placeholder="请选择" @change="onValueChange" size="small" filterable v-bind="$attrs">
    <el-option v-for="item in areaList" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'wtFormAreaSelect',
  isAutoComponent: true,
  props: ['value', 'placeholder'],
  data() {
    return {
      areaList: [],
      dataValue: ''
    }
  },
  created() {
    const that = this
    const tempAreaList = [{ label: '全部', value: '' }]
    this.$store.dispatch('queryAreaCacheList').then((data) => {
      data.forEach(item => {
        tempAreaList.push({ label: item.name, value: item.code })
      })
    })
    that.areaList = tempAreaList
  },
  methods: {
    onValueChange(val) {
      this.$emit('input', val)
    }
  },
  watch: {
    value: function(newVal) {
      this.dataValue = newVal
    }
  }
}
</script>

<style>
</style>
